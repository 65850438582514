<template>
  <div class="panel" @click="panelClick" @contextmenu="panelContextmenu">
    <div style="height: 100%">
      <div style="height: 100%">
        <div class="ef-tool-tar">
          <div class="back" @click="backClick">
            <a-tooltip placement="top">
              <div slot="title">点击返回</div>
              <div class="button">
                <img
                  class="iconImg"
                  @dragstart.prevent
                  src="../../assets/icon/fanhui.png"
                />
              </div>
            </a-tooltip>
          </div>
          <div class="tabs">
            <div class="tabList" ref="tabList">
              <template v-for="(item, index) in tabList">
                <a-dropdown :key="index" :trigger="['contextmenu']">
                  <div
                    :key="index"
                    :class="{ active: tabIndex == index, tabCard: true }"
                    ref="tabCard"
                    @click="tabChange(item, index)"
                  >
                    <template v-if="!templated && !processed">
                      <a-tooltip
                        placement="bottom"
                        v-if="
                          item.status == 'RUNNING' || item.status == 'PAUSEING'
                        "
                      >
                        <div slot="title">
                          {{ item.status == "PAUSEING" ? "暂停中" : "运行中" }}
                        </div>
                        <a-icon type="loading" style="margin-right: 5px" />
                      </a-tooltip>
                      <a-tooltip
                        placement="bottom"
                        v-if="item.status == 'SUCCESS'"
                      >
                        <div slot="title">执行成功</div>
                        <div
                          class="rage"
                          style="background-color: #52c41a"
                        ></div>
                      </a-tooltip>
                      <a-tooltip
                        placement="bottom"
                        v-if="item.status == 'ERROR'"
                      >
                        <div slot="title">执行失败</div>
                        <div
                          class="rage"
                          style="background-color: #f5222d"
                        ></div>
                      </a-tooltip>
                      <a-tooltip
                        placement="bottom"
                        v-if="item.status == 'DRAFT'"
                      >
                        <div slot="title">编辑状态</div>
                        <div
                          class="rage"
                          style="background-color: #cdcdce"
                        ></div>
                      </a-tooltip>
                      <a-tooltip
                        placement="bottom"
                        v-if="
                          item.status == 'WAITING' ||
                          item.status == 'SCHEDULED' ||
                          item.status == 'PAUSED'
                        "
                      >
                        <div slot="title">
                          {{ item.status == "PAUSED" ? "已暂停" : "等待执行" }}
                        </div>
                        <div
                          class="rage"
                          style="background-color: #fa8c16"
                        ></div>
                      </a-tooltip>
                    </template>
                    <a-button type="link">
                      {{ item.name }}
                    </a-button>

                    <a-icon
                      v-if="index !== 0 && !viewOnly"
                      class="delete"
                      type="close"
                      @click.stop="deleteBlankTaskForChain(item, index)"
                    />
                    <div class="borderBottom"></div>
                  </div>

                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="ReName(item)">
                      重命名
                    </a-menu-item>
                    <a-menu-item
                      key="2"
                      v-if="
                        index > 1 &&
                        tabList[0]?.status !== 'SUCCESS' &&
                        tabList[0]?.status !== 'ERROR' &&
                        tabList[0]?.status !== 'RUNNING'
                      "
                      @click="swapPriority(item.id, tabList[index - 1].id)"
                    >
                      <a-icon type="arrow-left" style="margin: 0" />
                      前移
                    </a-menu-item>
                    <a-menu-item
                      key="3"
                      v-if="
                        index < tabList.length - 1 &&
                        index !== 0 &&
                        tabList[0]?.status !== 'SUCCESS' &&
                        tabList[0]?.status !== 'ERROR' &&
                        tabList[0]?.status !== 'RUNNING'
                      "
                      @click="swapPriority(tabList[index + 1].id, item.id)"
                    >
                      <a-icon type="arrow-right" style="margin: 0" />
                      后移
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>
            </div>
            <a-button
              v-if="!viewOnly && !processed && model == 'BATCH'"
              class="add"
              type="link"
              icon="plus"
              @click="addBlankTaskForChain"
            />
          </div>
          <div class="tool">
            <template v-if="!viewOnly">
              <a-tooltip placement="bottom">
                <div slot="title">撤销</div>
                <a-button
                  type="link"
                  :disabled="recordMap[tabId]?.revertCount == 0"
                  @click="revocation"
                  size="small"
                >
                  <img
                    class="iconImg"
                    v-if="recordMap[tabId]?.revertCount == 0"
                    @dragstart.prevent
                    src="../../assets/icon/revocationDisable.png"
                  />
                  <img
                    class="iconImg"
                    v-else
                    @dragstart.prevent
                    src="../../assets/icon/revocation.png"
                  />
                </a-button>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <div slot="title">重做</div>
                <a-button
                  type="link"
                  :disabled="recordMap[tabId]?.redoCount == 0"
                  @click="cancelRevocation"
                  size="small"
                >
                  <img
                    class="iconImg"
                    v-if="recordMap[tabId]?.redoCount == 0"
                    @dragstart.prevent
                    src="../../assets/icon/cancelRevocationDisable.png"
                  />
                  <img
                    class="iconImg"
                    v-else
                    @dragstart.prevent
                    src="../../assets/icon/cancelRevocation.png"
                  />
                </a-button>
              </a-tooltip>
              <a-divider type="vertical" />
            </template>
            <a-tooltip placement="bottom">
              <div slot="title">{{ collect ? "取消" : "" }}收藏</div>
              <a-button type="link" @click="collectChange" size="small">
                <img
                  class="iconImg"
                  v-if="collect"
                  @dragstart.prevent
                  src="../../assets/icon/favorite_1.svg"
                />
                <img
                  class="iconImg"
                  v-else
                  @dragstart.prevent
                  src="../../assets/icon/favorite_0.svg"
                />
              </a-button>
            </a-tooltip>
            <a-dropdown>
              <a-button type="link">
                <img
                  class="iconImg"
                  @dragstart.prevent
                  src="../../assets/icon/otherAction.png"
                />
              </a-button>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button
                    style="color: #4d4d4d"
                    type="link"
                    size="small"
                    @click="statistics"
                  >
                    <img
                      class="otherActionIconImg"
                      @dragstart.prevent
                      src="../../assets/icon/statistics.png"
                    />
                    统计节点
                  </a-button>
                </a-menu-item>
                <template v-if="!viewOnly">
                  <a-menu-item>
                    <a-button
                      style="color: #575757"
                      type="link"
                      size="small"
                      @click="importTemplate"
                    >
                      <img
                        class="otherActionIconImg"
                        @dragstart.prevent
                        src="../../assets/icon/chuangjian.png"
                      />
                      导入模板
                    </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      style="color: #575757"
                      type="link"
                      size="small"
                      @click="openHelp"
                    >
                      <img
                        class="otherActionIconImg"
                        @dragstart.prevent
                        src="../../assets/icon/bangzhu.png"
                      />
                      帮助
                    </a-button>
                  </a-menu-item>
                </template>
                <a-menu-item>
                  <a-button
                    style="color: #575757"
                    type="link"
                    size="small"
                    @click="editThreadCountClick"
                  >
                    <a-icon
                      type="edit"
                      style="font-size: 18px; vertical-align: middle"
                    />
                    修改并发数
                  </a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <template v-if="!viewOnly">
              <a-tooltip placement="bottom">
                <div slot="title">保存</div>
                <a-button
                  type="link"
                  @click="saveTemplateClick"
                  v-if="templated"
                >
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocun.png"
                  />
                </a-button>

                <a-button
                  type="link"
                  @click="saveProcessClick"
                  v-else-if="processed"
                >
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocun.png"
                  />
                </a-button>

                <a-button type="link" @click="saveTimedTask" v-else-if="timed">
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocun.png"
                  />
                </a-button>

                <a-button
                  type="link"
                  @click="saveApiTask"
                  v-else-if="pipelineTaskType == 'API_TASK_TEMPLATE'"
                >
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocun.png"
                  />
                </a-button>

                <a-button type="link" @click="saveTask" v-else>
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocun.png"
                  />
                </a-button>
              </a-tooltip>

              <a-tooltip
                placement="bottom"
                v-if="
                  pipelineTaskType !== 'API_TASK_TEMPLATE' &&
                  !processed &&
                  !templated &&
                  !timed &&
                  model == 'BATCH'
                "
              >
                <div slot="title">保存并启动:R</div>
                <a-button type="link" @click="quickSubmit">
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/baocunbingzx.png"
                  />
                </a-button>
              </a-tooltip>
            </template>

            <template v-else>
              <template v-if="status == 'RUNNING'">
                <a-tooltip placement="bottom">
                  <div slot="title">定位到当前正在运行的节点</div>
                  <a-button type="link" @click="position" size="small">
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/position.png"
                    />
                  </a-button>
                </a-tooltip>

                <span style="font-size: 12px; margin: 0 5px">
                  执行时间：{{ $common.timeConsuming(executeSecond) }}
                </span>
                <a-tooltip placement="bottom">
                  <div slot="title">中断执行</div>
                  <a-button type="link" @click="stopTaskNow" size="small">
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/stop.png"
                    />
                  </a-button>
                </a-tooltip>

                <a-tooltip placement="bottom">
                  <div slot="title">暂停</div>
                  <a-button
                    type="link"
                    @click="pause"
                    size="small"
                    v-if="userName == '陈鹏'"
                  >
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/pause.png"
                    />
                  </a-button>
                </a-tooltip>
              </template>

              <a-tooltip
                placement="bottom"
                v-else-if="status == 'PAUSED' && userName == '陈鹏'"
              >
                <div slot="title">恢复</div>
                <a-button type="link" @click="resume" size="small">
                  <img
                    class="iconImg"
                    @dragstart.prevent
                    src="../../assets/icon/resume.png"
                  />
                </a-button>
              </a-tooltip>

              <template v-else>
                <a-tooltip placement="bottom">
                  <div slot="title">复制任务</div>
                  <a-button type="link" @click="copyClick" size="small">
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/copy.png"
                    />
                  </a-button>
                </a-tooltip>

                <a-tooltip placement="bottomLeft">
                  <div slot="title">重新编辑:E</div>
                  <a-button
                    type="link"
                    @click="reEditClick"
                    size="small"
                    v-if="
                      status == 'SUCCESS' ||
                      status == 'ERROR' ||
                      status == 'WAITING'
                    "
                  >
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/reEdit.png"
                    />
                  </a-button>
                </a-tooltip>

                <a-tooltip placement="bottom">
                  <div slot="title">启动任务</div>
                  <a-button
                    type="link"
                    @click="execute"
                    size="small"
                    v-if="this.tabList[this.tabIndex].status == 'WAITING'"
                  >
                    <img
                      class="iconImg"
                      @dragstart.prevent
                      src="../../assets/icon/execute.png"
                    />
                  </a-button>
                </a-tooltip>
              </template>
            </template>
          </div>
        </div>
        <div style="display: flex; height: calc(100% - 47px)">
          <div
            id="efContainer"
            ref="efContainer"
            @click="efContainerClick"
            v-flowDrag
            @dragenter.prevent="handleDragenter"
            @dragleave.prevent="handleDragleave"
            @dragover.prevent="handleDragover"
            @drop.prevent="handleDrop"
            :style="{
              overflow: maskShow ? 'hidden' : 'scroll',
            }"
          >
            <!-- 节点目录 -->
            <node-menu
              class="suspensionMenu"
              v-if="!viewOnly && pipelineTaskType !== 'PROCESS_COPY'"
              :menuList="menuList"
              :processList="processList"
              :processed="processed"
              @addNode="addNode"
              @panelClick="panelClick"
              @getNodeInfoList="getNodeInfoList"
            ></node-menu>
            <!-- 放大缩小工具 -->
            <div :class="{ 'zoom-tool': true, collapsed }" @mousedown.stop>
              <a-icon class="enlarged-btn" type="plus" />
              <a-slider
                @change="startScale"
                :step="0.001"
                :max="2"
                :min="0.5"
                v-model="scaleValue"
                vertical
                style="height: 90px"
                :show-tooltip="false"
              />
              <a-icon class="shrink-btn" type="minus" />
            </div>
            <!-- 节点附属表单 -->
            <div class="rightForm">
              <a-icon
                type="unordered-list"
                style="color: #000; font-size: 24px; cursor: pointer"
                @click.stop="rightForm"
                v-if="!nodeFormShow"
              />
              <flow-node-form
                ref="nodeForm"
                v-show="rightFormShow && nodeFormShow"
                :viewOnly="viewOnly"
                @repaintEverything="repaintEverything"
                @closed="flowNodeFormClose"
              ></flow-node-form>
            </div>
            <!-- 拖拽上传 -->
            <div
              class="fileMask"
              v-if="maskShow"
              :style="{
                left: $refs.efContainer.scrollLeft + 'px',
                top: $refs.efContainer.scrollTop + 'px',
              }"
            >
              拖拽至此上传文件
            </div>
            <!-- 框选 -->
            <div
              class="frameSelection"
              ref="frameSelection"
              v-frameSelectionFlowDrag
              @contextmenu="copyRightMenu($event)"
            ></div>
            <!-- 自定义节点目录 -->
            <div
              class="composeWorkflowTaskList"
              v-if="composeWorkflowTaskList.length > 1"
            >
              <a-tooltip
                :title="item.name"
                v-for="(item, index) in composeWorkflowTaskList"
                :key="index"
                placement="right"
              >
                <div
                  :class="{
                    workflowTaskItem: true,
                    workflowTaskItemActive: workflowTaskItemActive == index,
                  }"
                  :style="{ backgroundColor: item.color }"
                  @click="chooseWorkflowTask(index, 'tab')"
                ></div>
              </a-tooltip>
            </div>
            <!-- 画布 -->
            <div
              id="eeContainer"
              ref="eeContainer"
              @contextmenu.self="panelRightMenu($event)"
            >
              <template v-for="node in data.nodeList">
                <flow-node
                  ref="flowNode"
                  :id="node.id"
                  :node="node"
                  :key="node.id"
                  :viewOnly="viewOnly"
                  :data="data"
                  :activeElement="activeElement"
                  :composeWorkflowTaskList="composeWorkflowTaskList"
                  :taskId="tabId"
                  :menuList="menuList"
                  :errorNodeId="errorNodeId"
                  :runningNodeId="runningNodeId"
                  :model="model"
                  :datasourceIdList="datasourceIdList"
                  :fileSpaceList="fileSpaceList"
                  :pipelineTaskType="pipelineTaskType"
                  :nodePriorityShow="nodePriorityShow"
                  @dblclickNode="dblclickNode"
                  @changeNodeSite="changeNodeSite"
                  @nodeRightMenu="nodeRightMenu"
                  @endpointNodeMenu="endpointNodeMenu"
                  @clickNode="clickNode"
                  @setClick="setClick"
                  @download="download"
                  @confirmPauseNode="confirmPauseNode"
                  @mouseenter.native="changeEndpointStyle(node.id, 'enter')"
                  @mouseleave.native="changeEndpointStyle(node.id, 'leave')"
                />
              </template>
            </div>
            <!-- 缩略图 -->
            <div
              id="miniView"
              class="miniView"
              @mousedown.stop="miniStart($event)"
              @mouseup.stop="miniStop($event)"
              @mouseleave.stop="miniStop($event)"
              @mousemove.stop="miniMove($event)"
            >
              <div class="jtk-miniView">
                <div
                  id="miniNode"
                  class="miniNode"
                  v-for="node in data.nodeList"
                  :key="node.id"
                  :style="nodeContainerStyle(node)"
                ></div>
              </div>
              <div class="mask"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      title="帮助"
      v-model="flowHelpVisible"
      width="70%"
      :footer="false"
      :maskClosable="false"
    >
      <a-tabs v-model="activeName" type="card">
        <a-tab-pane tab="帮助" key="first">
          <flow-help v-if="activeName == 'first'" ref="flowHelp"></flow-help>
        </a-tab-pane>
        <a-tab-pane tab="流程数据信息" key="second">
          <flow-info
            v-if="activeName == 'second'"
            ref="flowInfo"
            :data="data"
          ></flow-info>
        </a-tab-pane>
        <a-tab-pane tab="占位符表" key="three">
          <a-table
            :rowClassName="$common.rowClassColor"
            :columns="tableColumns"
            :dataSource="tableData"
            :pagination="false"
            bordered
            size="small"
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <!-- 重命名 -->
    <a-modal
      title="重命名"
      v-model="reNameShow"
      width="420px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="Id:">
          {{ copyTabId }}
          <a-button type="link" v-copy="copyTabId">复制</a-button>
        </a-form-item>
        <a-form-item label="名称:">
          <a-input v-model="taskName" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="rename_submit()">确 定</a-button>
          <a-button @click="reNameShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="title"
      v-model="createShow"
      width="420px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="名称:">
          <a-input v-model="data.name" placeholder="请输入名称" disabled />
        </a-form-item>
        <a-form-item label="定时执行:" v-if="title == '保存'">
          <a-checkbox
            v-model="data.checked"
            @change="checkboxChange"
          ></a-checkbox>
          <a-date-picker
            v-if="data.checked"
            v-model="executeDate"
            placeholder="选择时间"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            style="margin-left: 20px"
          />
        </a-form-item>
        <a-form-item label="备注:" v-else>
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
        <a-form-item label="并发数:">
          <a-input
            v-model="threadCount"
            placeholder="请输入并发数"
            style="width: 100px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="saveTask_submit()">
            <img
              class="iconImg"
              src="../../assets/icon/baocun.png"
              style="margin-right: 2px"
            />
            保存任务
          </a-button>
          <a-button
            v-if="title == '保存'"
            @click="saveTask_submit(false, true)"
          >
            <img
              class="iconImg"
              src="../../assets/icon/caogao.png"
              style="margin-right: 2px"
            />
            保存为草稿
          </a-button>
          <a-button @click="saveTask_submit(true)" v-if="title == '保存'">
            <img
              class="iconImg"
              src="../../assets/icon/baocunbingzx.png"
              style="margin-right: 2px"
            />
            保存并启动任务
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改并发数"
      v-model="editThreadCountShow"
      width="420px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="并发数:">
          <a-input
            v-model="threadCount"
            placeholder="请输入并发数"
            style="width: 100px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editThreadCountSubmit">
            确认
          </a-button>
          <a-button @click="editThreadCountShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="导入模板"
      v-model="importTemplateShow"
      width="700px"
      :maskClosable="false"
    >
      <a-form>
        <a-form-item>
          <a-textarea
            v-model="importTemplateData"
            :rows="15"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="importTemplate_submit"
            >确认</a-button
          >
          <a-button @click="importTemplateShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="创建任务"
      v-model="frameSelectionCreateShow"
      width="700px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="名称">
          <a-input v-model="frameTaskName" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="详情">
          <a-textarea v-model="FrameSelectionTaskDetail" :rows="10" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="FrameSelectionTask"
            >创建任务</a-button
          >
          <a-button @click="frameSelectionCreateShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="下载文件列表"
      v-model="downloadShow"
      width="1000px"
      :afterClose="fileListClosed"
      :footer="false"
      :maskClosable="false"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: fileSelectTableData,
        }"
        :rowKey="(record) => record.id"
        size="small"
        :loading="loadingTable"
      >
        <span slot="lineCountLabel" slot-scope="text, record">
          <a-tooltip placement="left">
            <div slot="title">
              {{ record.lineCount }}
            </div>
            <span> {{ text }} </span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="downloadClick(record)"
            :disabled="record.expired"
            >下载</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="copyPath(record)"
            >复制路径</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            @click="checkFileContent(record)"
            :disabled="
              record.fileName.split('.')[
                record.fileName.split('.').length - 1
              ] == 'zip'
            "
            >查看</a-button
          >
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              其它 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-button type="link" size="small" @click="shareClick(record)"
                  >分享</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button type="link" size="small" @click="uploadOss(record)">
                  上传到oss
                </a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="downloadLocal(record)"
                >
                  下载到本地
                </a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="fileAnalysis(record)"
                >
                  文件分析
                </a-button>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <a-button
        @click="batch_download"
        type="primary"
        style="margin-top: 20px"
        :disabled="this.fileSelectTableData.length > 0 ? false : true"
        >批量下载</a-button
      >
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xlsx' || fileType == 'xls'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      ></a-table>

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />

      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>

    <a-modal
      width="30%"
      title="分享设置"
      v-model="innerVisible"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-select v-model="expireSeconds" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireSecondList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select v-model="fileSpaceId" placeholder="请选择">
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="25%"
      :title="composeTitle"
      v-model="composeSubmitShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="分组名称">
          <a-input
            v-model="groupName"
            placeholder="请输入分组名称"
            style="width: 200px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="composeSubmitShow = false">取 消</a-button>
          <a-button type="primary" @click="composeSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="false"
      :maskClosable="false"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        v-copy="inviteCode"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>

    <a-modal
      width="25%"
      title="复制任务"
      v-model="copyClickShow"
      :maskClosable="false"
    >
      <div style="margin-bottom: 10px">
        {{ copyClickContent }}
      </div>
      <a-checkbox
        v-model="copySourceInputFile"
        @change="copySourceInputFileChange"
      >
        复制原输入文件
      </a-checkbox>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="copyClickShow = false">取 消</a-button>
          <a-button type="primary" @click="copyClick_submit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="统计节点"
      v-model="statisticsShow"
      :footer="null"
      :maskClosable="false"
      width="700px"
    >
      <a-tabs v-model="active">
        <a-tab-pane tab="节点类型" key="1">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(nodeTypeStatisticsColumns)"
            :columns="nodeTypeStatisticsColumns"
            :dataSource="nodeTypeStatisticsList"
            :pagination="false"
            size="small"
          >
            <span
              slot="action"
              slot-scope="text, record, index"
              v-if="index !== nodeTypeStatisticsList.length - 1"
            >
              <a-button
                type="link"
                size="small"
                @click="getNodeTypeStatisticsChildrenList(record)"
                >查看</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="节点列表" key="2">
          <div class="searchCondition">
            <div class="searchConditionItem">
              <span>关键字：</span>
              <a-input
                v-model.trim="filterNodeKey"
                placeholder="请输入关键字"
                allowClear
                @change="allowClearFilterNodeListChange"
                @pressEnter="filterFlashingNodeList()"
              />
            </div>
            <div class="searchButton">
              <a-button
                type="primary"
                @click="filterFlashingNodeList()"
                icon="search"
              >
                查询
              </a-button>
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(nodeTypeStatisticsChildrenColumns)"
            :columns="nodeTypeStatisticsChildrenColumns"
            :dataSource="flashingNodeList"
            :pagination="false"
            size="small"
          >
            <span slot="totalCount" slot-scope="text, record">
              <span v-if="!record.totalCount">0</span>
              <span v-else-if="record.totalCount == -1">未知</span>
              <a-tooltip placement="left" v-else>
                <div slot="title">
                  {{ record.totalCount }}
                </div>
                <span>{{ $common.formatTotalCount(record.totalCount) }}</span>
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button type="link" size="small" @click="flashing(record, true)"
                >定位</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <a-modal
      :title="statisticsChildrenTitle"
      v-model="statisticsChildrenShow"
      :footer="null"
      :maskClosable="false"
      width="700px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(nodeTypeStatisticsChildrenColumns)"
        :columns="nodeTypeStatisticsChildrenColumns"
        :dataSource="nodeTypeStatisticsChildrenList"
        :pagination="false"
        size="small"
      >
        <span slot="totalCount" slot-scope="text, record">
          <span v-if="record.totalCount == -1">未知</span>
          <a-tooltip placement="left" v-else>
            <div slot="title">
              {{ record.totalCount }}
            </div>
            <span>{{ $common.formatTotalCount(record.totalCount) }}</span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" size="small" @click="flashing(record, true)"
            >定位</a-button
          >
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="修改节点备注"
      v-model="updateRemarkShow"
      :maskClosable="false"
      width="700px"
    >
      <a-form :wrapper-col="{ span: 24 }">
        <a-form-item>
          <a-textarea
            v-model="nodeRemark"
            :rows="15"
            placeholder="请输入备注"
            ref="nodeRemarkInput"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="updateRemarkShow = false">取 消</a-button>
          <a-button type="primary" @click="updateRemarkSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="文件分析"
      v-model="fileAnalysisShow"
      width="80%"
      :footer="false"
      :maskClosable="false"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        :columns="fileAnalysisColumns"
        :dataSource="fileAnalysisData"
        :pagination="false"
        bordered
        size="small"
        :scroll="{
          y: '600px',
        }"
        class="fileAnalysisTable"
      >
        <template v-for="(column, index) in fileAnalysisColumnList">
          <span :slot="column.name" :key="index">
            {{ column.alias || column.name }}
            <a-button
              type="link"
              size="small"
              icon="edit"
              @click="editColumn(column, index)"
            />
            <a-popover
              placement="bottom"
              trigger="click"
              v-model="column.visible"
              :key="index"
            >
              <template slot="content">
                <div class="popover">
                  <a-input
                    v-model="columnSearchKey"
                    placeholder="输入关键词过滤"
                    allowClear
                    @change="allowClearColumnSearchKeyChange"
                    @pressEnter="columnSearch"
                    class="searchInput"
                  />
                  <a-checkbox
                    :indeterminate="indeterminate"
                    :checked="checkAll"
                    @change="checkAllChange"
                  >
                    全选
                    {{
                      columnItemValueList.length > 0
                        ? "(" + columnItemValueList.length + ")"
                        : ""
                    }}
                  </a-checkbox>
                  <div class="checkList">
                    <a-checkbox-group
                      v-model="columnItemValueList"
                      :options="columnItemGroupList"
                      @change="checkChange"
                    >
                      <span slot="label" slot-scope="{ value }">
                        {{ value }}
                      </span>
                    </a-checkbox-group>
                  </div>
                  <div class="popoverFooter">
                    <a-button
                      type="primary"
                      size="small"
                      class="leftbutton button"
                      @click="findDataByIn(column)"
                      >确定</a-button
                    >
                    <a-button
                      size="small"
                      @click="column.visible = false"
                      class="button"
                      >取消</a-button
                    >
                  </div>
                </div>
              </template>
              <a-button
                type="link"
                size="small"
                style="float: right; width: 28px"
                @click="showPopover(column, index)"
              >
                <a-icon
                  type="filter"
                  :style="{
                    color:
                      columnParamList[index]?.valueList.length > 0
                        ? '#1890ff'
                        : '',
                  }"
                />
              </a-button>
            </a-popover>
          </span>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      title="修改列名"
      v-model="editColumnShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="列名:">
          <a-input v-model="columnName" placeholder="请输入列名" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="editColumnShow = false">取 消</a-button>
          <a-button type="primary" @click="editColumnSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 右击框 -->
    <right-menu
      v-if="rightMenu.show"
      :menuData="rightMenu"
      :viewOnly="viewOnly"
      :data="data"
      :taskId="tabId"
      :composeNodeId="composeNodeId"
      :frameSelectionNodeListHasComposeNodeAndCompose="
        frameSelectionNodeListHasComposeNodeAndCompose
      "
      :frameSelectionNodeListHasComposeNodeAndDelete="
        frameSelectionNodeListHasComposeNodeAndDelete
      "
      :status="status"
      :pipelineTaskType="pipelineTaskType"
      :pipelineId="pipelineId"
      :menuList="menuList"
      :nodePriorityShow="nodePriorityShow"
      @setClick="setClick"
      @FrameSelectionClick="FrameSelectionClick"
      @FrameSelectionBatchConnectClick="FrameSelectionBatchConnectClick"
      @deleteElement="deleteElement"
      @checkAllClick="checkAllClick"
      @analysisPriority="analysisPriority"
      @addIntermediateNode="addIntermediateNode"
      @nodeEnabledChange="nodeEnabledChange"
    ></right-menu>
    <!-- 端点右击框 -->
    <endpoint-right-menu
      v-if="endpointNodeMenuShow"
      :model="model"
      :menuList="menuList"
      :endpointMenuData="endpointMenuData"
      :pipelineTaskType="pipelineTaskType"
      :frameSelectionList="frameSelectionList"
      @panelClick="panelClick"
      @addNode="addNode"
    ></endpoint-right-menu>
    <!-- 参数设置 -->
    <set-data
      ref="setData"
      :taskId="tabId"
      :nodeData="nodeData"
      :type="setDataType"
      :viewOnly="viewOnly"
      :menuData="rightMenu"
      :fromOptionsList="fromOptionsList"
      :toOptionsList="toOptionsList"
      :fromShow="fromShow"
      :fromPriorityDiffShow="fromPriorityDiffShow"
      :toShow="toShow"
      :tagShow="tagShow"
      :menuList="menuList"
      :data="data"
      :fileSpaceList="fileSpaceList"
      @setClick="setClick"
      @setLineLabel="setLineLabel"
    ></set-data>
  </div>
</template>

<script>
import "./jsplumb";
import { easyFlowMixin } from "@/components/ef/mixins";
import flowNode from "@/components/ef/node";
import nodeMenu from "@/components/ef/node_menu";
import FlowInfo from "@/components/ef/info";
import FlowHelp from "@/components/ef/help";
import rightMenu from "@/components/ef/right_menu";
import setData from "@/components/ef/set_data";
import endpointRightMenu from "@/components/ef/endpoint_right_menu";
import FlowNodeForm from "@/components/ef/node_form";
import lodash from "lodash";
import $ from "jquery";
import moment from "moment";
var that;

export default {
  data() {
    return {
      title: "创建任务",
      // jsPlumb 实例
      jsPlumb: null,
      initOnce: true,
      // 控制画布销毁
      easyFlowVisible: false,
      // 控制流程数据显示与隐藏
      flowHelpVisible: false,
      // 是否加载完毕标志位
      loadEasyFlowFinish: false,
      // 数据
      data: {
        nodeList: [],
        lineList: [],
        top: "",
        left: "",
      },
      firstLoad: true,
      modifyMap: {},
      prevHistoryData: {
        nodeList: [],
        lineList: [],
        top: "",
        left: "",
      },
      executeDate: "",
      // 激活的元素、可能是节点、可能是连线
      activeElement: {
        // 名称
        name: undefined,
        // 可选值 node 、line
        type: undefined,
        // 节点ID
        nodeId: undefined,
        // 连线ID
        sourceId: undefined,
        targetId: undefined,
      },
      resetStateNodeId: "",
      // 编辑显示
      nodeFormShow: false,
      createShow: false,
      remark: "",
      threadCount: 1,
      zoom: 0.5,
      // 流程id
      pipelineId: "",
      parentTaskName: "",
      viewOnly: false,
      debug: false,
      refreshProgressTimer: null,
      refreshRunTimeTimer: null,
      executeSecond: 0,
      // 右击数据
      rightMenu: {
        // 右击显示
        show: false,
        // 类型
        menuType: "node",
        // 数据
        data: undefined,
        // 位置
        left: 0,
        top: 0,
        lineParameterShow: false,
        notifyState: "",
      },
      // 测试节点数据
      nodeData: {},
      // 参数设置弹框类型
      setDataType: "",
      scaleValue: 1,
      templated: false,
      processed: false,
      status: "",
      model: "",
      cronRemark: "",
      timed: false,
      mouseViewShow: false,
      clientX: "",
      clientY: "",
      activeName: "first",
      reNameShow: false,
      frameSelectionList: {
        nodeList: [],
        lineList: [],
        left: 0,
        top: 0,
      },
      copyList: {},
      pasteLoading: false,
      pipelineTaskType: "",
      nodeId: "",
      nodeName: "",
      rightFormShow: true,
      frameSelectionData: [],
      FrameSelectionTaskDetail: "",
      frameSelectionCreateShow: false,
      frameTaskName: "",
      menuList: [
        {
          id: 1,
          type: "group",
          name: "零对一",
          icon: require("../../assets/icon/shujuyuan.png"),
          children: [],
        },
        {
          id: 2,
          type: "group",
          name: "一对一",
          icon: require("../../assets/icon/v.png"),
          children: [],
        },
        {
          id: 3,
          type: "group",
          name: "多对一",
          icon: require("../../assets/icon/duoduiyi.png"),
          children: [],
        },
        {
          id: 4,
          type: "group",
          name: "一对多",
          icon: require("../../assets/icon/yiduiduo.png"),
          children: [],
        },
        {
          id: 5,
          type: "group",
          name: "多对多",
          icon: require("../../assets/icon/duoduiduo.png"),
          children: [],
        },
        {
          id: 6,
          type: "group",
          name: "一对零",
          icon: require("../../assets/icon/heidong.png"),
          children: [],
        },
      ],
      processList: [
        {
          id: 1,
          icon: require("../../assets/icon/input.png"),
          name: "输入",
          type: "PROCESS_INPUT",
          starterNode: true,
          dynamicUpdateProgress: false,
          needAudit: false,
          minInputCount: 0,
          maxInputCount: 0,
          minOutputCount: 1,
          maxOutputCount: 99,
          testable: false,
          remark: "PROCESS_INPUT",
          availableType: null,
          inFileType: "",
          outFileType: "",
          inputConditionList: [],
          outputConditionList: [],
          extraInfoShow: false,
          property: {
            keepResultFile: false,
            resultFileName: "",
            resultFileType: "txt",
          },
        },
        {
          id: 2,
          icon: require("../../assets/icon/output.png"),
          name: "输出",
          type: "PROCESS_OUTPUT",
          starterNode: true,
          dynamicUpdateProgress: false,
          needAudit: false,
          minInputCount: 1,
          maxInputCount: 99,
          minOutputCount: 0,
          maxOutputCount: 0,
          testable: false,
          remark: "PROCESS_OUTPUT",
          availableType: null,
          inFileType: "",
          outFileType: "",
          inputConditionList: [],
          outputConditionList: [],
          extraInfoShow: false,
          property: {
            keepResultFile: false,
            resultFileName: "",
            resultFileType: "txt",
          },
        },
      ],
      // 可设置参数的节点类型数组
      setMenuTypeList: [
        "fileLoadService",
        "randomPhoneService",
        "databaseLoadService",
        "sortService",
        "prioritySelectorService",
        "multipleFileFilterService",
        "quickFilterService",
        "replaceService",
        "cutService",
        "contentFilterService",
        "fileSplitService",
        "lengthFilterService",
        "contentFormatService",
        "contentAppendService",
        "shellService",
        "shufSelectorService",
        "blacklistFilterService",
        "phone2GuishudiService",
        "joinService",
        "fileSelectService",
        "parseJobFileSelectService",
        "columnConversionService",
        "columnAppendService",
        "parseJobPipelineService",
        "zipMergeService",
        "diffService",
        "columnGroupService",
        "manualInputService",
        "httpSenderService",
        "httpReceiverService",
        "delayService",
        "httpApiService",
        "multipleColumn2OneService",
        "excel2TxtService",
        "ftpService",
        "sqlBatchQueryService",
        "textToExcelService",
        "columnRearrangeService",
        "columnDateFilterService",
        "uploadBlacklistService",
        "fileUploadCollectService",
        "fileCollectDistinctService",
        "shortUrlCreateService",
        "dataBaseUpdateService",
        "dataBaseInsertService",
        "sipCallTaskCreateService",
        "dataStatisticsService",
        "apiLogToDzDkDataService",
        "columnDistinctService",
        "calculateService",
        "alarmService",
        "columnSupplementService",
        "ossUploadService",
        "logicViewSynService",
        "addShortUrlGroupService",
        "dataFilterBaseAction",
        "inputFileDataCheckService",
        "uploadXinBaService",
        "jsonFormatService",
        "kafkaReceiverService",
        "uploadQuDianService",
        "zipService",
        "limiterService",
        "sync2ClickhouseService",
        "concurrentLimitService",
        "fileListUploadService",
        "fileCompressionService",
        "rowAppendService",
        "mergeService",
        "contentSupplementService",
        "maxwellFilterService",
        "databaseSelectService",
        "intersectionService",
        "databaseSelectColumnSupplementService",
        "smsCustomerCodeFetchService",
        "database2MaxwellService",
        "columnRandomOffsetService",
        "maxwell2DatabaseService",
        "columnFilterService",
        "repeatFilterService",
        "uploadFileSystemService",
        "tagFilterService",
        "ai00166Service",
        "repeatCountAnalysisService",
        "uploadMonitorService",
        "distributionStatisticsService",
        "columnGroupAndFetchService",
        "columnMatchAndFetchService",
        "fileStoreService",
        "createFormTaskService",
        "pauseNotifyService",
        "columnJoinFilterService",
        "variableExtractService",
        "variableGenerateService",
        "phoneGenerateService",
        "upload2OmegaMidTableService",
        "customerBlackListService",
        "multipleLine2OneService",
        "cellComputationService",
        "analysisUrlOfOneAppService",
        "aiCallOutInput94Service",
        "fastFilterColumnService",
        "parquetUploadService",
        "fileContentCheckService",
        "create94AiTaskService",
      ],
      fromOptionsList: [],
      toOptionsList: [],
      fromShow: false,
      fromPriorityDiffShow: false,
      toShow: false,
      tagShow: false,
      importTemplateShow: false,
      importTemplateData: "",
      addIntermediateNodeShadeShow: false,
      endpointMenuData: {
        top: "",
        left: "",
        node: null,
      },
      endpointNodeMenuShow: false,
      loadingTable: false,
      fileListTableData: [],
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
          width: 180,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          width: 100,
          align: "center",
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "lineCountLabel",
          scopedSlots: { customRender: "lineCountLabel" },
          width: 80,
          align: "center",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "label",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      fileSelectTableData: [],
      downloadShow: false,
      innerVisible: false,
      inviteCode: "",
      password: "",
      fileId: "",
      lineCount: 0,
      expireSeconds: "",
      passwordSwitch: false,
      expireSecondList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 86400 },
        { id: 3, label: "2天", value: 172800 },
        { id: 4, label: "3天", value: 259200 },
        { id: 5, label: "7天", value: 604800 },
        { id: 6, label: "15天", value: 1296000 },
        { id: 7, label: "一个月", value: 2592000 },
        { id: 8, label: "31天", value: 2592000 },
        { id: 9, label: "45天", value: 3888000 },
        { id: 10, label: "6个月", value: 15552000 },
        { id: 11, label: "1年", value: 31536000 },
      ],
      fileSpaceId: -1,
      fileSpaceList: [],
      copyVisible: false,
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      copySourceInputFile: true,
      copyClickContent: "",
      copyClickShow: false,
      tableColumns: [
        {
          title: "占位符",
          ellipsis: true,
          dataIndex: "placeholder",
          width: 200,
        },
        {
          title: "示例值",
          ellipsis: true,
          dataIndex: "exampleValue",
          width: 200,
        },
        {
          title: "说明",
          ellipsis: true,
          dataIndex: "remark",
        },
      ],
      tableData: [
        {
          placeholder: "${random}",
          exampleValue: "ajnf",
          remark: "生成4位随机字符串",
        },
        {
          placeholder: "${random:12}",
          exampleValue: "1234567890ab",
          remark: "生成指定位数的随机值",
        },
        {
          placeholder: "${yyyy-MM-dd}",
          exampleValue: "2023-07-14",
          remark: "生成年月日",
        },
        {
          placeholder: "${yyyy-MM-dd HH:mm:ss}",
          exampleValue: "2023-07-14 17:39:00",
          remark: "生成完整年月日时分秒",
        },
        {
          placeholder: "${taskId}",
          exampleValue: "10245",
          remark: "生成当前任务的id",
        },
        {
          placeholder: "${requestId}",
          exampleValue: "20230714-0001",
          remark: "生成当前任务名称",
        },
      ],
      nodeIdList: [],
      groupName: "",
      composeTitle: "",
      editComposeNodeId: "",
      composeSubmitShow: false,
      composeWorkflowTaskList: [],
      composeNodeId: "",
      workflowTaskItemActive: 0,
      frameSelectionNodeListHasComposeNodeAndCompose: false,
      frameSelectionNodeListHasComposeNodeAndDelete: false,
      enterLeft: 100,
      enterTop: 100,
      errorNodeId: "",
      errorNodeShow: false,
      shareTimer: null,
      syncLoading: null,
      maskShow: false,
      datasourceIdList: [],
      resetStateTimer: null,
      runningNodeId: "",
      hintTimer: null,
      statisticsShow: false,
      active: "1",
      nodeTypeStatisticsColumns: [
        {
          title: "节点类型",
          ellipsis: true,
          dataIndex: "type",
        },
        {
          title: "节点名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "数量",
          dataIndex: "count",
          width: 80,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      nodeTypeStatisticsList: [],
      nodeTypeStatisticsChildrenColumns: [
        {
          title: "id",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "耗时",
          dataIndex: "time",
          width: 100,
        },
        {
          title: "总条数",
          dataIndex: "totalCount",
          width: 100,
          scopedSlots: { customRender: "totalCount" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      filterNodeKey: "",
      flashingNodeList: [],
      nodeTypeStatisticsChildrenList: [],
      statisticsChildrenShow: false,
      statisticsChildrenTitle: "",
      tabIndex: 0,
      tabList: [],
      tabId: "",
      taskName: "",
      flag: "",
      copyTabId: "",
      queryTimer: null,
      deleteBlankTaskForChainId: "",
      uuid: "",
      frameSelectionTaskCreate: false,
      moveBol: true,
      recordMap: {},
      setSequenceMapSwitch: false,
      recordModifyItemList: [],
      recordModifyTimer: null,
      nodeRemark: "",
      updateRemarkShow: false,
      queryRestoreStatusTimer: null,
      isTabVisible: true,
      finishTitleTimer: null,
      nodePriorityShow: false,
      originalThreadCount: "",
      editThreadCountShow: false,
      fileNodeId: "",
      fileAnalysisColumns: [],
      fileAnalysisColumnList: [],
      fileAnalysisData: [],
      fileAnalysisShow: false,
      collect: false,
      fileAnalysisColumnId: "",
      columnName: "",
      columnIndex: 0,
      editColumnShow: false,
      indeterminate: false,
      checkAll: false,
      columnItemAllGroupList: [],
      columnItemGroupList: [],
      columnItemValueList: [],
      columnParamList: [],
      columnSearchKey: "",
    };
  },
  props: ["collapsed", "loginName", "userName"],
  // 一些基础配置移动该文件中
  mixins: [easyFlowMixin],
  components: {
    flowNode,
    nodeMenu,
    FlowInfo,
    FlowNodeForm,
    FlowHelp,
    rightMenu,
    setData,
    endpointRightMenu,
  },
  computed: {
    // 节点容器样式
    nodeContainerStyle() {
      return (node) => {
        return {
          top: (node.top.split("px")[0] - 42) / 2.6 + "px",
          left: (node.left.split("px")[0] - 15) / 2.6 + "px",
        };
      };
    },
  },
  watch: {
    "$route.query": {
      deep: true,
      handler() {
        location.reload();
      },
    },
    endpointNodeMenuShow: {
      handler(val) {
        if (this.endpointMenuData.node) {
          this.$refs.flowNode.forEach((flowNode) => {
            if (flowNode.node.id == this.endpointMenuData.node.id) {
              flowNode.endpointShow = val;
            }
          });
        }
      },
    },
    "$route.query.tabIndex": {
      immediate: true,
      handler(tabIndex) {
        if (tabIndex) {
          this.tabIndex = tabIndex;
        }
      },
    },
    tabIndex: {
      immediate: true,
      handler(tabIndex) {
        var url = window.location.href;
        let newQuery = this.$route.query;
        if (newQuery.hasOwnProperty("tabIndex")) {
          delete newQuery.tabIndex;
        }
        newQuery.tabIndex = tabIndex;
        let str = "";
        for (const key in newQuery) {
          const item = newQuery[key];
          str += key + "=" + item + "&";
        }
        var newUrl = url.split("?")[0] + "?" + str.slice(0, -1);
        history.replaceState(null, null, newUrl);
      },
    },
  },
  directives: {
    flowDrag: {
      bind(el, binding) {
        if (!binding) {
          return;
        }
        el.onmousedown = (e) => {
          if (e.button == 2) {
            return;
          }
          if (e.button == 0) {
            that.$refs.efContainer.style.cursor = "crosshair";
            that.frameSelectionList.nodeList.splice(0);
            that.frameSelectionList.lineList.splice(0);
            that.frameSelectionList.left = 0;
            that.frameSelectionList.top = 0;
            that.$refs.frameSelection.style.width = 0;
            that.$refs.frameSelection.style.height = 0;
            that.$refs.frameSelection.style.left = 0;
            that.$refs.frameSelection.style.top = 0;
            //  鼠标按下，计算当前原始距离可视区的高度
            let disX = e.offsetX;
            let disY = e.offsetY;
            let X = e.clientX;
            let Y = e.clientY;
            $(`.node-endpoint-active`).removeClass("node-endpoint-active");
            document.onmousemove = function (e) {
              // 移动时禁止默认事件
              e.preventDefault();
              if (X > e.clientX) {
                that.$refs.frameSelection.style.left =
                  e.clientX - 180 + that.$refs.efContainer.scrollLeft + "px";
                that.frameSelectionList.left =
                  e.clientX - 180 + that.$refs.efContainer.scrollLeft;
              } else {
                that.$refs.frameSelection.style.left = disX + "px";
                that.frameSelectionList.left = disX;
              }
              if (Y > e.clientY) {
                that.$refs.frameSelection.style.top =
                  e.clientY - 42 + that.$refs.efContainer.scrollTop + "px";
                that.frameSelectionList.top =
                  e.clientY - 42 + that.$refs.efContainer.scrollTop;
              } else {
                that.$refs.frameSelection.style.top = disY + "px";
                that.frameSelectionList.top = disY;
              }
              let width = Math.abs(X - e.clientX);
              let height = Math.abs(Y - e.clientY);
              that.$refs.frameSelection.style.width = width + "px";
              that.$refs.frameSelection.style.height = height + "px";
              that.frameSelectionList.nodeList.splice(0);
              that.frameSelectionList.lineList.splice(0);
              if (X < e.clientX && Y < e.clientY) {
                that.data.nodeList.forEach((item) => {
                  if (
                    parseInt(item.left.split("px")[0]) > disX &&
                    parseInt(item.left.split("px")[0]) <
                      e.clientX - 180 + that.$refs.efContainer.scrollLeft &&
                    parseInt(item.top.split("px")[0]) > disY &&
                    parseInt(item.top.split("px")[0]) <
                      e.clientY - 42 + that.$refs.efContainer.scrollTop
                  ) {
                    $(`#${item.id}`).addClass("node-endpoint-active");
                    that.frameSelectionList.nodeList.push(item);
                  } else {
                    $(`#${item.id}`).removeClass("node-endpoint-active");
                  }
                });
              } else if (X < e.clientX && Y > e.clientY) {
                that.data.nodeList.forEach((item) => {
                  if (
                    parseInt(item.left.split("px")[0]) > disX &&
                    parseInt(item.left.split("px")[0]) <
                      e.clientX - 180 + that.$refs.efContainer.scrollLeft &&
                    parseInt(item.top.split("px")[0]) < disY &&
                    parseInt(item.top.split("px")[0]) >
                      e.clientY - 42 + that.$refs.efContainer.scrollTop
                  ) {
                    $(`#${item.id}`).addClass("node-endpoint-active");
                    that.frameSelectionList.nodeList.push(item);
                  } else {
                    $(`#${item.id}`).removeClass("node-endpoint-active");
                  }
                });
              } else if (X > e.clientX && Y < e.clientY) {
                that.data.nodeList.forEach((item) => {
                  if (
                    parseInt(item.left.split("px")[0]) < disX &&
                    parseInt(item.left.split("px")[0]) >
                      e.clientX - 180 + that.$refs.efContainer.scrollLeft &&
                    parseInt(item.top.split("px")[0]) > disY &&
                    parseInt(item.top.split("px")[0]) <
                      e.clientY - 42 + that.$refs.efContainer.scrollTop
                  ) {
                    $(`#${item.id}`).addClass("node-endpoint-active");
                    that.frameSelectionList.nodeList.push(item);
                  } else {
                    $(`#${item.id}`).removeClass("node-endpoint-active");
                  }
                });
              } else if (X > e.clientX && Y > e.clientY) {
                that.data.nodeList.forEach((item) => {
                  if (
                    parseInt(item.left.split("px")[0]) < disX &&
                    parseInt(item.left.split("px")[0]) >
                      e.clientX - 180 + that.$refs.efContainer.scrollLeft &&
                    parseInt(item.top.split("px")[0]) < disY &&
                    parseInt(item.top.split("px")[0]) >
                      e.clientY - 42 + that.$refs.efContainer.scrollTop
                  ) {
                    $(`#${item.id}`).addClass("node-endpoint-active");
                    that.frameSelectionList.nodeList.push(item);
                  } else {
                    $(`#${item.id}`).removeClass("node-endpoint-active");
                  }
                });
              }
              that.data.lineList.forEach((lineItem) => {
                let flag = false;
                that.frameSelectionList.nodeList.forEach((item) => {
                  if (lineItem.from == item.id || lineItem.to == item.id) {
                    flag = true;
                  }
                });
                if (flag) {
                  that.frameSelectionList.lineList.push(lineItem);
                }
              });
            };
          } else if (e.button == 1) {
            that.$refs.efContainer.style.cursor = "move";
            let startX = event.pageX;
            let startY = event.pageY;
            let scrollLeft = that.$refs.efContainer.scrollLeft;
            let scrollTop = that.$refs.efContainer.scrollTop;
            document.onmousemove = function (e) {
              const x = event.pageX - startX;
              const y = event.pageY - startY;
              that.$refs.efContainer.scrollLeft = scrollLeft - x;
              that.$refs.efContainer.scrollTop = scrollTop - y;
            };
          }

          document.onmouseup = function (e) {
            that.$refs.efContainer.style.cursor = "default";
            that.$refs.frameSelection.style.cursor = "move";
            if (that.frameSelectionList.nodeList.length == 0) {
              that.$refs.frameSelection.style.width = 0;
              that.$refs.frameSelection.style.height = 0;
              that.$refs.frameSelection.style.left = 0;
              that.$refs.frameSelection.style.top = 0;
            }
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
    frameSelectionFlowDrag: {
      bind(el, binding) {
        if (!binding) {
          return;
        }
        el.onmousedown = (e) => {
          if (e.button == 2) {
            // 右键不管
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          let offsetX = e.offsetX;
          let offsetY = e.offsetY;
          that.frameSelectionList.nodeList.forEach((item) => {
            item.oldLeft = item.left;
            item.oldTop = item.top;
          });
          that.data.lineList.forEach((lineItem) => {
            let flag = false;
            that.frameSelectionList.nodeList.forEach((item) => {
              if (lineItem.from == item.id || lineItem.to == item.id) {
                flag = true;
              }
            });
            if (flag) {
              var conn = that.jsPlumb.getConnections({
                source: lineItem.from,
                target: lineItem.to,
              })[0];
              that.jsPlumb.deleteConnection(conn);
            }
          });
          document.onmousemove = function (e) {
            // 移动时禁止默认事件
            e.preventDefault();
            let left = parseInt(
              that.$refs.frameSelection.style.left.split("px")[0]
            );
            let top = parseInt(
              that.$refs.frameSelection.style.top.split("px")[0]
            );
            let styleLeft =
              e.clientX - 190 + that.$refs.efContainer.scrollLeft - offsetX;
            let styleTop =
              e.clientY - 52 + that.$refs.efContainer.scrollTop - offsetY;
            if (
              styleLeft > 0 &&
              styleLeft < that.$refs.efContainer.scrollWidth
            ) {
              that.$refs.frameSelection.style.left = styleLeft + "px";
            } else if (styleLeft <= 0) {
              that.$refs.frameSelection.style.left = "0px";
            } else {
              that.$refs.frameSelection.style.left =
                that.$refs.efContainer.scrollWidth -
                that.$refs.efContainer.offsetWidth +
                "px";
            }
            if (
              styleTop > 0 &&
              styleTop < that.$refs.efContainer.scrollHeight
            ) {
              that.$refs.frameSelection.style.top = styleTop + "px";
            } else if (styleTop <= 0) {
              that.$refs.frameSelection.style.top = "0px";
            } else {
              that.$refs.frameSelection.style.top =
                that.$refs.efContainer.scrollHeight -
                that.$refs.efContainer.offsetHeight +
                "px";
            }
            let newLeft = parseInt(
              that.$refs.frameSelection.style.left.split("px")[0]
            );
            let newTop = parseInt(
              that.$refs.frameSelection.style.top.split("px")[0]
            );
            that.data.nodeList.forEach((nodeItem) => {
              that.frameSelectionList.nodeList.forEach((item) => {
                if (item.id == nodeItem.id) {
                  let nodeLeft = parseInt(item.left.split("px")[0]);
                  let nodeTop = parseInt(item.top.split("px")[0]);
                  nodeItem.left = item.left = nodeLeft + newLeft - left + "px";
                  nodeItem.top = item.top = nodeTop + newTop - top + "px";
                }
              });
            });
          };
          document.onmouseup = function (e) {
            that.frameSelectionList.nodeList.forEach((node) => {
              that.modifyTaskNode("MOVE", node, null);
            });

            that.frameSelectionList.lineList.forEach((lineItem) => {
              var connParam = {
                source: lineItem.from,
                target: lineItem.to,
                label: lineItem.label ? lineItem.label : "",
                anchor: "Continuous",
              };
              that.jsPlumb.connect(connParam, that.jsplumbConnectOptions);
              that.data.lineList.forEach((line) => {
                if (line.from == lineItem.from && line.to == lineItem.to) {
                  that.$set(line, "label", lineItem.label);
                }
              });
            });
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  created() {
    this.getPipelineDataSourceList();
    that = this;
    if (localStorage.getItem("rightFormShow")) {
      this.rightFormShow = JSON.parse(localStorage.getItem("rightFormShow"));
    } else {
      localStorage.setItem("rightFormShow", true);
      this.rightFormShow = true;
    }
    this.jsPlumb = jsPlumb.getInstance();
    this.pipelineId = this.$route.query.id;
    this.tabId = this.$route.query.id;
    let parentTaskName = this.$route.query.parentTaskName;
    let tabIndex = this.$route.query.tabIndex;
    let frameSelectionTaskCreate = this.$route.query.frameSelectionTaskCreate;

    if (parentTaskName) {
      this.parentTaskName = parentTaskName;
    }
    if (tabIndex) {
      this.tabIndex = tabIndex * 1;
    }
    if (frameSelectionTaskCreate) {
      this.frameSelectionTaskCreate = true;
    }
    this.getUuid();
    this.getSpaceList();
    this.getCollect();
    document.body.addEventListener("keydown", this.keyboardShortcuts);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    keyboardShortcuts(e) {
      var targetElement = e.target;

      // 检查目标元素是否是输入框或文本区域
      var isInputElement =
        targetElement.tagName === "INPUT" ||
        targetElement.tagName === "TEXTAREA";

      // 如果是输入框或文本区域，阻止事件传播
      if (isInputElement) {
        e.stopPropagation();
      } else {
        // 在这里处理 body 上的 keydown 事件

        if (e.key == "ArrowLeft" && this.tabIndex > 0) {
          let index = this.tabIndex - 1;
          this.tabChange(this.tabList[index], index);
        }
        if (e.key == "ArrowRight" && this.tabIndex < this.tabList.length - 1) {
          let index = this.tabIndex + 1;
          this.tabChange(this.tabList[index], index);
        }
        if (
          this.tabList[0].status !== "DRAFT" &&
          this.tabList[0].status !== "RUNNING" &&
          (e.key == "e" || e.key == "E") &&
          !e.ctrlKey &&
          !e.metaKey
        ) {
          this.reEditClick();
        }
        if (
          this.tabList[0].status == "DRAFT" &&
          this.pipelineTaskType !== "API_TASK_TEMPLATE" &&
          !this.processed &&
          !this.templated &&
          !this.timed &&
          this.model == "BATCH" &&
          (e.key == "r" || e.key == "R") &&
          !e.ctrlKey &&
          !e.metaKey
        ) {
          this.quickSubmit();
        }
        if (
          !this.viewOnly &&
          (e.key == "d" || e.key == "D") &&
          !e.ctrlKey &&
          !e.metaKey &&
          this.frameSelectionList.nodeList.length > 0
        ) {
          this.FrameSelectionClick("删除");
        }
      }
    },
    getPipelineDataSourceList() {
      this.$http
        .get("/pipelineDatasource/pipelineDatasourceList")
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            this.datasourceIdList = list;
          }
        });
    },
    handleDragenter(e) {
      if (!this.$refs.efContainer.contains(e.relatedTarget)) {
        this.maskShow = true;
      }
    },
    handleDragleave(e) {
      if (!this.$refs.efContainer.contains(e.relatedTarget)) {
        this.maskShow = false;
      }
    },
    handleDragover(e) {},
    async handleDrop(e) {
      let fileList = await this.$common.dragFileList(e);

      let length = fileList.length;
      if (length > 0) {
        this.syncLoading = this.$syncLoading({
          message: `文件上传中(0/${length})...`,
          progressShow: true,
          percent: 0,
        });
        let index = 0;
        this.uploadPipelineOriginalFile(e, fileList, index, length);
      }
      this.maskShow = false;
    },
    uploadPipelineOriginalFile(e, fileList, index, length) {
      this.$http
        .files(
          "/sysPipeline/uploadPipelineOriginalFile",
          {
            file: fileList[index],
            taskId: this.pipelineId,
          },
          (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.syncLoading.update({
              percent,
            });
          }
        )
        .then((res) => {
          if (res.result == 200) {
            this.addNode(
              {
                clientX:
                  e.clientX +
                  this.$refs.efContainer.scrollLeft -
                  (window.innerWidth - this.$refs.efContainer.offsetWidth),
                clientY:
                  e.clientY +
                  this.$refs.efContainer.scrollTop -
                  (window.innerHeight - this.$refs.efContainer.offsetHeight) +
                  index * 100,
              },
              this.getMenuByType("fileLoadService"),
              null,
              res.data.fileUploadInfo
            );
          }
          this.uploadFileNext(e, fileList, index, length);
        })
        .catch(() => {
          this.uploadFileNext(e, fileList, index, length);
        });
    },
    uploadFileNext(e, fileList, index, length) {
      this.syncLoading.update({
        message: `文件上传中(${index + 1}/${length})...`,
      });

      if (index + 1 < length) {
        this.syncLoading.update({
          percent: 0,
        });
        setTimeout(() => {
          this.uploadPipelineOriginalFile(e, fileList, index + 1, length);
        }, 500);
      } else {
        setTimeout(() => {
          this.syncLoading.close();
        }, 1000);
      }
    },
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    getCollect() {
      let data = {
        taskId: this.pipelineId,
      };
      this.$http.get("/pipelineTask/isCollect", data).then((res) => {
        if (res.result === 200) {
          this.collect = res.data;
        }
      });
    },
    collectChange() {
      let data = {
        sourceId: this.pipelineId,
        collect: !this.collect,
      };
      this.$http
        .json_post("/pipelineTask/collectNormalTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success((data.collect ? "" : "取消") + "收藏成功");
            this.collect = !this.collect;
          }
        });
    },
    addIntermediateNode(e, from) {
      let node = this.data.nodeList.filter((node) => {
        return node.id == from;
      })[0];
      this.endpointNodeMenu(e, node);
      this.addIntermediateNodeShadeShow = true;
    },
    getSelectSubsequentNodes(nodeId, data) {
      let arr = this.data.lineList.filter((line) => {
        return line.from == nodeId;
      });
      let node = this.data.nodeList.filter((node) => {
        return node.id == nodeId;
      });
      if (node.length > 0) {
        let nodeArr = data.nodeList.filter((dataNode) => {
          return node[0] == dataNode;
        });
        // 去重
        if (nodeArr.length == 0) {
          data.nodeList.push(...node);
        }
      }
      if (arr.length > 0) {
        arr.forEach((line) => {
          // 去重
          let lineArr = data.lineList.filter((dataLine) => {
            return line == dataLine;
          });
          if (lineArr.length == 0) {
            data.lineList.push(line);
          }
          this.getSelectSubsequentNodes(line.to, data);
        });
      }
    },
    getSelectPreviousNodes(data) {
      data.nodeList.forEach((node) => {
        this.data.lineList.forEach((line) => {
          if (node.id == line.to) {
            data.lineList.push(line);
          }
        });
      });
      data.lineList = this.$common.unique(data.lineList);
    },
    checkAllClick(data) {
      let checkAllData = [];
      if (data) {
        checkAllData = lodash.cloneDeep(data);
      } else {
        checkAllData = lodash.cloneDeep(this.data);
      }
      let minLeft = checkAllData.nodeList[0].left.split("px")[0] * 1,
        minTop = checkAllData.nodeList[0].top.split("px")[0] * 1,
        maxTop = checkAllData.nodeList[0].top.split("px")[0] * 1,
        maxLeft = checkAllData.nodeList[0].left.split("px")[0] * 1;
      checkAllData.nodeList.forEach((item) => {
        if (item.left.split("px")[0] * 1 < minLeft) {
          minLeft = item.left.split("px")[0] * 1;
        }
        if (item.left.split("px")[0] * 1 > maxLeft) {
          maxLeft = item.left.split("px")[0] * 1;
        }
        if (item.top.split("px")[0] * 1 < minTop) {
          minTop = item.top.split("px")[0] * 1;
        }
        if (item.top.split("px")[0] * 1 > maxTop) {
          maxTop = item.top.split("px")[0] * 1;
        }
        $(`#${item.id}`).addClass("node-endpoint-active");
      });
      this.frameSelectionList = checkAllData;
      this.$refs.frameSelection.style.width = maxLeft - minLeft + 250 + "px";
      this.$refs.frameSelection.style.height = maxTop - minTop + 300 + "px";
      this.$refs.frameSelection.style.left = minLeft - 10 + "px";
      this.$refs.frameSelection.style.top = minTop - 10 + "px";
      this.frameSelectionList.left = minLeft - 10;
      this.frameSelectionList.top = minTop - 10;
    },
    analysisPriority() {
      if (this.nodePriorityShow) {
        this.nodePriorityShow = false;
      } else {
        let detail = JSON.stringify({
          nodeList: this.data.nodeList,
          lineList: this.data.lineList,
        });
        let data = {
          taskId: this.tabId,
          detail,
        };
        this.$http
          .json_post("/pipelineTask/analysisPriority", data)
          .then((res) => {
            if (res.result === 200) {
              this.data.nodeList.forEach((node) => {
                this.$set(node, "priority", res.data[node.id]);
              });
              this.nodePriorityShow = true;
            }
          });
      }
    },
    getNodeInfoList(detailRes, group, tag) {
      let data = {
        pipelineTaskType: this.pipelineTaskType,
        group,
        tag,
        model: this.model,
      };
      this.$http.get("/sysPipeline/nodeInfoList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.menuList.forEach((menu) => {
            menu.children.splice(0);
          });
          list.forEach((item) => {
            this.menuList.forEach((menu) => {
              if (menu.name == item.group.split("节点列表")[0]) {
                let obj = item;
                obj.property = {
                  keepResultFile: false,
                  resultFileName: "",
                  resultFileType: "txt",
                };
                let fileType = "";
                if (item.outFileType.indexOf(",") !== -1) {
                  fileType = item.outFileType.split(",")[0];
                } else {
                  fileType = item.outFileType;
                }
                if (fileType == "excel") {
                  obj.property.resultFileType = "xlsx";
                } else if (fileType == "csv") {
                  obj.property.resultFileType = "csv";
                } else if (fileType == "zip") {
                  obj.property.resultFileType = "zip";
                } else {
                  obj.property.resultFileType = "txt";
                }

                if (
                  obj.type == "diyAbstractMultipleInputAndOutputService" ||
                  obj.type == "diySingleInputAndMultipleOutputService" ||
                  obj.type == "diyAbstractSingleInputAndOutputService" ||
                  obj.type == "diyAbstractSingleInputAndNoOutputService" ||
                  obj.type == "diyAbstractMultipleInputAndOneOutputService"
                ) {
                  obj.extraInfoShow = obj.diyNodeBindForm;
                } else {
                  obj.extraInfoShow = false;
                  this.setMenuTypeList.forEach((type) => {
                    if (item.type == type) {
                      obj.extraInfoShow = true;
                    }
                  });
                }
                menu.children.push(obj);
              }
            });
            let index = 0;
            this.menuList.forEach((group) => {
              group.children.forEach((node) => {
                index++;
                this.$set(node, "index", index);
              });
            });
          });
          if (detailRes) {
            if (detailRes.data.composeWorkflowTaskList.length > 0) {
              this.composeWorkflowTaskList =
                detailRes.data.composeWorkflowTaskList;
              this.chooseWorkflowTask(0, "query");
            } else {
              this.dataReload(this.data);
            }
          }
        }
      });
    },
    importTemplate() {
      let list = this.$common.deepClone(this.composeWorkflowTaskList);
      if (list.length > 0) {
        list[0].workflowTask = this.$common.deepClone(this.data);
      }
      this.importTemplateData = JSON.stringify(list, null, 2);
      this.importTemplateShow = true;
    },
    importTemplate_submit() {
      let data = JSON.parse(this.importTemplateData);
      this.composeWorkflowTaskList = data;
      this.importTemplateShow = false;
      this.chooseWorkflowTask(0, "query", true);
    },
    efContainerClick() {
      if (this.rightFormShow) {
        this.nodeFormShow = false;
      }
    },
    rightForm() {
      if (this.nodeId) {
        this.nodeFormShow = true;
        this.rightFormShow = true;
        localStorage.setItem("rightFormShow", true);
        this.$nextTick(() => {
          this.activeElement.type = "node";
          this.activeElement.nodeId = this.nodeId;
          this.activeElement.name = this.nodeName;
          this.$refs.nodeForm.nodeInit(this.data, this.nodeId);
        });
      } else {
        this.$message.warning("请先选择节点");
      }
    },
    async FrameSelectionClick(instruction, e, type, nodeId, initBlankTask) {
      switch (instruction) {
        case "复制":
          if (initBlankTask) {
            let composeWorkflowTaskList = JSON.parse(
              this.FrameSelectionTaskDetail
            );
            this.copyList = composeWorkflowTaskList[0].workflowTask;
            let COMPOSE_NODE = this.copyList.nodeList.filter((node) => {
              return node.type == "COMPOSE_NODE";
            });
            if (COMPOSE_NODE.length > 0) {
              this.copyList.nodeList.forEach((node) => {
                if (node.type == "COMPOSE_NODE") {
                  this.$set(
                    node,
                    "composeWorkflowTask",
                    ...composeWorkflowTaskList.filter((workflowTask) => {
                      return workflowTask.composeNodeId == node.id;
                    })
                  );
                }
              });
            }
            let data = {
              name: this.frameTaskName,
              model: this.model,
              pipelineTaskType: this.pipelineTaskType,
            };
            await this.$http
              .json_post("/pipelineTask/initBlankTask", data)
              .then((res) => {
                localStorage.setItem("copyList", JSON.stringify(this.copyList));
                this.$message.success("创建成功");
                this.frameSelectionCreateShow = false;
                sessionStorage.removeItem("modifyMap");
                let query = {
                  id: res.data.pipelineTask.id,
                  frameSelectionTaskCreate: true,
                };
                this.$router.replace({
                  path: "/panel",
                  query,
                });
              });
          } else {
            if (type == "node") {
              this.copyList = {
                nodeList: [],
                lineList: [],
                left: null,
                top: null,
              };
              let node = this.data.nodeList.filter((item) => {
                return item.id == nodeId;
              })[0];
              this.copyList.nodeList.push(JSON.parse(JSON.stringify(node)));
            } else {
              this.copyList = JSON.parse(
                JSON.stringify(this.frameSelectionList)
              );
              let COMPOSE_NODE = this.copyList.nodeList.filter((node) => {
                return node.type == "COMPOSE_NODE";
              });
              if (COMPOSE_NODE.length > 0) {
                this.copyList.nodeList.forEach((node) => {
                  if (node.type == "COMPOSE_NODE") {
                    this.$set(
                      node,
                      "composeWorkflowTask",
                      ...this.composeWorkflowTaskList.filter((workflowTask) => {
                        return workflowTask.composeNodeId == node.id;
                      })
                    );
                  }
                });
              }
            }
            localStorage.setItem("copyList", JSON.stringify(this.copyList));
            this.$message.success("复制成功");
          }
          break;
        case "收缩":
          this.nodeIdList = this.frameSelectionList.nodeList.map((item) => {
            return item.id;
          });
          this.groupName = "";
          this.composeTitle = "收缩节点";
          this.composeSubmitShow = true;
          break;
        case "展开":
          var groupName = this.data.nodeList.filter((item) => {
            return item.id == nodeId;
          })[0].name;
          this.disposeComposeWorkflowTaskList();
          let data = {
            taskId: this.tabId,
            detail: JSON.stringify(this.composeWorkflowTaskList),
            groupName,
            uuid: this.uuid,
          };
          this.$http
            .json_post("/sysPipeline/deComposeTaskNode", data)
            .then((res) => {
              if (res.result === 200) {
                this.composeWorkflowTaskList = res.data;
                this.chooseWorkflowTask(0, "query");
                this.$message.success("展开成功");
              }
            });
          break;
        case "重命名":
          var groupName = this.data.nodeList.filter((item) => {
            return item.id == nodeId;
          })[0].name;
          this.composeTitle = "收缩节点重命名";
          this.editComposeNodeId = nodeId;
          this.groupName = groupName;
          this.composeSubmitShow = true;
          break;
        case "删除":
          this.frameSelectionList.nodeList.forEach((item) => {
            this.data.nodeList = this.data.nodeList.filter((node) => {
              if (node.id === item.id) {
                this.modifyTaskNode("DELETE", node);
                return false;
              }
              return true;
            });
            this.$nextTick(() => {
              this.jsPlumb.removeAllEndpoints(item.id);
            });
          });
          this.$message.success("删除成功");
          break;
        case "创建任务":
          this.frameSelectionList.left = this.$refs.efContainer.scrollLeft;
          this.frameSelectionList.top = this.$refs.efContainer.scrollTop;
          this.frameSelectionData = JSON.parse(
            JSON.stringify(this.frameSelectionList)
          );
          let lineArr = [];
          this.frameSelectionData.lineList.forEach((line, index) => {
            let from = false;
            let to = false;
            this.frameSelectionData.nodeList.forEach((node) => {
              if (line.from == node.id) {
                from = true;
              }
              if (line.to == node.id) {
                to = true;
              }
            });
            if (!from || !to) {
              lineArr.push(line);
            }
          });
          this.frameSelectionData.lineList =
            this.frameSelectionData.lineList.filter((line) => {
              return lineArr.every((item) => {
                return !(line.from == item.from && line.to == item.to);
              });
            });
          this.generateFrameSelectionTaskName();
          break;
        case "粘贴":
          this.pasteLoading = true;
          try {
            for (
              let index = 0;
              index < this.copyList.nodeList.length;
              index++
            ) {
              const item = this.copyList.nodeList[index];
              if (item.type == "fileStoreService") {
                await this.$http
                  .get("/sysPipeline/generateName")
                  .then((res) => {
                    if (res.result === 200) {
                      item.extraInfo.code = res.data.name;

                      let data = {
                        taskId: this.tabId,
                        api: "storeTagInfo",
                        action: item.type,
                        tag: item.extraInfo.tag,
                        code: item.extraInfo.code,
                      };
                      this.$http.get("/sysPipeline/storeDynamicApiData", data);
                    }
                  });
              }
              this.$set(item, "state", "WAITING");
              this.$set(item, "errorMessage", "");
              this.$set(item, "totalCount", 0);
              this.$set(item, "completeCount", 0);
              let nodeLeft = parseInt(item.left.split("px")[0]);
              let nodeTop = parseInt(item.top.split("px")[0]);

              if (this.copyList.left) {
                item.left =
                  nodeLeft +
                  e.clientX -
                  e.offsetX -
                  190 +
                  this.$refs.efContainer.scrollLeft -
                  this.copyList.left +
                  "px";
                item.top =
                  nodeTop +
                  e.clientY -
                  e.offsetY -
                  52 +
                  this.$refs.efContainer.scrollTop -
                  this.copyList.top +
                  "px";
              } else {
                item.left =
                  e.clientX -
                  e.offsetX -
                  190 +
                  this.$refs.efContainer.scrollLeft +
                  "px";
                item.top =
                  e.clientY -
                  e.offsetY -
                  52 +
                  this.$refs.efContainer.scrollTop +
                  "px";
              }
              let id = item.id;
              item.id = await this.generateNodeId();

              var origName = "";
              if (item.type == "PROCESS_INPUT") {
                origName = this.processList[0].name;
              } else if (item.type == "PROCESS_OUTPUT") {
                origName = this.processList[1].name;
              } else if (item.type == "COMPOSE_NODE") {
                origName = item.name;
                item.composeWorkflowTask.composeNodeId = item.id;
                this.composeWorkflowTaskList.push(
                  lodash.cloneDeep(item.composeWorkflowTask)
                );
                delete item.composeWorkflowTask;
              } else {
                origName = this.getMenuByNodeId(item.nodeId).name;
                let index = 1;
                while (index < 10000) {
                  var repeat = false;
                  for (var i = 0; i < this.data.nodeList.length; i++) {
                    let node = this.data.nodeList[i];
                    if (node.name === item.name) {
                      item.name = origName + index;
                      repeat = true;
                    }
                  }
                  if (repeat) {
                    index++;
                    continue;
                  }
                  break;
                }
              }
              if (item.type == "parseJobPipelineService") {
                item.extraInfo.relationPipelineUrl =
                  location.href + "&parseJobNodeId=" + item.id;
              }

              if (item.property.resultFileType) {
                item.property.resultFileName =
                  item.id +
                  "." +
                  (item.property.resultFileType == "excel"
                    ? "xlsx"
                    : item.property.resultFileType);
              } else {
                item.property.resultFileName = item.id + ".txt";
              }

              if (
                item.type == "columnGroupService" ||
                item.type == "zipService" ||
                item.type == "fileCompressionService"
              ) {
                item.property.resultFileName =
                  item.property.resultFileName.split(".")[0] + ".zip";
                item.property.resultFileType = "zip";
              }
              this.jsplumbSourceOptions.maxConnections = 999;
              this.jsplumbTargetOptions.maxConnections = item.maxInputCount;
              this.data.nodeList.push(item);
              this.modifyTaskNode("ADD", null, item);
              this.$nextTick(() => {
                this.jsPlumb.makeSource(item.id, this.jsplumbSourceOptions);
                this.jsPlumb.makeTarget(item.id, this.jsplumbTargetOptions);
                this.jsPlumb.draggable(item.id, {
                  containment: "parent",
                  grid: [15, 15],
                });
              });
              this.copyList.lineList.forEach((line) => {
                if (line.from == id) {
                  line.from = item.id;
                }
                if (line.to == id) {
                  line.to = item.id;
                }
              });
            }
            this.copyList.lineList.forEach((line) => {
              let fromFlag = false,
                toFlag = false;
              fromFlag = that.copyList.nodeList.some((item) => {
                return item.id == line.from;
              });
              toFlag = that.copyList.nodeList.some((item) => {
                return item.id == line.to;
              });
              if (fromFlag && toFlag) {
                this.$nextTick(() => {
                  var connParam = {
                    source: line.from,
                    target: line.to,
                    label: line.label ? line.label : "",
                    anchor: "Continuous",
                  };
                  this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
                  this.data.lineList.forEach((lineItem) => {
                    if (line.from == lineItem.from && line.to == lineItem.to) {
                      if (lineItem.label !== line.label) {
                        this.modifyTaskNodeLine(
                          "MODIFY",
                          {
                            from: line.from,
                            to: line.to,
                            initialLabel: lineItem.label,
                          },
                          {
                            from: line.from,
                            to: line.to,
                            label: line.label,
                          }
                        );
                      }
                      this.$set(lineItem, "label", line.label);
                    }
                  });
                });
              }
            });
            this.$message.success("粘贴成功");
            if (this.frameSelectionTaskCreate) {
              localStorage.removeItem("copyList");
            }

            setTimeout(() => {
              this.pasteLoading = false;
            }, 200);
            throw new Error("粘贴成功");
          } catch (error) {
            if (error.message != "粘贴成功") {
              this.$message.error("粘贴失败");

              setTimeout(() => {
                this.pasteLoading = false;
              }, 200);
              throw error;
            }
          }
          break;
        case "重置状态":
          this.frameSelectionList.nodeList.forEach((selectNode) => {
            this.data.nodeList.forEach((node) => {
              if (selectNode.id === node.id) {
                if (node.state !== "WAITING") {
                  if (node.type == "ftpReceiverService") {
                    this.$message.error("FTP接收节点不能被重置");
                    return;
                  }
                  let oldNode = lodash.cloneDeep(node);
                  this.modifyTaskNode("RESET", oldNode);
                  this.$set(node, "state", "WAITING");
                  this.$set(node, "errorMessage", "");
                  this.$set(node, "totalCount", 0);
                  this.$set(node, "completeCount", 0);
                }
                if (node.type == "COMPOSE_NODE") {
                  this.getNextComposeNodeAndResetState(item.name);
                }
                this.getNextNodeAndResetState(node.id);
              }
            });
          });

          break;
      }
      this.frameSelectionList.nodeList.splice(0);
      this.frameSelectionList.lineList.splice(0);
      this.$refs.frameSelection.style.width = 0;
      this.$refs.frameSelection.style.height = 0;
      this.$refs.frameSelection.style.left = 0;
      this.$refs.frameSelection.style.top = 0;
      this.frameSelectionList.left = 0;
      this.frameSelectionList.top = 0;
    },
    FrameSelectionBatchConnectClick(e) {
      this.endpointNodeMenu(e);
    },
    generateFrameSelectionTaskName() {
      let composeWorkflowTaskList = [];
      composeWorkflowTaskList.push({
        name: "主流程",
        color: "#f2f2f2",
        composeNodeId: "",
        workflowTask: JSON.parse(JSON.stringify(this.frameSelectionData)),
      });
      composeWorkflowTaskList[0].workflowTask.nodeList.forEach((node) => {
        node.state = "WAITING";
        node.errorMessage = "";
        node.totalCount = 0;
        node.completeCount = 0;
        Reflect.deleteProperty(node, "icon");
        Reflect.deleteProperty(node, "testable");
        Reflect.deleteProperty(node, "remark");
        Reflect.deleteProperty(node, "nodeInfoRemark");
        Reflect.deleteProperty(node, "extraInfoShow");
        Reflect.deleteProperty(node, "dynamicUpdateProgress");
        Reflect.deleteProperty(node, "maxInputCount");
        Reflect.deleteProperty(node, "maxOutputCount");
        Reflect.deleteProperty(node, "minInputCount");
        Reflect.deleteProperty(node, "minOutputCount");
      });
      this.FrameSelectionTaskDetail = JSON.stringify(
        composeWorkflowTaskList,
        null,
        4
      ).toString();
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.frameTaskName = res.data.name;
          this.frameSelectionCreateShow = true;
        }
      });
    },
    FrameSelectionTask() {
      this.FrameSelectionClick("复制", null, "FrameSelection", null, true);
    },
    disposeComposeWorkflowTaskList() {
      this.data.left = this.$refs.efContainer.scrollLeft;
      this.data.top = this.$refs.efContainer.scrollTop;
      let detail = lodash.cloneDeep(this.data);
      if (this.composeWorkflowTaskList.length > 0) {
        this.composeWorkflowTaskList[this.workflowTaskItemActive].workflowTask =
          detail;
      } else {
        this.composeWorkflowTaskList.push({
          name: "主流程",
          workflowTask: detail,
        });
      }
    },
    composeSubmit() {
      if (this.composeTitle == "收缩节点") {
        this.disposeComposeWorkflowTaskList();
        let data = {
          taskId: this.tabId,
          detail: JSON.stringify(this.composeWorkflowTaskList),
          nodeIdList: this.nodeIdList,
          groupName: this.groupName,
          uuid: this.uuid,
        };
        this.$http
          .json_post("/sysPipeline/composeTaskNode", data)
          .then((res) => {
            if (res.result === 200) {
              this.composeSubmitShow = false;
              this.composeWorkflowTaskList = res.data;
              this.chooseWorkflowTask(0, "query");
              this.$message.success("收缩成功");
            }
          })
          .catch(() => {
            this.composeSubmitShow = false;
          });
      } else {
        this.data.nodeList.forEach((node) => {
          if (node.id == this.editComposeNodeId) {
            this.composeWorkflowTaskList.forEach((item) => {
              if (item.name == node.name) {
                this.$set(item, "name", this.groupName);
                this.$set(node, "name", this.groupName);
                this.composeSubmitShow = false;
              }
            });
          }
        });
      }
    },
    panelRightMenu(e) {
      if (this.pasteLoading) {
        return;
      }
      let pasteShow = true;
      if (localStorage.getItem("copyList") && !this.viewOnly) {
        this.copyList = JSON.parse(localStorage.getItem("copyList"));
        if (this.copyList.nodeList.length == 0) {
          pasteShow = false;
        }
      } else {
        pasteShow = false;
      }
      e.preventDefault();
      e.stopPropagation();
      this.nodeRightMenu(e, "panel", { pasteShow });
    },
    copyRightMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      this.nodeRightMenu(e, "frameSelection");
      let arr = this.frameSelectionList.nodeList.filter((node) => {
        return node.type == "COMPOSE_NODE";
      });
      if (arr.length == 0) {
        this.frameSelectionNodeListHasComposeNodeAndDelete = true;
        if (this.frameSelectionList.nodeList.length > 1) {
          this.frameSelectionNodeListHasComposeNodeAndCompose = true;
        } else {
          this.frameSelectionNodeListHasComposeNodeAndCompose = false;
        }
      } else {
        this.frameSelectionNodeListHasComposeNodeAndDelete = false;
        this.frameSelectionNodeListHasComposeNodeAndCompose = false;
      }
    },
    rename_submit() {
      let data = {
        id: this.tabId,
        name: this.taskName,
      };
      this.$http.json_post("/pipelineTask/renameTask", data).then((res) => {
        if (res.result == 200) {
          this.reNameShow = false;
          this.tabList.forEach((item) => {
            if (this.tabId == item.id) {
              this.$set(item, "name", this.taskName);
            }
          });
          this.$message.success("修改成功");
        }
      });
    },
    copySourceInputFileChange() {
      localStorage.setItem("copySourceInputFile", this.copySourceInputFile);
    },
    reEditClick() {
      this.$http
        .json_post("/sysPipeline/reEditPipelineTask", { id: this.tabId })
        .then((res) => {
          if (res.result === 200) {
            let query = {
              id: this.pipelineId,
              tabIndex: this.tabIndex,
            };
            if (this.resetStateNodeId) {
              query.resetStateNodeId = this.resetStateNodeId;
              this.$router.replace({
                path: "/panel",
                query,
              });
            } else {
              this.$router.go(0);
            }
          }
        });
    },
    copyClick() {
      localStorage.getItem("copySourceInputFile");
      if (localStorage.getItem("copySourceInputFile")) {
        if (localStorage.getItem("copySourceInputFile") == "true") {
          this.copySourceInputFile = true;
        } else {
          this.copySourceInputFile = false;
        }
      } else {
        this.copySourceInputFile = false;
      }
      this.copyClickContent = "复制【" + this.data.name + "】任务, 是否继续?";
      this.copyClickShow = true;
    },
    copyClick_submit() {
      let data = {
        id: this.pipelineId,
        copySourceInputFile: this.copySourceInputFile,
      };
      this.$http
        .json_post("/sysPipeline/copyPipelineTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("操作成功");
            sessionStorage.removeItem("modifyMap");
            this.$router.replace({
              path: "/panel",
              query: {
                id: res.data.pipelineTask.id,
              },
            });
          }
        });
    },
    ReName(item) {
      this.copyTabId = item.id;
      this.taskName = item.name;
      this.reNameShow = true;
    },
    viewScroll() {
      $(".mask").css("left", this.$refs.efContainer.scrollLeft / 2.6);
      $(".mask").css("top", this.$refs.efContainer.scrollTop / 2.6);
    },
    miniStart(e) {
      e.target.style.cursor = "move";
      this.clientX = e.clientX;
      this.clientY = e.clientY;
      this.mouseViewShow = true;
    },
    miniStop(e) {
      e.target.style.cursor = "default";
      this.mouseViewShow = false;
    },
    miniMove(e) {
      if (this.mouseViewShow) {
        const left = e.clientX - this.clientX;
        this.clientX = e.clientX;
        const top = e.clientY - this.clientY;
        this.clientY = e.clientY;
        this.$refs.efContainer.scrollLeft += left * 2.6 * 17;
        this.$refs.efContainer.scrollTop += top * 2.6 * 17;
        $(".mask").css("left", this.$refs.efContainer.scrollLeft / 2.6);
        $(".mask").css("top", this.$refs.efContainer.scrollTop / 2.6);
      }
    },
    //开始放大缩小
    startScale() {
      this.$refs.eeContainer.style.transform = `scale(${this.scaleValue})`;
      this.jsPlumb.setZoom(`${this.scaleValue}`);
      // 获取efContainer的宽高作为原始宽高,然后以放大/缩小倍数计算后给eecontainer
      var containWidth = $("#efContainer").width() * 4;
      var containHeight = $("#efContainer").height() * 4;
      if (this.scaleValue < 1) {
        $("#eeContainer").width(containWidth / this.scaleValue);
        $("#eeContainer").height(containHeight / this.scaleValue);
      } else if (this.scaleValue > 1) {
        $("#eeContainer").width(containWidth * this.scaleValue);
        $("#eeContainer").height(containHeight * this.scaleValue);
      }
    },
    // 立即执行复选框
    checkboxChange(val) {
      if (val) {
        // this.data.executeDate1 = new Date();
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var h = date.getHours();
        h = h < 10 ? "0" + h : h;
        var min = date.getMinutes();
        min = min < 10 ? "0" + min : min;
        var s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        this.executeDate =
          y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
      } else {
        this.executeDate = "";
      }
    },
    // 关闭右边编辑表单
    flowNodeFormClose(val) {
      this.nodeFormShow = false;
      this.rightFormShow = false;
      localStorage.setItem("rightFormShow", false);
    },
    confirmPauseNode(nodeId, passed) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">
            确定要 {passed ? "继续执行" : "停止"} 吗?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            taskId: this.tabId,
            nodeId,
            passed,
          };
          this.$http.get("/sysPipeline/confirmPauseNode", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("操作成功");
            }
          });
        },
        onCancel: () => {},
      });
    },
    download(node) {
      this.loadingTable = true;
      if (node) {
        if (node.type == "COMPOSE_NODE") {
          let nodeIdList = [];
          let nodeList = this.composeWorkflowTaskList.filter((item) => {
            return item.name == node.name;
          })[0].workflowTask.nodeList;
          nodeList.forEach((item) => {
            if (
              item.type !== "PROCESS_INPUT" &&
              item.type !== "PROCESS_OUTPUT"
            ) {
              nodeIdList.push(item.id);
            }
          });
          this.fileNodeId = nodeIdList.join(",");
        } else {
          this.fileNodeId = node.id;
        }
      }
      let data = {
        pipelineLogId: this.tabId,
        nodeId: this.fileNodeId,
      };
      this.$http.get("/pipelineFile/fileList", data).then((res) => {
        if (res.result === 200) {
          this.fileListTableData = res.data;
          this.fileListTableData.map((item) => {
            if (item.lineCount == -1) {
              this.$set(item, "lineCount", "未知");
              this.$set(item, "lineCountLabel", "未知");
            } else {
              this.$set(
                item,
                "lineCountLabel",
                this.$common.formatTotalCount(item.lineCount)
              );
            }
          });
          this.loadingTable = false;
          this.downloadShow = true;
        }
      });
    },
    batch_download() {
      let ids = this.fileSelectTableData.join(",");
      location.href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/pipelineFile/download?id=" +
        ids;
      this.clearSelect();
    },
    clearSelect() {
      this.fileSelectTableData.splice(0);
    },
    downloadClick(val) {
      let path = location.protocol + process.env.VUE_APP_BASE_API;
      let href = path + "/pipelineFile/download?id=" + val.id;
      window.location.href = href;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.fileSelectTableData.splice(0);
      this.fileSelectTableData.push(...selectedRowKeys);
    },
    random() {
      this.password = this.$common.random();
    },
    // 分享确定
    shareConfirm() {
      this.syncLoading = this.$syncLoading({
        message: "分享链接生成中...",
      });
      this.getFileShareLink();
    },
    getFileShareLink() {
      let data = {
        id: this.pipelineId,
        fileId: this.fileId,
        nodeId: this.fileNodeId,
        password: this.password,
        expireSeconds: this.expireSeconds,
        fileSpaceId: this.fileSpaceId,
        remark: this.remark,
      };
      this.innerVisible = false;
      this.$http
        .get("/pipelineTask/getFileShareLink", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.url) {
              this.copyVisible = true;
              this.inviteCode = res.data.url;
              this.syncLoading.close();
              this.$message.success("分享成功");
              clearInterval(this.shareTimer);
              this.shareTimer = null;
            } else if (!this.shareTimer) {
              this.shareTimer = setInterval(() => {
                this.getFileShareLink();
              }, 2000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    fileListClosed() {
      this.fileListTableData = [];
      this.clearSelect();
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileName.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      let id = val.id;
      this.$http
        .get("/pipelineFile/checkFileContent", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            if (res.data.state == "ARCHIVED") {
              this.$confirm({
                title: "文件已归档，查看需要进行解冻",
                content: (h) => <div style="color:red;">确认解冻吗</div>,
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.restore(id);
                },
                onCancel: () => {},
              });
            } else if (res.data.state == "SUCCESS") {
              if (this.fileType == "xlsx" || this.fileType == "xls") {
                res.data.data.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                  };
                  item.forEach((value, key) => {
                    obj["column" + (key + 1)] = value;
                  });
                  this.excelFileList.push(obj);
                });

                res.data.data[0].forEach((value, index) => {
                  this.excelFileColumns.push({
                    title: "列" + (index + 1),
                    ellipsis: true,
                    dataIndex: "column" + (index + 1),
                    width: 100,
                  });
                });
              } else {
                this.fileContent = res.data.data[0].join("\n");
              }
              this.checkFileContentShow = true;
            }
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    restore(id) {
      this.syncLoading = this.$syncLoading({
        message: "解冻中...",
      });
      this.$http
        .post("/pipelineFile/restore", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.queryRestoreStatus(id);
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    queryRestoreStatus(id) {
      this.$http
        .get("/pipelineFile/queryRestoreStatus", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            if (res.data !== "RUNNING") {
              clearTimeout(this.queryRestoreStatusTimer);
              this.queryRestoreStatusTimer = null;
              this.syncLoading.close();
              if (res.data == "DONE") {
                this.$message.success("解冻成功");
              } else {
                this.$message.error("解冻失败");
              }
            } else {
              this.queryRestoreStatusTimer = setTimeout(() => {
                this.queryRestoreStatus(id);
              }, 1000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    copyPath(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/pipelineFile/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    uploadOss(val) {
      let data = {
        id: val.id,
      };
      this.syncLoading = this.$syncLoading({
        message: "上传中...",
      });
      this.$http
        .json_post("/pipelineFile/upload2Oss", data)
        .then((res) => {
          if (res.result === 200) {
            this.queryUpload2OssStatus(data.id);
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    queryUpload2OssStatus(id) {
      let data = {
        id,
      };
      this.$http
        .get("/pipelineFile/queryUpload2OssStatus", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data == "RUNNING") {
              setTimeout(() => {
                this.queryUpload2OssStatus(id);
              }, 1000);
            } else {
              this.syncLoading.close();
              if (res.data == "DONE") {
                this.$message.success("上传成功");
              } else {
                this.$message.error("上传失败");
              }
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    downloadLocal(val) {
      let data = {
        id: val.id,
      };
      this.syncLoading = this.$syncLoading({
        message: "下载中...",
      });
      this.$http
        .json_post("/pipelineFile/download2Local", data)
        .then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            this.$message.success("下载成功");
            this.download();
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    fileAnalysis(record) {
      this.fileId = record.id;
      this.lineCount = record.lineCount;
      this.fileNodeId = record.nodeId;
      this.syncLoading = null;
      let timeout = setTimeout(() => {
        this.syncLoading = this.$syncLoading({
          message: "分析中...",
        });
      }, 500);
      this.fileAnalysisData.splice(0);
      this.columnParamList.splice(0);
      this.columnItemValueList.splice(0);
      this.fileAnalysisColumnList.splice(0);
      this.fileAnalysisColumns.splice(0);
      this.queryStatus(timeout);
    },
    queryStatus(timeout) {
      let data = {
        taskId: this.tabId,
        nodeId: this.fileNodeId,
        fileId: this.fileId,
      };
      this.$http
        .get("/fileAnalysis/queryStatus", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.status == "CREATE_HIVED") {
              this.fileAnalysisColumnId = res.data.id;
              this.fileAnalysisColumnList = JSON.parse(res.data.extraInfo);
              this.fileAnalysisColumnList.forEach((item) => {
                this.$set(item, "visible", false);
              });
              this.findAllData(timeout);
            } else {
              let params = {
                id: res.data.id,
                status: res.data.status,
                event: "",
                ...data,
              };
              switch (res.data.status) {
                case "CREATE_ANALYSIS":
                  params.event = "SAVE_FIELD";
                  this.changeStatus(params, timeout);
                  break;
                case "SAVE_FIELD":
                  params.event = "UPLOAD_PARQUET";
                  this.changeStatus(params, timeout);
                  break;
                case "UPLOAD_PARQUETED":
                  params.event = "CREATE_HIVE";
                  this.changeStatus(params, timeout);
                  break;
                default:
                  this.queryStatus();
                  break;
              }
            }
          }
        })
        .catch(() => {
          if (this.syncLoading) {
            this.syncLoading.close();
          } else {
            clearTimeout(timeout);
          }
        });
    },
    changeStatus(data, timeout) {
      this.$http
        .json_post("/fileAnalysis/changeStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.queryStatus();
          }
        })
        .catch(() => {
          if (this.syncLoading) {
            this.syncLoading.close();
          } else {
            clearTimeout(timeout);
          }
        });
    },
    findAllData(timeout) {
      let data = {
        taskId: this.tabId,
        nodeId: this.fileNodeId,
        fileId: this.fileId,
      };
      this.$http.get("/fileAnalysis/findAllData", data).then((res) => {
        if (res.result === 200) {
          this.fileAnalysisData = res.data.data;
          this.fileAnalysisColumns = this.fileAnalysisColumnList.map((item) => {
            return {
              ellipsis: true,
              dataIndex: item.name,
              scopedSlots: { title: item.name },
            };
          });
          this.columnParamList = this.fileAnalysisColumnList.map((item) => {
            return {
              columnName: item.name,
              valueList: [],
            };
          });
          this.fileAnalysisShow = true;
          if (this.syncLoading) {
            this.syncLoading.close();
          } else if (timeout) {
            clearTimeout(timeout);
          }
        }
      });
    },
    editColumn(column, index) {
      this.columnName = column.alias || column.name;
      this.columnIndex = index;
      this.editColumnShow = true;
    },
    editColumnSubmit() {
      this.$set(
        this.fileAnalysisColumnList[this.columnIndex],
        "alias",
        this.columnName
      );
      let data = {
        id: this.fileAnalysisColumnId,
        extraInfo: JSON.stringify(this.fileAnalysisColumnList),
      };

      this.$http
        .json_post("/fileAnalysis/updateExtraInfo", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.editColumnShow = false;
          }
        });
    },
    showPopover(column, index) {
      // 先关闭 popover，确保状态一致
      this.columnItemValueList =
        this.columnParamList.find((item) => item.columnName === column.name)
          ?.valueList || [];
      this.checkAll =
        this.columnItemValueList.length === this.columnItemGroupList.length &&
        this.columnItemGroupList.length !== 0;
      this.columnIndex = index;
      this.indeterminate =
        this.columnItemValueList.length < this.columnItemGroupList.length &&
        this.columnItemValueList.length != 0;
      this.columnSearchKey = "";

      if (this.columnName !== column.name) {
        this.columnItemAllGroupList.splice(0);
        this.columnItemGroupList.splice(0);
        setTimeout(() => {
          this.columnName = column.name;
          let paramList = this.columnParamList.filter((item) => {
            return item.valueList.length > 0;
          });
          let data = {
            columnName: this.columnName,
            taskId: this.tabId,
            nodeId: this.fileNodeId,
            fileId: this.fileId,
            value: this.columnSearchKey,
            paramList,
          };

          this.$http.get("/fileAnalysis/findGroupCount", data).then((res) => {
            if (res.result === 200) {
              this.columnItemAllGroupList.push(...res.data.data);
              this.columnItemAllGroupList.forEach((item) => {
                this.$set(
                  item,
                  "label",
                  `${item[this.columnName]} ( ${item.count} , ${(
                    (item.count / this.lineCount) *
                    100
                  ).toFixed(4)}% )`
                );
                this.$set(item, "value", item[this.columnName]);
              });
              this.columnItemGroupList.push(...this.columnItemAllGroupList);
            }
          });
        }, 500);
      }
    },
    allowClearColumnSearchKeyChange(e) {
      if (e.target.value) {
        return;
      }
      this.columnSearch();
    },
    columnSearch() {
      this.columnItemValueList.splice(0);
      this.columnItemAllGroupList.splice(0);
      this.columnItemGroupList.splice(0);
      this.checkAll = false;
      this.indeterminate = false;

      let paramList = this.columnParamList.filter((item) => {
        return item.valueList.length > 0;
      });
      let data = {
        columnName: this.columnName,
        taskId: this.tabId,
        nodeId: this.fileNodeId,
        fileId: this.fileId,
        value: this.columnSearchKey,
        paramList,
      };

      this.$http.get("/fileAnalysis/findGroupCount", data).then((res) => {
        if (res.result === 200) {
          this.columnItemAllGroupList.push(...res.data.data);
          this.columnItemAllGroupList.forEach((item) => {
            this.$set(
              item,
              "label",
              `${item[this.columnName]} ( ${item.count} , ${(
                (item.count / this.lineCount) *
                100
              ).toFixed(4)}% )`
            );
            this.$set(item, "value", item[this.columnName]);
          });
          this.columnItemGroupList.push(...this.columnItemAllGroupList);
        }
      });
    },
    checkAllChange(e) {
      Object.assign(this, {
        columnItemValueList: e.target.checked
          ? this.columnItemGroupList.map((item) => {
              return item.value;
            })
          : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    checkChange(checkedList) {
      this.indeterminate =
        !!checkedList.length &&
        checkedList.length < this.columnItemGroupList.length;
      this.checkAll = checkedList.length === this.columnItemGroupList.length;
    },
    findDataByIn(column) {
      this.columnParamList.forEach((item) => {
        if (item.columnName == this.columnName) {
          this.$set(item, "valueList", this.columnItemValueList);
        }
      });
      let paramList = this.columnParamList.filter((item) => {
        return item.valueList.length > 0;
      });
      let data = {
        columnName: this.columnName,
        paramList,
        taskId: this.tabId,
        nodeId: this.fileNodeId,
        fileId: this.fileId,
        extraInfo: JSON.stringify(this.fileAnalysisColumnList),
      };
      this.$http.json_post("/fileAnalysis/findDataByIn", data).then((res) => {
        if (res.result === 200) {
          this.fileAnalysisData = res.data.data;
          this.$set(column, "visible", false);
        }
      });
    },
    shareClick(val) {
      this.fileId = val.id;
      this.shareUrl = val.link;
      this.innerVisible = true;
      this.passwordSwitch = false;
      this.password = "";
      this.remark = "";
      this.expireSeconds = 2592000;
      this.fileSpaceId = -1;
    },
    // 右键点击操作
    setClick(nodeId, label, line, test) {
      if (line) {
        this.setDataType = "line";
        this.nodeData.nodeInfoRemark = "";
        this.$refs.setData.info(false);
      } else {
        if (nodeId) {
          let list = this.data.nodeList.filter((item) => {
            return item.id === nodeId;
          });
          this.nodeData = list[0];
          if (label === "delete_click") {
            this.activeElement.type = "node";
            this.activeElement.nodeId = nodeId;
            this.activeElement.name = this.nodeData.name;
            this.deleteElement();
          } else if (label === "resetState_click") {
            if (this.tabList[this.tabIndex].status !== "DRAFT") {
              this.resetStateNodeId = nodeId;
              this.reEditClick();
            } else {
              this.resetState(nodeId);
            }
          } else if (label == "selectSubsequentNodes") {
            // 选中后续节点
            let data = lodash.cloneDeep(this.data);
            data.nodeList.splice(0);
            data.lineList.splice(0);
            // 添加初始节点前的一个连线，防止整体拖动导致线丢失
            let lineArr = this.data.lineList.filter((line) => {
              return line.to == nodeId;
            });
            data.lineList.push(...lineArr);
            // 对数据进行处理，将后续的节点和线都存在data里
            this.getSelectSubsequentNodes(nodeId, data);
            // 因为整体拖动时线不会跟随，所以需要把线存起来拖动结束后重新渲染；
            // 手动拖拽选择没有问题，但选中后续节点的操作是根据nodeId和line.from来找下一个节点
            // 所以存在一种情况，当一个节点有别的输入时，是无法获取这条线的
            // 在拖动后，因为在lineList中没有这条线的数据，无法重新渲染，这条线会消失
            // 因此需要根据nodeList当中每一个节点的id和line.to再获取其他线的数据，然后进行去重
            this.getSelectPreviousNodes(data);
            // 对data所含的节点进行一键全选
            this.checkAllClick(data);
          } else if (label == "updateRemark") {
            this.nodeRemark = this.nodeData.remark;
            this.updateRemarkShow = true;
            this.$nextTick(() => {
              this.$refs.nodeRemarkInput.focus();
            });
          } else if (label == "notifyChange") {
            let data = {
              pipelineTaskId: this.tabId,
              nodeId,
            };
            if (this.rightMenu.notifyState) {
              this.$http
                .json_post("/pipelineTaskNode/stopNotify", data)
                .then((res) => {
                  if (res.result === 200) {
                    this.checkNotifyState(data);
                    this.$message.success("取消成功");
                  }
                });
            } else {
              this.$http
                .json_post("/pipelineTaskNode/notifyMeIfComplete", data)
                .then((res) => {
                  if (res.result === 200) {
                    this.checkNotifyState(data);
                    this.$message.success("操作成功");
                  }
                });
            }
          } else {
            this.setDataType = label;
            this.$refs.setData.info(test);
          }
        }
      }
    },
    nodeEnabledChange(nodeId, after) {
      this.data.nodeList.forEach((node) => {
        if (nodeId == node.id) {
          let oldNode = lodash.cloneDeep(node);
          this.$set(node, "enabled", !node.enabled);
          this.modifyTaskNode(
            oldNode.enabled ? "DISABLE_NODE" : "ENABLE_NODE",
            oldNode
          );

          if (!node.enabled || (node.enabled && after)) {
            this.getNextNodeAndEnabledChange(node.enabled, nodeId);
          }
        }
      });
    },
    getNextNodeAndEnabledChange(enabled, from) {
      let arr = this.data.lineList.filter((line) => {
        return line.from == from;
      });
      if (arr.length > 0) {
        arr.forEach((line) => {
          this.data.nodeList.forEach((node) => {
            if (line.to == node.id && enabled !== node.enabled) {
              let oldNode = lodash.cloneDeep(node);
              this.$set(node, "enabled", enabled);
              this.modifyTaskNode(
                oldNode.enabled ? "DISABLE_NODE" : "ENABLE_NODE",
                oldNode
              );
              this.getNextNodeAndEnabledChange(enabled, node.id);
            }
          });
        });
      }
    },
    updateRemarkSubmit() {
      let data = {
        taskId: this.tabId,
        nodeId: this.nodeData.id,
        remark: this.nodeRemark,
      };
      this.$http
        .json_post("/pipelineTaskNode/modifyRemark", data)
        .then((res) => {
          if (res.result == 200) {
            this.data.nodeList.forEach((node) => {
              if (node.id == this.nodeData.id) {
                let oldNode = lodash.cloneDeep(node);
                this.$set(node, "remark", this.nodeRemark);
                this.modifyTaskNode("MODIFY", oldNode, node);
                this.$message.success("修改成功");
                this.updateRemarkShow = false;
              }
            });
          }
        });
    },
    handleVisibilityChange() {
      this.isTabVisible = document.visibilityState === "visible";
      if (this.isTabVisible && !this.refreshRunTimeTimer) {
        clearInterval(this.finishTitleTimer);
        this.finishTitleTimer = null;
        document.title = "tj_" + this.tabList[0].name;
      }
    },
    // 刷新进度--查看
    refreshProgress() {
      document.title = "tj_" + this.tabList[0].name + "(运行中...)";
      if (this.refreshProgressTimer) {
        clearInterval(this.refreshProgressTimer);
        this.refreshProgressTimer = null;
      }
      if (this.refreshRunTimeTimer) {
        clearInterval(this.refreshRunTimeTimer);
        this.refreshRunTimeTimer = null;
      }

      this.refreshRunTimeTimer = setInterval(() => {
        this.$http
          .get("/pipelineTask/queryTaskStateAndRunTime", {
            ids: this.pipelineId,
          })
          .then((res) => {
            if (res.result === 200) {
              this.executeSecond = res.data[0].executeSecond;

              if (this.status !== "RUNNING" && this.status !== "PAUSEING") {
                clearInterval(this.refreshRunTimeTimer);
                this.refreshRunTimeTimer = null;
                let parseJobNodeList = this.data.nodeList.filter((node) => {
                  return node.type == "parseJobPipelineService";
                });
                if (parseJobNodeList.length > 0) {
                  this.getPipelineLogDetail();
                }
                if (this.isTabVisible) {
                  document.title = "tj_" + this.tabList[0].name;
                } else {
                  document.title = "tj_" + this.tabList[0].name + "(已完成)";
                  this.finishTitleTimer = setInterval(() => {
                    if (
                      document.title ==
                      "tj_" + this.tabList[0].name + "(已完成)"
                    ) {
                      document.title = "tj_" + this.tabList[0].name;
                    } else {
                      document.title =
                        "tj_" + this.tabList[0].name + "(已完成)";
                    }
                  }, 1000);
                }
              }
            }
          });
      }, 1000);

      this.refreshProgressTimer = setInterval(() => {
        let nodeIdList = [];

        this.data.nodeList.forEach((node) => {
          if (
            node.state !== "SUCCESS" &&
            node.state !== "ERROR" &&
            node.state !== "WARNING"
          ) {
            nodeIdList.push(node.id);
          }
        });

        let data = {
          taskId: this.tabId,
          nodeIds: nodeIdList.join(","),
        };

        this.$http
          .get("/pipelineTask/queryTaskNodeInfoByTaskIdAndNodeId", data)
          .then((res) => {
            if (res.result === 200) {
              this.data.nodeList.forEach((node) => {
                res.data.forEach((resNode) => {
                  if (node.id == resNode.nodeId) {
                    this.$set(node, "completeCount", resNode.completeCount);
                    this.$set(node, "costSeconds", resNode.costSeconds);
                    this.$set(node, "errorMessage", resNode.errorMessage);
                    this.$set(node, "state", resNode.state);
                    this.$set(node, "totalCount", resNode.totalCount);
                    this.$set(node, "startTime", resNode.startTime);
                    this.$set(node, "endTime", resNode.endTime);
                  }
                });
              });

              let finish = this.data.nodeList.every((node) => {
                return (
                  node.state == "SUCCESS" ||
                  node.state == "ERROR" ||
                  node.state == "WARNING"
                );
              });

              if (finish) {
                clearInterval(this.refreshProgressTimer);
                this.refreshProgressTimer = null;
              }
            }
          });
      }, 2000);
    },
    tabChange(item, index, deleteBlankTaskForChain, runningNodeId) {
      if (runningNodeId) {
        this.runningNodeId = runningNodeId;
      }
      if (Object.keys(this.modifyMap).length !== 0) {
        this.disposeComposeWorkflowTaskList();
        if (!deleteBlankTaskForChain) {
          this.$set(
            this.modifyMap[this.tabId],
            "operational",
            lodash.cloneDeep(this.composeWorkflowTaskList)
          );
        }
        if (!this.viewOnly) {
          // 重新编辑重置状态刷新页面后检查是否有本地缓存
          let modifyMapJson = sessionStorage.getItem("modifyMap");
          if (modifyMapJson) {
            let modifyMap = JSON.parse(modifyMapJson);
            for (const id in modifyMap) {
              let tab = this.tabList.filter((item) => {
                return item.id == id;
              });
              if (
                !this.modifyMap.hasOwnProperty(id) &&
                modifyMap.hasOwnProperty(id) &&
                tab.length > 0
              ) {
                this.$set(this.modifyMap, id, lodash.cloneDeep(modifyMap[id]));
              }
            }
          }
          sessionStorage.setItem("modifyMap", JSON.stringify(this.modifyMap));
        }
      }
      this.tabId = item.id;
      this.data.name = item.name;
      this.tabIndex = index;
      this.moveBol = true;
      this.tabsScroll(index);

      if (!this.recordMap.hasOwnProperty(this.tabId)) {
        this.$set(this.recordMap, this.tabId, {
          redoCount: 0,
          revertCount: 0,
          recordId: -1,
        });
        this.setSequenceMapSwitch = false;
      }

      if (this.modifyMap.hasOwnProperty(this.tabId)) {
        this.composeWorkflowTaskList = lodash.cloneDeep(
          this.modifyMap[this.tabId].operational
        );
        this.chooseWorkflowTask(0, "query");
      } else {
        if (this.syncLoading) {
          this.syncLoading.close();
          this.syncLoading = null;
        }
        this.getPipelineLogDetail();
      }
    },
    tabsScroll(index) {
      let max = this.tabList.length - 1;
      let middle = 0;
      if (max % 2 == 0) {
        middle = (max / 2 + (max / 2 + 1)) / 2;
      } else {
        middle = (max + 1) / 2;
      }
      const parent = this.$refs.tabList;

      if (index >= middle) {
        if (index < max - 1) {
          parent.scrollLeft = this.$refs.tabCard[index + 2].offsetLeft;
        } else {
          parent.scrollLeft = parent.scrollWidth;
        }
      } else {
        if (index > 1) {
          parent.scrollLeft = this.$refs.tabCard[index - 2].offsetLeft;
        } else {
          parent.scrollLeft = 0;
        }
      }
    },
    getUuid() {
      // 获取uuid
      this.$http.get("/sysPipeline/generateNodeId").then((res) => {
        if (res.result === 200) {
          this.uuid = res.data.nodeId;

          // 获取tab
          this.getChainTaskList("query");
        }
      });
    },
    getChainTaskList(type, resetIndex) {
      let data = { taskId: this.pipelineId };
      this.$http
        .get("/pipelineTaskChain/getChainTaskList", data)
        .then(async (res) => {
          if (res.result === 200) {
            this.tabList = res.data;
            this.status = this.tabList[0].status;
            if (type == "add") {
              this.tabChange(
                this.tabList[this.tabList.length - 1],
                this.tabList.length - 1
              );
            } else if (type == "delete") {
              if (resetIndex) {
                this.tabChange(this.tabList[0], 0, true);
              }
            } else if (type == "query") {
              let runningArr = this.tabList.filter((item) => {
                return item.status == "RUNNING" || item.status == "PAUSEING";
              });
              if (runningArr.length > 0) {
                this.queryTimer = setTimeout(() => {
                  this.getChainTaskList("query");
                }, 1000);
              }
              if (this.firstLoad) {
                document.title = "tj_" + this.tabList[0].name;
                if (this.tabIndex == 0) {
                  this.getPipelineLogDetail();
                } else {
                  await this.getPipelineLogDetail(this.pipelineId);
                  this.tabChange(this.tabList[this.tabIndex], this.tabIndex);
                }
              }
            } else if (type == "swapPriority") {
              this.tabList.forEach((item, index) => {
                if (item.id == this.tabId) {
                  this.tabIndex = index;
                }
              });
            }
          }
        });
    },
    addBlankTaskForChain() {
      this.generateTaskName();
    },
    generateTaskName() {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.taskName = res.data.name;
          this.flag = "";
          this.addBlankTaskForChainSubmit();
        }
      });
    },
    addBlankTaskForChainSubmit() {
      let data = {
        name: this.taskName,
        flag: this.flag,
        parentTaskId: this.pipelineId,
        pipelineTaskType: "SUB_TASK",
        model: this.model,
      };
      this.$http
        .json_post("/pipelineTask/initBlankTaskForChain", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.getChainTaskList("add");
          }
        });
    },
    deleteBlankTaskForChain(item, index) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">确认删除后置任务{item.name}吗?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: item.id,
          };
          this.$http.json_post("/pipelineTask/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success({ content: "删除成功", duration: 1 });
              let resetIndex = false;
              delete this.modifyMap[item.id];
              delete this.recordMap[item.id];

              if (index == this.tabIndex) {
                resetIndex = true;
              }
              this.getChainTaskList("delete", resetIndex);
            }
          });
        },
        onCancel: () => {},
      });
    },
    swapPriority(task1, task2) {
      let data = {
        task1,
        task2,
      };
      this.$http
        .json_post("/pipelineTaskChain/swapPriority", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("操作成功");
            this.getChainTaskList("swapPriority");
          }
        });
    },
    // 查询进度--查看
    async getPipelineLogDetail(id, uuid) {
      let data = { id: this.tabId };
      if (uuid) {
        data.uuid = this.uuid;
        data.lastRecordId = this.recordMap[this.tabId].recordId;
        data.redoCount = this.recordMap[this.tabId].redoCount;
        data.revertCount = this.recordMap[this.tabId].revertCount;
      }
      if (id) {
        data.id = id;
      } else {
        this.$nextTick(() => {
          this.$refs.efContainer.addEventListener(
            "scroll",
            this.viewScroll,
            false
          );
        });
        this.syncLoading = this.$syncLoading({
          message: "页面加载中...",
        });
      }
      await this.$http
        .get("/pipelineTask/detail", data)
        .then((res) => {
          if (res.result === 200) {
            if (this.firstLoad) {
              this.firstLoad = false;
              this.model = res.data.model;
              this.pipelineTaskType = res.data.type;
              if (res.data.threadCount) {
                this.threadCount = res.data.threadCount;
              } else {
                this.threadCount = 1;
              }
              if (
                (this.pipelineTaskType == "NORMAL_TASK" ||
                  this.pipelineTaskType == "SUB_TASK" ||
                  this.pipelineTaskType == "API_TASK" ||
                  this.pipelineTaskType == "SCHEDULED_TASK" ||
                  this.pipelineTaskType == "PROCESS_COPY") &&
                res.data.status !== "DRAFT"
              ) {
                this.viewOnly = true;
              } else {
                this.viewOnly = false;
              }
              let resetStateNodeId = this.$route.query.resetStateNodeId;
              if (resetStateNodeId) {
                if (this.viewOnly) {
                  let url = this.$router.replace({
                    path: "/panel",
                    query: {
                      id: this.pipelineId,
                      tabIndex: this.tabIndex,
                    },
                  });

                  location.href = url.href;
                } else {
                  this.resetStateNodeId = resetStateNodeId;
                }
              }

              this.templated = this.pipelineTaskType == "TEMPLATE";
              this.processed = this.pipelineTaskType == "PROCESS";
              this.timed = this.pipelineTaskType == "SCHEDULED_TASK_TEMPLATE";
            }
            this.data.name = res.data.name;
            if (!id) {
              if (this.syncLoading) {
                this.syncLoading.close();
                this.syncLoading = null;
              }
              this.getNodeInfoList(res);
            }
          }
        })
        .catch(() => {
          if (this.syncLoading) {
            this.syncLoading.close();
            this.syncLoading = null;
          }
        });
    },
    dblclickNode(groupName) {
      this.composeWorkflowTaskList.forEach((item, index) => {
        if (item.name == groupName) {
          this.chooseWorkflowTask(index, "tab");
        }
      });
    },
    chooseWorkflowTask(index, type, importTemplate) {
      if (type == "tab") {
        let data = lodash.cloneDeep(this.data);
        this.composeWorkflowTaskList[this.workflowTaskItemActive].workflowTask =
          data;
      }

      this.composeWorkflowTaskList.forEach((item, itemIndex) => {
        if (itemIndex !== 0) {
          this.composeWorkflowTaskList[0].workflowTask.nodeList.forEach(
            (primaryNode) => {
              if (primaryNode.name == item.name) {
                let inputCount = 0,
                  outputCount = 0;
                item.workflowTask.nodeList.forEach((node) => {
                  if (node.type == "PROCESS_INPUT") {
                    inputCount++;
                  }
                  if (node.type == "PROCESS_OUTPUT") {
                    outputCount++;
                  }
                });
                primaryNode.maxOutputCount = outputCount;
                primaryNode.maxInputCount = inputCount;
                primaryNode.minInputCount = 0;
                primaryNode.minOutputCount = 0;
              }
            }
          );
        }
      });

      if (index || index == 0) {
        this.workflowTaskItemActive = index;
      }
      this.composeWorkflowTaskList.forEach((data) => {
        data.workflowTask.nodeList.forEach((item) => {
          if (
            item.type !== "COMPOSE_NODE" &&
            item.type !== "PROCESS_INPUT" &&
            item.type !== "PROCESS_OUTPUT"
          ) {
            if (item.extraInfo) {
              if (typeof item.extraInfo !== "object") {
                item.extraInfo = JSON.parse(item.extraInfo);
              }
            } else {
              item.extraInfo = {};
            }
          }
          let node = null;
          if (item.type == "PROCESS_INPUT") {
            node = this.processList[0];
          } else if (item.type == "PROCESS_OUTPUT") {
            node = this.processList[1];
          } else {
            node = this.getMenuByNodeId(item.nodeId);
          }
          if (node) {
            if (!item.hasOwnProperty("errorMessage")) {
              item.errorMessage = "";
            }
            item.maxOutputCount = node.maxOutputCount;
            item.maxInputCount = node.maxInputCount;
            item.minInputCount = node.minInputCount;
            item.minOutputCount = node.minOutputCount;
            item.testable = node.testable;
            item.nodeInfoRemark = node.remark;
            item.dynamicUpdateProgress = node.dynamicUpdateProgress;
            item.icon =
              node.type == "PROCESS_INPUT" || node.type == "PROCESS_OUTPUT"
                ? ""
                : node.icon;
            item.extraInfoShow = node.extraInfoShow;
          }
        });
      });
      let detail =
        this.composeWorkflowTaskList[this.workflowTaskItemActive].workflowTask;
      this.composeNodeId =
        this.composeWorkflowTaskList[this.workflowTaskItemActive].composeNodeId;
      detail.name = this.data.name;

      this.prevHistoryData =
        this.composeWorkflowTaskList[this.workflowTaskItemActive].workflowTask;
      // 记录任务初始数据
      if (!this.modifyMap.hasOwnProperty(this.tabId)) {
        this.$set(this.modifyMap, this.tabId, {
          originally: lodash.cloneDeep(this.composeWorkflowTaskList),
          operational: null,
        });
        this.$set(this.recordMap, this.tabId, {
          redoCount: 0,
          revertCount: 0,
          recordId: -1,
        });
        this.setSequenceMapSwitch = false;
      }
      // 模板导入上传操作
      if (importTemplate) {
        this.data.nodeList.forEach((node) => {
          this.modifyTaskNode("DELETE", node);
        });
        this.data.lineList.forEach((line) => {
          this.modifyTaskNodeLine("DELETE", line);
        });
        detail.nodeList.forEach((node) => {
          this.modifyTaskNode("ADD", null, node);
        });
        detail.lineList.forEach((line) => {
          this.modifyTaskNodeLine("ADD", null, line);
        });
      }

      this.dataReload(lodash.cloneDeep(detail));

      if (this.viewOnly) {
        if (this.status == "RUNNING" || this.status == "PAUSEING") {
          this.refreshProgress();
        } else {
          if (this.refreshProgressTimer) {
            clearInterval(this.refreshProgressTimer);
            this.refreshProgressTimer = null;
          }
          if (this.refreshRunTimeTimer) {
            clearInterval(this.refreshRunTimeTimer);
            this.refreshRunTimeTimer = null;
          }
        }
      }
    },
    // 点击返回
    backClick() {
      if (this.workflowTaskItemActive !== 0) {
        this.chooseWorkflowTask(0, "tab");
      } else {
        if (!this.viewOnly) {
          this.disposeComposeWorkflowTaskList();
          this.$set(
            this.modifyMap[this.tabId],
            "operational",
            lodash.cloneDeep(this.composeWorkflowTaskList)
          );
          let modifyCount = 0;
          let modifyMap = lodash.cloneDeep(this.modifyMap);
          for (const key in modifyMap) {
            const item = modifyMap[key];
            item.equal = true;
            let originally = [],
              operational = [];
            if (
              !(
                item.operational.length == 1 &&
                item.operational[0].workflowTask.nodeList.length == 0
              )
            ) {
              operational = lodash.cloneDeep(item.operational);
            }
            if (
              !(
                item.originally.length == 1 &&
                item.originally[0].workflowTask.nodeList.length == 0
              )
            ) {
              originally = lodash.cloneDeep(item.originally);
            }
            originally.forEach((data) => {
              Reflect.deleteProperty(data.workflowTask, "left");
              Reflect.deleteProperty(data.workflowTask, "top");
              data.workflowTask.nodeList.forEach((node) => {
                Reflect.deleteProperty(node, "left");
                Reflect.deleteProperty(node, "top");
              });
            });
            operational.forEach((data) => {
              Reflect.deleteProperty(data.workflowTask, "left");
              Reflect.deleteProperty(data.workflowTask, "top");
              data.workflowTask.nodeList.forEach((node) => {
                Reflect.deleteProperty(node, "left");
                Reflect.deleteProperty(node, "top");
              });
            });
            let originallyJson = JSON.stringify(originally);
            let operationalJson = JSON.stringify(operational);
            if (originallyJson !== operationalJson) {
              item.equal = false;
            }
            if (item.equal == false) {
              modifyCount++;
            }
          }

          if (modifyCount == 0) {
            this.commitModify("REVERT");
          } else {
            this.$confirm({
              title: "提示",
              content: (h) => (
                <div style="color:red;">
                  你修改的内容还没有保存，确定要退出吗?
                </div>
              ),
              okText: "确定",
              cancelText: "取消",
              onOk: () => {
                this.commitModify("REVERT");
              },
              onCancel: () => {},
            });
          }
        } else {
          this.$router.go(-1);
        }
      }
    },
    statistics() {
      let nodeTypeStatisticsMap = {};
      this.data.nodeList.forEach((node) => {
        if (!nodeTypeStatisticsMap[node.type]) {
          let name = "";
          if (node.type == "COMPOSE_NODE") {
            name = "自定义节点";
          } else if (node.type == "PROCESS_INPUT") {
            name = "输入节点";
          } else if (node.type == "PROCESS_OUTPUT") {
            name = "输出节点";
          } else {
            name = this.getMenuByNodeId(node.nodeId).name;
          }
          nodeTypeStatisticsMap[node.type] = {
            type: node.type,
            name,
            count: 0,
            nodeList: [],
          };
        }
        nodeTypeStatisticsMap[node.type].count += 1;
        nodeTypeStatisticsMap[node.type].nodeList.push(node);
      });
      this.nodeTypeStatisticsList = Object.values(nodeTypeStatisticsMap);
      this.nodeTypeStatisticsList.sort((a, b) => {
        return b.count - a.count;
      });
      let sum = this.nodeTypeStatisticsList.reduce(
        (accumulator, currentObject) => {
          return accumulator + currentObject.count;
        },
        0
      );
      this.nodeTypeStatisticsList.push({
        type: "合计",
        name: null,
        count: sum,
        nodeList: [],
      });
      this.filterNodeKey = "";
      this.filterFlashingNodeList();
      this.active = "1";
      this.statisticsShow = true;
    },
    allowClearFilterNodeListChange(e) {
      if (e.target.value) {
        return;
      }
      this.filterFlashingNodeList();
    },
    filterFlashingNodeList() {
      this.flashingNodeList.splice(0);
      if (this.filterNodeKey) {
        this.flashingNodeList = this.data.nodeList.filter((node) => {
          return (
            node.id.indexOf(this.filterNodeKey) !== -1 ||
            node.name.indexOf(this.filterNodeKey) !== -1
          );
        });
      } else {
        this.flashingNodeList.push(...this.data.nodeList);
      }
      const pad = (num) => (num < 10 ? "0" + num : num);
      this.flashingNodeList.forEach((item) => {
        item.time = "00:00:00";

        let seconds = item.costSeconds;
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;

        this.$set(
          item,
          "time",
          `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
        );
      });
    },
    getNodeTypeStatisticsChildrenList(record) {
      this.nodeTypeStatisticsChildrenList.splice(0);
      this.nodeTypeStatisticsChildrenList.push(...record.nodeList);
      const pad = (num) => (num < 10 ? "0" + num : num);

      this.nodeTypeStatisticsChildrenList.forEach((item) => {
        item.time = "00:00:00";

        let seconds = item.costSeconds;
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;

        this.$set(
          item,
          "time",
          `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
        );
      });
      this.statisticsChildrenTitle = record.name + "节点列表";
      this.statisticsChildrenShow = true;
    },
    position() {
      if (this.hintTimer) {
        clearInterval(this.hintTimer);
        this.hintTimer = null;
        this.runningNodeId = "";
      }
      let data = {
        taskId: this.pipelineId,
      };
      this.$http.get("/sysPipeline/getRunningNodeId", data).then((res) => {
        if (res.result === 200) {
          this.tabList.forEach((item, index) => {
            if (item.id == res.data[0].pipelineTaskId) {
              this.tabChange(item, index, false, res.data[0].nodeId);
            }
          });
        }
      });
    },
    flashing(node, statistics) {
      if (statistics) {
        this.statisticsChildrenShow = false;
        this.statisticsShow = false;
      }
      let top =
        node.top.split("px")[0] * 1 - this.$refs.efContainer.clientHeight / 2;

      let left =
        node.left.split("px")[0] * 1 - this.$refs.efContainer.clientWidth / 2;

      if (left < 0) {
        left = 0;
      } else {
        left += 180;
      }
      if (top < 0) {
        top = 0;
      } else {
        top += 42;
      }
      // 42和180是节点的大约高和宽

      this.$refs.efContainer.scrollTop = top;
      this.$refs.efContainer.scrollLeft = left;
      this.runningNodeId = node.id;
      let frequency = 0;
      this.hintTimer = setInterval(() => {
        frequency += 1;
        if (frequency > 5) {
          clearInterval(this.hintTimer);
          this.hintTimer = null;
          this.runningNodeId = "";
        } else if (this.runningNodeId) {
          this.runningNodeId = "";
        } else {
          this.runningNodeId = node.id;
        }
      }, 200);
    },
    stopTaskNow() {
      let data = {
        id: this.pipelineId,
      };
      this.$http.json_post("/pipelineTask/interruptTask", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("中断成功");
        }
      });
    },
    pause() {
      let data = {
        id: this.pipelineId,
      };
      this.$http.json_post("/pipelineTask/pause", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("暂停成功");
          this.$router.go(0);
        }
      });
    },
    resume() {
      let data = {
        id: this.pipelineId,
      };
      this.$http.json_post("/pipelineTask/resume", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("恢复成功");
          this.$router.go(0);
        }
      });
    },
    // 保存任务列表
    saveTask() {
      this.title = "保存";
      this.createShow = true;
    },
    quickSubmit() {
      this.title = "保存";
      this.saveTask_submit(true);
    },
    saveTimedTask() {
      this.title = "保存定时任务";
      this.createShow = true;
    },
    // 点击保存
    saveTemplateClick() {
      this.title = "保存模板";
      this.createShow = true;
    },
    saveProcessClick() {
      this.title = "保存过程";
      this.createShow = true;
    },
    saveApiTask() {
      this.title = "保存接口任务";
      this.createShow = true;
    },
    saveTask_submit(execute, draftStatus) {
      let efNodeLeftWarning = this.$refs.flowNode.some((flowNode) => {
        return (
          flowNode.nodeLeftStyle.hasOwnProperty("ef-node-left") &&
          !flowNode.nodeLeftStyle["ef-node-left"] &&
          flowNode.node.enabled
        );
      });

      if (efNodeLeftWarning) {
        this.$confirm({
          title: "提示",
          content: (h) => (
            <div>
              存在需要补充参数的节点(也可以以后设置，不影响任务正常运行)
            </div>
          ),
          okText: "以后设置",
          cancelText: "立即设置",
          onOk: () => {
            this.efNodeLeftWarningAfter(execute, draftStatus);
          },
          onCancel: () => {
            this.createShow = false;
            let arr = this.$refs.flowNode.filter((flowNode) => {
              return (
                flowNode.nodeLeftStyle.hasOwnProperty("ef-node-left") &&
                !flowNode.nodeLeftStyle["ef-node-left"]
              );
            });
            this.flashing(arr[0].node);
          },
        });
      } else {
        this.efNodeLeftWarningAfter(execute, draftStatus);
      }
    },
    efNodeLeftWarningAfter(execute, draftStatus) {
      let timeout = 0;
      if (this.recordModifyItemList.length > 0) {
        timeout = 1000;
      }
      setTimeout(() => {
        let statusIsWaiting = true;
        this.errorNodeId = null;
        let data = {
          remark: this.remark,
          threadCount: this.threadCount,
          status: "DRAFT",
        };
        if (draftStatus) {
          statusIsWaiting = false;
        }
        if (this.title == "保存") {
          data.executeDate = this.executeDate;
        } else if (this.title == "保存定时任务") {
          data.status = this.status;
        }
        this.disposeComposeWorkflowTaskList();
        this.$set(
          this.modifyMap[this.tabId],
          "operational",
          lodash.cloneDeep(this.composeWorkflowTaskList)
        );
        let modifyMap = lodash.cloneDeep(this.modifyMap);
        let list = Object.entries(modifyMap).map(([id, item]) => {
          return {
            id,
            name: this.tabList.filter((tab) => {
              return tab.id == id * 1;
            })[0].name,
            left: item.operational[0].workflowTask.left,
            top: item.operational[0].workflowTask.top,
          };
        });
        this.updateTask(list, data, execute, statusIsWaiting);
      }, timeout);
    },
    updateTask(list, data, execute, statusIsWaiting) {
      this.$axios
        .all(
          list.map((item) => {
            let params = {
              ...data,
              id: item.id,
              name: item.name,
              left: item.left,
              top: item.top,
            };
            return this.$http.json_post("/pipelineTask/updateTask", params);
          })
        )
        .then(() => {
          this.commitModify("COMMIT", execute, statusIsWaiting);
        });
    },
    commitModify(type, execute, statusIsWaiting) {
      let data = {
        taskId: this.pipelineId,
        uuid: this.uuid,
        type,
      };
      if (type == "COMMIT") {
        let lastRecordIdInfo = {};
        for (const tabId in this.recordMap) {
          const element = this.recordMap[tabId];
          lastRecordIdInfo[tabId] = element.recordId;
        }
        data.lastRecordIdInfo = lastRecordIdInfo;
      }
      this.syncLoading = null;
      let timeout = setTimeout(() => {
        this.syncLoading = this.$syncLoading({
          message: "提交中...",
        });
      }, 500);
      this.$http
        .json_post("/pipelineTaskNode/commitModify", data)
        .then((res) => {
          if (res.result == 200) {
            clearTimeout(timeout);
            if (this.syncLoading) {
              this.syncLoading.close();
            }
            if (type == "COMMIT") {
              if (statusIsWaiting) {
                this.markTaskAsWaiting(execute);
              } else {
                // 提交后执行的操作
                this.saveAfter(execute);
              }
            } else {
              this.$router.go(-1);
            }
          }
        })
        .catch(() => {
          clearTimeout(timeout);
          if (this.syncLoading) {
            this.syncLoading.close();
          }
        });
    },
    markTaskAsWaiting(execute) {
      this.$http
        .json_post("/pipelineTask/markTaskAsWaiting", {
          id: this.pipelineId,
        })
        .then((res) => {
          if (res.result == 200) {
            // 提交后执行的操作
            this.saveAfter(execute);
          }
        })
        .catch((err) => {
          let message = err.response.data.message;
          if (message.indexOf("参数异常") !== -1) {
            const regex = /\[(.*?)\]/g;
            const matches = [];
            let match;
            while ((match = regex.exec(message)) !== null) {
              matches.push(match[1]);
            }
            this.createShow = false;
            this.errorNodeShow = true;
            this.errorNodeId = matches[0];
            let tabIndex = 0;
            this.tabList.forEach((item, index) => {
              if (item.id == matches[1] * 1) {
                tabIndex = index;
              }
            });
            this.tabChange(this.tabList[tabIndex], tabIndex);
          }
        });
    },
    saveAfter(execute) {
      this.$message.success("保存成功");
      sessionStorage.removeItem("modifyMap");
      if (execute) {
        this.execute();
      } else if (this.title == "保存") {
        this.$router.push("/tabTaskList/dataRecord");
      } else if (this.title == "保存定时任务") {
        this.$router.push("/tabTaskList/timedTaskTab/timedTask");
      } else if (this.title == "保存模板") {
        this.$router.push("/pipelineListOrigin/pipelineList");
      } else if (this.title == "保存接口任务") {
        if (this.model == "BATCH") {
          this.$router.push("/pipelineListOrigin/apiTask");
        } else {
          this.$router.go(0);
        }
      } else if (this.title == "保存接口任务") {
        this.$router.push("/pipelineListOrigin/apiTask");
      } else if (this.title == "保存过程") {
        this.$router.push("/pipelineListOrigin/nodeListTab/processNodeList");
      } else if (this.title == "保存子任务") {
        this.$router.go(0);
      }
    },
    execute() {
      this.$http
        .json_post("/pipelineTask/start", { id: this.pipelineId })
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("启动成功");
            this.$router.go(0);
          }
        });
    },
    modifyTaskNode(operationType, initialNode, node) {
      let recordModifyItem = {
        targetType: "NODE",
        operationType,
      };
      let initialExtraInfoObj = {},
        extraInfoObj = {};
      if (operationType == "MOVE") {
        initialExtraInfoObj = {
          nodeId: initialNode.id,
          nodeInfo: {
            x: initialNode.oldLeft,
            y: initialNode.oldTop,
          },
        };
        extraInfoObj = {
          nodeId: initialNode.id,
          nodeInfo: {
            x: initialNode.left,
            y: initialNode.top,
          },
        };
      } else {
        if (initialNode) {
          initialExtraInfoObj = {
            nodeId: initialNode.id,
            nodeInfo: initialNode,
          };
        }
        if (node) {
          extraInfoObj = {
            nodeId: node.id,
            nodeInfo: node,
          };
        }
      }
      if (Object.keys(initialExtraInfoObj).length == 0) {
        initialExtraInfoObj = {
          nodeId: node.id,
        };
      }
      recordModifyItem.initialExtraInfo = JSON.stringify(initialExtraInfoObj);
      if (Object.keys(extraInfoObj).length == 0) {
        extraInfoObj = {
          nodeId: initialNode.id,
        };
      }
      recordModifyItem.extraInfo = JSON.stringify(extraInfoObj);
      this.recordModify(recordModifyItem);
    },
    modifyTaskNodeLine(operationType, initialLine, line) {
      let recordModifyItem = {
        targetType: "LINE",
        operationType,
      };
      if (initialLine) {
        recordModifyItem.initialExtraInfo = JSON.stringify(initialLine);
      }
      if (line) {
        recordModifyItem.extraInfo = JSON.stringify(line);
      }
      // 修改的是否为同一批操作里刚添加的线段,不是则正常执行
      let modifyBeforeAddLineBol = false;
      if (this.recordModifyItemList.length > 0 && operationType == "MODIFY") {
        this.recordModifyItemList.forEach((item) => {
          if (item.targetType == "LINE" && item.operationType == "ADD") {
            let lineInfo = JSON.parse(item.extraInfo);
            if (lineInfo.from == line.from && lineInfo.to == line.to) {
              modifyBeforeAddLineBol = true;
              this.$set(item, "extraInfo", JSON.stringify(line));
            }
          }
        });
      }
      if (!modifyBeforeAddLineBol) {
        this.recordModify(recordModifyItem);
      }
    },
    recordModify(recordModifyItem) {
      if (this.viewOnly || !this.easyFlowVisible || this.setSequenceMapSwitch) {
        return;
      }
      this.recordModifyItemList.push(recordModifyItem);
      if (this.recordModifyTimer) {
        clearTimeout(this.recordModifyTimer);
        this.recordModifyTimer = null;
      }
      this.recordModifyTimer = setTimeout(() => {
        this.recordModifySubmit();
      }, 500);
    },
    recordModifySubmit() {
      if (this.recordModifyItemList.length == 0) {
        return;
      }
      let data = {
        taskId: this.tabId,
        uuid: this.uuid,
        itemList: this.recordModifyItemList,
        lastRecordId: this.recordMap[this.tabId].recordId,
        redoCount: this.recordMap[this.tabId].redoCount,
        revertCount: this.recordMap[this.tabId].revertCount,
      };
      this.nodePriorityShow = false;
      this.$http
        .json_post("/pipelineTaskNode/recordModify", data)
        .then((res) => {
          if (res.result === 200) {
            this.recordModifyItemList.splice(0);
            this.$set(
              this.recordMap[this.tabId],
              "recordId",
              res.data.recordId
            );
            this.$set(
              this.recordMap[this.tabId],
              "redoCount",
              res.data.redoCount
            );
            this.$set(
              this.recordMap[this.tabId],
              "revertCount",
              res.data.revertCount
            );
          }
        })
        .catch((err) => {
          this.recordModifyItemList.splice(0);
          this.frameSelectionList.nodeList.splice(0);
          this.frameSelectionList.lineList.splice(0);
          this.frameSelectionList.left = 0;
          this.frameSelectionList.top = 0;
          this.$refs.frameSelection.style.width = 0;
          this.$refs.frameSelection.style.height = 0;
          this.$refs.frameSelection.style.left = 0;
          this.$refs.frameSelection.style.top = 0;
          this.getPipelineLogDetail(null, this.uuid);
        });
    },
    revocation() {
      this.setSequenceMapSwitch = true;
      let data = {
        taskId: this.tabId,
        uuid: this.uuid,
        lastRecordId: this.recordMap[this.tabId].recordId,
        redoCount: this.recordMap[this.tabId].redoCount,
        revertCount: this.recordMap[this.tabId].revertCount,
      };
      this.$http
        .json_post("/pipelineTaskNode/revert", data)
        .then((res) => {
          if (res.result === 200) {
            this.setRecordMap(res.data, "revert");
          }
        })
        .catch((err) => {
          this.$message.error("撤销失败");
        });
    },
    cancelRevocation() {
      this.setSequenceMapSwitch = true;
      let data = {
        taskId: this.tabId,
        uuid: this.uuid,
        lastRecordId: this.recordMap[this.tabId].recordId,
        redoCount: this.recordMap[this.tabId].redoCount,
        revertCount: this.recordMap[this.tabId].revertCount,
      };
      this.$http
        .json_post("/pipelineTaskNode/redo", data)
        .then((res) => {
          if (res.result === 200) {
            this.setRecordMap(res.data, "redo");
          }
        })
        .catch((err) => {
          this.$message.error("重做失败");
        });
    },
    setRecordMap(info, type) {
      this.nodePriorityShow = false;
      this.$set(this.recordMap[this.tabId], "recordId", info.recordId);
      this.$set(this.recordMap[this.tabId], "redoCount", info.redoCount);
      this.$set(this.recordMap[this.tabId], "revertCount", info.revertCount);
      let data = lodash.cloneDeep(this.data);
      info.actionList.forEach((item) => {
        if (item.pipelineModifyType == "NODE") {
          let nodeInfo, nodeId;
          if (
            (type == "revert" && item.action.operationType !== "DELETE") ||
            (type == "redo" && item.action.operationType == "DELETE")
          ) {
            nodeInfo = JSON.parse(item.action.initialExtraInfo).nodeInfo;
            nodeId = JSON.parse(item.action.initialExtraInfo).nodeId;
          }

          if (
            (type == "revert" && item.action.operationType == "DELETE") ||
            (type == "redo" && item.action.operationType !== "DELETE")
          ) {
            nodeInfo = JSON.parse(item.action.extraInfo).nodeInfo;
            nodeId = JSON.parse(item.action.extraInfo).nodeId;
          }

          switch (item.action.operationType) {
            case "ADD":
              data.nodeList.push(nodeInfo);
              break;
            case "DELETE":
              data.nodeList = data.nodeList.filter((node) => {
                return nodeId !== node.id;
              });
              break;
            case "MODIFY":
              data.nodeList.forEach((node, index) => {
                if (node.id == nodeId) {
                  data.nodeList[index] = lodash.cloneDeep(nodeInfo);
                }
              });
              break;
            case "RESET":
              data.nodeList.forEach((node) => {
                if (node.id == nodeId) {
                  this.$set(node, "state", "WAITING");
                  this.$set(node, "errorMessage", "");
                  this.$set(node, "totalCount", 0);
                  this.$set(node, "completeCount", 0);
                }
              });
              break;
            case "MOVE":
              data.nodeList.forEach((node) => {
                if (node.id == nodeId) {
                  this.$set(node, "left", nodeInfo.x);
                  this.$set(node, "top", nodeInfo.y);
                }
              });
              break;
            case "DISABLE_NODE":
              data.nodeList.forEach((node) => {
                if (node.id == nodeId) {
                  this.$set(node, "enabled", false);
                }
              });
              break;
            case "ENABLE_NODE":
              data.nodeList.forEach((node) => {
                if (node.id == nodeId) {
                  this.$set(node, "enabled", true);
                }
              });
              break;
          }
        }

        if (item.pipelineModifyType == "LINE") {
          let line = {};

          if (
            (type == "revert" && item.action.operationType == "DELETE") ||
            (type == "redo" && item.action.operationType !== "DELETE")
          ) {
            line = JSON.parse(item.action.extraInfo);
          }

          if (
            (type == "revert" && item.action.operationType !== "DELETE") ||
            (type == "redo" && item.action.operationType == "DELETE")
          ) {
            line = JSON.parse(item.action.initialExtraInfo);
          }

          switch (item.action.operationType) {
            case "ADD":
              data.lineList.push({
                from: line.from,
                to: line.to,
                label: line.label ? line.label : "",
              });
              break;
            case "DELETE":
              data.lineList = data.lineList.filter((item) => {
                return !(line.from == item.from && line.to == item.to);
              });
              break;
            case "MODIFY":
              data.lineList.forEach((item) => {
                if (line.from == item.from && line.to == item.to) {
                  this.$set(item, "label", line.initialLabel);
                }
              });
              break;
          }
        }
      });
      this.dataReload(lodash.cloneDeep(data));
      this.setSequenceMapSwitch = false;
    },
    jsPlumbInit() {
      if (this.moveBol) {
        if (this.data.left == -1 || this.data.top == -1) {
          // 新任务移动到画布中间
          this.$refs.efContainer.scrollTop =
            (this.$refs.efContainer.scrollHeight -
              this.$refs.efContainer.clientHeight) /
            2;
          this.$refs.efContainer.scrollLeft =
            (this.$refs.efContainer.scrollWidth -
              this.$refs.efContainer.clientWidth) /
            2;
        } else {
          // 初始化时移动到保存过的画布固定位置
          this.$refs.efContainer.scrollTop = parseInt(this.data.top);
          this.$refs.efContainer.scrollLeft = parseInt(this.data.left);
        }
        this.moveBol = false;
      }
      this.startScale();
      this.jsPlumb.ready(() => {
        // 导入默认配置
        this.jsPlumb.importDefaults(this.jsplumbSetting);
        // 会使整个jsPlumb立即重绘。
        this.jsPlumb.setSuspendDrawing(false, true);
        // 初始化节点
        this.loadEasyFlow();

        // 连线成功
        this.jsPlumb.bind("connection", async (evt) => {
          let from = evt.source.id;
          let to = evt.target.id;
          if (this.loadEasyFlowFinish) {
            this.data.lineList.push({ from, to, label: "" });
          }
          this.nodeFormShow = false;

          // this.frameSelectionList.nodeList.length == 0条件是因为全选拖动时会重新渲染线条，会重置后续节点的状态，所以需要确定是否为全选状态
          if (
            this.endpointNodeMenuShow ||
            this.frameSelectionList.nodeList.length == 0
          ) {
            this.modifyTaskNodeLine("ADD", null, {
              from,
              to,
              label: "",
            });
            this.data.nodeList.forEach((node) => {
              // 当连线成功后重置后续节点的状态
              if (node.id == to) {
                if (node.state !== "WAITING") {
                  let oldNode = lodash.cloneDeep(node);
                  this.modifyTaskNode("RESET", oldNode);
                  this.$set(node, "state", "WAITING");
                  this.$set(node, "errorMessage", "");
                  this.$set(node, "totalCount", 0);
                  this.$set(node, "completeCount", 0);
                }
                this.getNextNodeAndResetState(node.id);
              }
            });
          }

          // 随机取数节点连线后若已经有另一条线且该线段选择了label，那么会为新的线段赋值另一个label
          let shufSelectorServiceLineList = [];
          this.data.nodeList.forEach((node) => {
            if (node.id == from && node.type == "shufSelectorService") {
              shufSelectorServiceLineList = this.data.lineList.filter(
                (line) => {
                  return line.from == node.id;
                }
              );
            }
          });

          if (
            shufSelectorServiceLineList.length == 2 &&
            this.copyList.lineList.length == 0
          ) {
            let otherLineLabel = "";
            shufSelectorServiceLineList.forEach((line) => {
              if (line.to !== to && line.label) {
                otherLineLabel = line.label;
              }
            });
            if (otherLineLabel) {
              let conn = this.jsPlumb.getConnections({
                source: from,
                target: to,
              })[0];
              await this.getLineLabel(conn, null, true);
              let label = this.fromOptionsList.filter((options) => {
                return options.label !== otherLineLabel;
              })[0].label;
              this.setLineLabel(from, to, label);
              this.dataReload(lodash.cloneDeep(this.data));
            }
          }
        });

        // 删除连线回调
        this.jsPlumb.bind("connectionDetached", (evt) => {
          this.deleteLine(evt.sourceId, evt.targetId);
        });

        // 连线
        this.jsPlumb.bind("beforeDrop", (evt) => {
          let from = evt.sourceId;
          let to = evt.targetId;
          let lineType = this.hasLineCount(from, to);
          if (from === to) {
            this.$message.error("节点不支持连接自己");
            return false;
          }
          if (this.hasLine(from, to)) {
            this.$message.error("该关系已存在,不允许重复创建");
            return false;
          }
          if (this.hashOppositeLine(from, to)) {
            this.$message.error("不支持两个节点之间连线回环");
            return false;
          }
          if (lineType.status === "start") {
            this.$message.error("【" + lineType.name + "】节点为开始节点");
            return false;
          }
          if (lineType.status === "end") {
            this.$message.error("【" + lineType.name + "】节点为结束节点");
            return false;
          }
          return true;
        });

        if (this.initOnce) {
          this.initOnce = false;
          let parseJobNodeId = this.$route.query.parseJobNodeId;
          let nodeId = this.$route.query.nodeId;
          if (nodeId) {
            let node = this.data.nodeList.filter((node) => {
              return node.id == nodeId;
            })[0];
            this.flashing(node);
          } else if (parseJobNodeId) {
            let node = this.data.nodeList.filter((node) => {
              return node.id == parseJobNodeId;
            })[0];
            this.flashing(node);
          }

          // 连线右击
          this.jsPlumb.bind("contextmenu", (conn, evt, a) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.activeElement.type = "line";
            this.activeElement.name = undefined;
            this.activeElement.sourceId = conn.sourceId;
            this.activeElement.targetId = conn.targetId;
            this.getLineLabel(conn, evt);
          });
        }

        this.jsPlumb.setContainer(this.$refs.eeContainer);
      });
    },
    changeEndpointStyle(source, type) {
      const connections = this.jsPlumb.getConnections({ source });
      connections.forEach((connection) => {
        const connectionElement = connection.getConnector().canvas;

        if (type == "enter") {
          connectionElement.classList.add("animated-connection");
        } else {
          connectionElement.classList.remove("animated-connection");
        }
        this.changeEndpointStyle(connection.target, type);
      });
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        this.jsplumbSourceOptions.maxConnections = 999;
        this.jsplumbTargetOptions.maxConnections = node.maxInputCount;

        // 设置源点，可以拖出线连接其他节点
        this.jsPlumb.makeSource(
          node.id,
          lodash.merge(this.jsplumbSourceOptions, {})
        );
        // // 设置目标点，其他源点拖出的线可以连接该节点
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions);
        this.jsPlumb.draggable(node.id, {
          containment: "parent",
          grid: [15, 15],
        });
      }
      // 初始化连线
      for (var i = 0; i < this.data.lineList.length; i++) {
        let line = this.data.lineList[i];
        var connParam = {
          source: line.from,
          target: line.to,
          label: line.label ? line.label : "",
          anchor: "Continuous",
        };
        this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
      }
      this.$nextTick(() => {
        this.loadEasyFlowFinish = true;

        if (this.errorNodeShow) {
          this.errorNodeShow = false;
          if (this.hintTimer) {
            clearInterval(this.hintTimer);
            this.hintTimer = null;
          }
          this.runningNodeId = "";
          let arr = this.data.nodeList.filter((item) => {
            return item.id == this.errorNodeId;
          });
          let node = arr[0];

          this.flashing(node);
        } else if (this.runningNodeId) {
          let arr = this.data.nodeList.filter((item) => {
            return item.id == this.runningNodeId;
          });
          let node = arr[0];

          this.flashing(node);
        }

        if (this.frameSelectionTaskCreate) {
          if (localStorage.getItem("copyList")) {
            this.copyList = JSON.parse(localStorage.getItem("copyList"));
            if (this.copyList.nodeList.length == 0) {
              this.frameSelectionTaskCreate = false;
            } else {
              this.FrameSelectionClick("粘贴", {
                clientX: 573,
                offsetX: 29,
                clientY: 239,
                offsetY: 11,
              });
            }
          } else {
            this.frameSelectionTaskCreate = false;
          }
        }
      });
    },
    /**
     * 拖拽结束后添加新的节点
     * @param evt
     * @param nodeMenu 被添加的节点对象
     * @param mousePosition 鼠标拖拽结束的坐标
     */
    async addNode(evt, nodeMenu, ctrlKeyShow, fileUploadInfo) {
      var screenX = 0,
        screenY = 0;
      var left = 0,
        top = 0;
      if (evt) {
        if (evt.originalEvent) {
          screenX = evt.originalEvent.clientX;
          screenY = evt.originalEvent.clientY;
          let efContainer = this.$refs.efContainer;
          var containerRect = efContainer.getBoundingClientRect(); // 获取efContainer的大小及其相对于视口的位置
          left = screenX;
          top = screenY;
          // 计算是否拖入到容器中
          if (
            left < containerRect.x ||
            left > containerRect.width + containerRect.x ||
            top < containerRect.y ||
            containerRect.y > containerRect.y + containerRect.height
          ) {
            this.$message.error("请把节点拖入到画布中");
            return;
          }
          left = left - containerRect.x + efContainer.scrollLeft;
          top = top - containerRect.y + efContainer.scrollTop;
          left = Math.round(left);
          top = Math.round(top);
        } else {
          screenX = evt.clientX;
          screenY = evt.clientY;
          left = screenX;
          top = screenY;
        }
      } else {
        screenX = efContainer.scrollLeft + this.enterLeft;
        screenY = efContainer.scrollTop + this.enterTop;
        this.enterLeft += 20;
        this.enterTop += 20;
        left = screenX;
        top = screenY;
      }
      // 居中
      var nodeId = await this.generateNodeId();

      // 动态生成名字
      var origName = nodeMenu.name;
      var nodeName = origName;
      if (nodeMenu.type == "PROCESS_INPUT") {
        nodeName = this.processList[0].name;
      } else if (nodeMenu.type == "PROCESS_OUTPUT") {
        nodeName = this.processList[1].name;
      } else {
        let index = 1;
        while (index < 10000) {
          var repeat = false;
          for (var i = 0; i < this.data.nodeList.length; i++) {
            let node = this.data.nodeList[i];
            if (node.name === nodeName) {
              nodeName = origName + index;
              repeat = true;
            }
          }
          if (repeat) {
            index++;
            continue;
          }
          break;
        }
      }
      let node = {
        id: nodeId,
        name: nodeName,
        type: nodeMenu.type,
        left: left + "px",
        top: top + "px",
        icon:
          nodeMenu.type == "PROCESS_INPUT" || nodeMenu.type == "PROCESS_OUTPUT"
            ? ""
            : nodeMenu.icon,
        state: "WAITING",
        path: nodeMenu.path,
        testable: nodeMenu.testable,
        remark: "",
        enabled: true,
        nodeInfoRemark: nodeMenu.remark,
        extraInfoShow: nodeMenu.extraInfoShow,
        dynamicUpdateProgress: nodeMenu.dynamicUpdateProgress,
        maxInputCount: nodeMenu.maxInputCount,
        maxOutputCount: nodeMenu.maxOutputCount,
        minInputCount: nodeMenu.minInputCount,
        minOutputCount: nodeMenu.minOutputCount,
        nodeId: nodeMenu.id,
        property: nodeMenu.property,
        extraInfo: {},
        errorMessage: "",
      };
      if (node.property.resultFileType) {
        node.property.resultFileName =
          nodeId +
          "." +
          (node.property.resultFileType == "excel"
            ? "xlsx"
            : node.property.resultFileType);
      } else {
        node.property.resultFileName = nodeId + ".txt";
      }
      if (
        node.type == "columnGroupService" ||
        node.type == "zipService" ||
        node.type == "fileCompressionService"
      ) {
        node.property.resultFileName =
          node.property.resultFileName.split(".")[0] + ".zip";
        node.property.resultFileType = "zip";
      }

      if (node.type == "textToExcelService") {
        node.property.resultFileName =
          node.property.resultFileName.split(".")[0] + ".xlsx";
        node.property.resultFileType = "xlsx";
      }

      if (node.type === "fileLoadService") {
        if (fileUploadInfo) {
          node.extraInfo.originalFileName = fileUploadInfo.originalFileName;
          node.extraInfo.path = fileUploadInfo.path;
          node.extraInfo.uploadType = "UPLOAD";
          node.property.resultFileName =
            node.property.resultFileName.split(".")[0] +
            "." +
            this.$common.getFileExtension(fileUploadInfo.originalFileName);
          node.property.resultFileType = this.$common.getFileExtension(
            fileUploadInfo.originalFileName
          );
        } else {
          node.extraInfo = {
            uploadType: "UPLOAD",
            path: undefined,
            originalFileName: undefined,
            name: "",
            id: undefined,
            tag: undefined,
            tmpStoreCode: "",
            fileCollectLimitCountShow: false,
            fileCollectLimitCount: "",
            ftpId: undefined,
            ftpName: undefined,
            ftpPathList: [],
            ftpSelectShow: true,
            ftpPath: "",
            appendFileName: false,
            fileName: "",
            fileNameSelectShow: true,
            initialPath: "",
            projectName: "",
          };
        }
      } else if (node.type === "databaseLoadService") {
        node.extraInfo = {
          datasourceId: undefined,
          databaseName: "",
          sql: undefined,
          skipTitle: undefined,
          param: "",
          separator: ",",
          active: "1",
          tablesType: "0-9",
          tables: "0,1,2,3,4,5,6,7,8,9",
        };
      } else if (node.type === "randomPhoneService") {
        node.extraInfo = {
          count: 100,
          depressOperators: ["中国移动", "中国联通", "中国电信"],
          returnType: "phone",
        };
      } else if (node.type === "prioritySelectorService") {
        node.extraInfo = {
          priorityFileList: undefined,
          poolFileId: undefined,
          count: 100,
          appendPool: undefined,
          poolFileName: undefined,
        };
      } else if (node.type === "multipleFileFilterService") {
        node.extraInfo = {
          fileList: [],
        };
      } else if (node.type === "quickFilterService") {
        node.extraInfo = {
          includeOperator: ["中国移动", "中国联通", "中国电信"],
          excludeArea: "",
          includeArea: "",
          countLimitEnabled: false,
          limitCount: "",
          segments: "",
          columnIndex: "1",
        };
      } else if (node.type === "sortService") {
        node.extraInfo = {
          unique: true,
          reverse: false,
          columnType: "all",
          index: "",
          separator: ",",
          isNumber: false,
          useSpark: false,
        };
      } else if (node.type === "replaceService") {
        node.extraInfo = {
          replaceList: [
            {
              orgContentType: "	",
              newContentType: ",",
              orgContent: "	",
              newContent: ",",
            },
          ],
        };
      } else if (node.type === "cutService") {
        node.extraInfo = {
          cutType: "固定长度",
          param: undefined,
          separator: ",",
          targetIndex: undefined,
          startIndex: "",
          endIndex: "",
        };
      } else if (node.type === "contentFilterService") {
        node.extraInfo = {
          regex: undefined,
        };
      } else if (node.type === "fileSplitService") {
        node.extraInfo = {
          splitType: undefined,
          splitNum: undefined,
        };
      } else if (node.type === "lengthFilterService") {
        node.extraInfo = {
          scope: undefined,
        };
      } else if (node.type === "contentFormatService") {
        node.extraInfo = {
          type: "trim",
          separator: ",",
          format: "",
          handleSpecialCharacter: true,
        };
      } else if (node.type === "contentAppendService") {
        node.extraInfo = {
          unique: false,
          fileId: undefined,
          fileName: undefined,
          rewrite: false,
          showFileNameSelect: true,
        };
      } else if (node.type === "shellService") {
        node.extraInfo = {
          cmd: undefined,
        };
      } else if (node.type === "shufSelectorService") {
        node.extraInfo = {
          count: 100,
          type: "constant",
          ratio: 100,
          mode: "random",
        };
      } else if (node.type === "blacklistFilterService") {
        node.extraInfo = {
          source: undefined,
          column: 1,
        };
      } else if (node.type === "phone2GuishudiService") {
        node.extraInfo = {
          needProvince: true,
          needCity: true,
          needOperator: true,
        };
      } else if (node.type === "joinService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          matchIndexOne: "1",
          matchIndexTwo: "1",
          outputFormat: "-o1.1 -o2.2",
          outputFormaSelectShow: true,
          useSpark: false,
        };
      } else if (node.type === "fileSelectService") {
        node.extraInfo = {
          fileId: undefined,
          fileName: undefined,
          fileIdList: [],
          taskId: undefined,
          active: "1",
        };
      } else if (node.type === "parseJobFileSelectService") {
        node.extraInfo = {
          fileId: undefined,
          taskId: undefined,
          fileName: undefined,
        };
      } else if (node.type === "columnConversionService") {
        node.extraInfo = {
          separatorType: ",",
          separator: ",",
          index: "1",
          type: "CUSTOMER_CODE",
          typeDesc: "CUSTOMER_CODE",
          fields: "",
          replaceOld: "",
          replaceNew: "",
          splitSeparator: ",",
          splitIndex: "1",
          splitExpression: "1,1",
          length: "10",
          chars: "0123456789abcdefghijklmnopqrstuvwxyz",
          offset: "-1d",
          replaceItemList: [
            {
              replaceOld: "",
              replaceNew: "",
            },
          ],
        };
      } else if (node.type === "columnAppendService") {
        node.extraInfo = {
          appendAtLineHead: false,
          separatorType: ",",
          separator: ",",
          type: "CONTENT",
          initValue: "1",
          step: "1",
          arithmeticExceptionFilling: "0",
          content: "",
          customerCodeIndex: "",
          targetIndex: "",
          randomLength: "",
        };
      } else if (node.type === "parseJobPipelineService") {
        node.extraInfo = {
          requestId: "${yyyy-MM-dd}-${random:8}",
          accessKey: "${accessKey}",
          taskType: undefined,
          relationPipelineUrl: location.href + "&parseJobNodeId=" + nodeId,
          availableCount: "",
          autoAdjustDumpCount: false,
        };
      } else if (node.type === "zipMergeService") {
        node.extraInfo = {
          type: "txt2txt",
          appendFileName: false,
          removeHeaderLineCount: "0",
          unzipPassword: "",
          separator: "	",
          separatorType: "	",
          zipMerge: false,
        };
      } else if (node.type === "diffService") {
        node.extraInfo = {
          enableColumnDiff: false,
          targetDelimiter: ",",
          targetFieldIndex: 1,
          poolDelimiter: ",",
          poolFieldIndex: 1,
          checkFileType: true,
        };
      } else if (node.type === "columnGroupService") {
        node.extraInfo = {
          delimiter: ",",
          groupIndex: 1,
          removeColumn: true,
        };
      } else if (node.type === "manualInputService") {
        node.extraInfo = {
          content: "",
        };
      } else if (node.type === "httpSenderService") {
        node.extraInfo = {
          url: "${callbackUrl}",
          status: 200,
          message: "success",
          requestId: "${requestId}",
        };
      } else if (node.type === "httpReceiverService") {
        node.extraInfo = {
          requestId: "${requestId}",
        };
      } else if (node.type === "delayService") {
        node.extraInfo = {
          delayType: "FIXED_SECONDS",
          delaySecond: "10",
          executeDateTime: undefined,
          skipEmptyFile: false,
        };
      } else if (node.type === "httpApiService") {
        node.extraInfo = {
          method: "GET",
          url: "",
          requestHeader: {},
          requestParameter: {},
          requestBody: {},
        };
      } else if (node.type === "multipleColumn2OneService") {
        node.extraInfo = {
          delimiters: [],
          filterBlankLine: true,
        };
      } else if (node.type === "excel2TxtService") {
        node.extraInfo = {
          removeHeaderLineCount: "0",
          separator: ",",
          separatorType: ",",
        };
      } else if (node.type === "ftpService") {
        node.extraInfo = {
          path: "/",
          fileName: "",
          ftpId: undefined,
          ftpName: "",
          ignoreEmptyFile: true,
        };
      } else if (node.type === "sqlBatchQueryService") {
        node.extraInfo = {
          split: ",",
          datasourceId: undefined,
          sql: "",
        };
      } else if (node.type === "textToExcelService") {
        node.extraInfo = {
          type: "XLSX",
          separator: ",",
          separatorType: ",",
          enableTableHeadName: false,
          tableHeadNames: "",
        };
      } else if (node.type === "columnRearrangeService") {
        node.extraInfo = {
          order: "1",
          separator: ",",
          separatorType: ",",
        };
      } else if (node.type === "columnDateFilterService") {
        node.extraInfo = {
          type: "yyyy-MM-dd",
          start: moment().startOf("day").format("YYYY-MM-DD"),
          end: moment().startOf("day").format("YYYY-MM-DD"),
          include: true,
          index: "1",
          separator: ",",
          separatorType: ",",
        };
      } else if (node.type === "uploadBlacklistService") {
        node.extraInfo = {
          source: [],
          remark: "",
          expireSeconds: -1,
          expireTime: "",
          expireUnit: "秒",
          expireTimeShow: false,
        };
      } else if (node.type === "fileUploadCollectService") {
        node.extraInfo = {
          collectId: undefined,
          name: "",
          expireSeconds: 0,
          expireDays: 0,
        };
      } else if (node.type === "fileCollectDistinctService") {
        node.extraInfo = {
          collectId: undefined,
          days: "",
        };
      } else if (node.type === "shortUrlCreateService") {
        node.extraInfo = {
          type: "SIMPLE",
          separator: ",",
          domain: undefined,
        };
      } else if (node.type === "dataBaseUpdateService") {
        node.extraInfo = {
          updateMode: "GENERATE_BATCH",
          sql: "",
          separator: ",",
          datasourceId: undefined,
        };
      } else if (node.type === "dataBaseInsertService") {
        node.extraInfo = {
          datasourceId: undefined,
          datasourceName: "",
          separator: ",",
          tableName: undefined,
          fieldItemList: [],
          tableNameInputShow: false,
        };
      } else if (node.type === "sipCallTaskCreateService") {
        node.extraInfo = {
          taskName: "",
          projectToken: "",
        };
      } else if (node.type === "dataStatisticsService") {
        node.extraInfo = {
          baseColumn: "",
          separator: ",",
          conditionList: [],
        };
      } else if (node.type === "apiLogToDzDkDataService") {
        node.extraInfo = {
          requestId: "${requestId}",
        };
      } else if (node.type === "columnDistinctService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          sortIndex: "1",
          distinctIndex: "1",
          asc: false,
          columnIsNumber: false,
          customerSortShow: false,
          customerSortString: "",
        };
      } else if (node.type === "calculateService") {
        node.extraInfo = {
          keyDataList: [],
          conditionList: [
            { expression: "", tag: "", value: "" },
            { expression: "", tag: "", value: "" },
          ],
        };
      } else if (node.type === "alarmService") {
        node.extraInfo = {
          alarmType: "normal",
          content: "",
          userLoginName: this.loginName,
          userName: this.userName,
          userList: [],
          url: "",
        };
      } else if (node.type === "columnSupplementService") {
        node.extraInfo = {
          separator: ",",
          supplementList: [],
        };
      } else if (node.type === "ossUploadService") {
        node.extraInfo = {
          bucketName: undefined,
          filePath: "",
          storageType: undefined,
        };
      } else if (node.type === "logicViewSynService") {
        node.extraInfo = {
          viewCode: "",
        };
      } else if (node.type === "addShortUrlGroupService") {
        node.extraInfo = {
          activityCode: "${requestId}",
          longUrl: "https://www.baidu.com/",
          longUrlGroupId: undefined,
          domain: undefined,
          category: undefined,
          blankNumberCheck: false,
          appendShortUrl: false,
          active: "1",
          needLongUrlCheck: true,
          expireRule: "UN_EXPIRE",
          expireSeconds: "",
          expireTime: "",
          expireUnit: "秒",
        };
      } else if (node.type === "dataFilterBaseAction") {
        node.extraInfo = {
          action: undefined,
          actionSelectList: [],
        };
      } else if (node.type === "inputFileDataCheckService") {
        node.extraInfo = {
          columnType: "ALL_COLUMNS",
          columnIndex: "",
          passRate: "99",
          conditionList: [{ condition: "字符长度", value: "" }],
        };
      } else if (node.type === "uploadXinBaService") {
        node.extraInfo = {
          taskTemplateId: undefined,
          taskTemplateTitle: "",
          uploadShortUrl: true,
          type: undefined,
          taskName: "${requestId}",
        };
      } else if (node.type === "jsonFormatService") {
        node.extraInfo = {
          fields: "",
          separator: ",",
        };
      } else if (node.type === "kafkaReceiverService") {
        node.extraInfo = {
          topic: "${topic}",
        };
      } else if (node.type === "uploadQuDianService") {
        node.extraInfo = {
          taskTemplateId: undefined,
          taskTemplateTitle: "",
          uploadShortUrl: true,
          taskName: "${requestId}",
        };
      } else if (node.type === "zipService") {
        node.extraInfo = {
          password: "",
          ignoreBlankFile: true,
        };
      } else if (node.type === "limiterService") {
        node.extraInfo = {
          key: "",
          count: 1000,
        };
      } else if (node.type === "sync2ClickhouseService") {
        node.extraInfo = {
          datasourceId: undefined,
          tableName: "",
          separator: ",",
          columns: "",
        };
      } else if (node.type === "concurrentLimitService") {
        node.extraInfo = {
          key: "",
          count: 1,
        };
      } else if (node.type === "fileListUploadService") {
        node.extraInfo = {
          name: "",
          expireSeconds: 0,
          expireDays: 0,
        };
      } else if (node.type === "fileCompressionService") {
        node.extraInfo = {
          passwordSwitch: false,
          password: "",
          targetType: undefined,
          fileOrDirList: [],
          jobId: this.pipelineId,
        };
      } else if (node.type === "rowAppendService") {
        node.extraInfo = {
          content: "",
          rowNum: 1,
        };
      } else if (node.type === "mergeService") {
        node.extraInfo = {
          distinct: false,
          useSpark: false,
        };
      } else if (node.type === "contentSupplementService") {
        node.extraInfo = {
          total: 0,
        };
      } else if (node.type === "maxwellFilterService") {
        node.extraInfo = {
          database: "",
          datasourceId: "",
          table: "",
          types: "insert,update,delete",
          filterColumn: "",
          filterOperation: "equals",
          filterColumnValue: "",
          filterColumnSwitch: false,
        };
      } else if (node.type === "databaseSelectService") {
        node.extraInfo = {
          mode: "GENERATE_BATCH",
          sql: "",
          separator: ",",
          datasourceId: undefined,
        };
      } else if (node.type === "intersectionService") {
        node.extraInfo = {
          checkFileType: true,
          useSpark: false,
        };
      } else if (node.type === "databaseSelectColumnSupplementService") {
        node.extraInfo = {
          datasourceId: undefined,
          sql: "",
          fillingStrWhenNotFound: "",
        };
      } else if (node.type === "smsCustomerCodeFetchService") {
        node.extraInfo = {
          fetchSmsSendData: true,
          sendStatus: "",
          batchNameType: true,
          fields: "",
          selfUnique: true,
        };
      } else if (node.type === "database2MaxwellService") {
        node.extraInfo = {
          datasourceId: undefined,
          datasourceName: "",
          tableName: undefined,
          condition: "",
          type: "select",
        };
      } else if (node.type === "columnRandomOffsetService") {
        node.extraInfo = {
          separatorType: ",",
          separator: ",",
          index: "1",
          columnType: "number",
          minOffset: "",
          maxOffset: "",
        };
      } else if (node.type === "maxwell2DatabaseService") {
        node.extraInfo = {
          datasourceId: undefined,
          sourceDatabaseName: "",
          sourceTableName: undefined,
          sourceTableType: "select",
          sinkDataSourceId: undefined,
          sinkDatabaseName: "",
          sinkTableName: undefined,
          fieldMappingList: [],
        };
      } else if (node.type === "columnFilterService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          expression: "",
        };
      } else if (node.type === "repeatFilterService") {
        node.extraInfo = {
          splitCount: "10",
        };
      } else if (node.type === "uploadFileSystemService") {
        node.extraInfo = {
          fileName: "",
          zip: false,
          expireSeconds: 2592000,
          expireDays: 3,
          fileSpaceId: -1,
          password: "",
          remark: "",
        };
      } else if (node.type === "tagFilterService") {
        node.extraInfo = {
          c: "",
          include: true,
        };
      } else if (node.type === "ai00166Service") {
        node.extraInfo = {
          batchName:
            "pipeline" +
            "-" +
            this.data.name +
            "-" +
            this.generateRandomLetters(4) +
            ".txt",
          carrier: "中国移动,中国联通,中国电信",
          region: [],
          age: "",
          sex: "",
          ruleDk:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(6),
          period: 7,
          filePath: undefined,
          blacklistFilePath: undefined,
          excludeArea: false,
          model: "AI00166",
          category: undefined,
        };
      } else if (node.type === "repeatCountAnalysisService") {
        node.extraInfo = {
          splitCount: "10",
        };
      } else if (node.type === "uploadMonitorService") {
        node.extraInfo = {
          code: "",
          status: "SUCCESS",
          remark: "",
        };
      } else if (node.type === "distributionStatisticsService") {
        node.extraInfo = {
          start: "5000",
          end: "10000",
          step: "1000",
          specialGape: "",
        };
      } else if (node.type === "columnGroupAndFetchService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          groupIndex: "1",
          count: "100",
        };
      } else if (node.type === "columnMatchAndFetchService") {
        node.extraInfo = {
          mainFileColumns: "",
          matchFileColumns: "",
        };
      } else if (node.type === "fileStoreService") {
        node.extraInfo = {
          tag: "",
          code: "",
        };
      } else if (node.type === "createFormTaskService") {
        node.extraInfo = {
          formId: undefined,
          formName: "",
          itemList: [],
        };
      } else if (node.type === "pauseNotifyService") {
        node.extraInfo = {
          notifyUsers: this.loginName,
          userName: this.userName,
          userList: [],
        };
      } else if (node.type === "columnJoinFilterService") {
        node.extraInfo = {
          matchIndexOne: "1",
          matchIndexTwo: "1",
          isIntersection: true,
        };
      } else if (node.type === "variableExtractService") {
        node.extraInfo = {
          content: "",
        };
      } else if (node.type === "variableGenerateService") {
        node.extraInfo = {
          separator: ",",
          variableList: [],
        };
      } else if (node.type === "phoneGenerateService") {
        node.extraInfo = {
          segments: "",
          returnType: "PHONE",
        };
      } else if (node.type === "upload2OmegaMidTableService") {
        node.extraInfo = {
          midTableName: undefined,
          dataRelationMap: {},
        };
      } else if (node.type === "customerBlackListService") {
        node.extraInfo = {
          sourceList: [],
        };
      } else if (node.type === "multipleLine2OneService") {
        node.extraInfo = {
          separator: ",",
        };
      } else if (node.type === "cellComputationService") {
        node.extraInfo = {
          capacity: 10,
          conditionList: [],
        };
      } else if (node.type === "analysisUrlOfOneAppService") {
        node.extraInfo = {
          appName: "",
        };
      } else if (node.type === "aiCallOutInput94Service") {
        node.extraInfo = {
          taskName: "",
          taskId: undefined,
          appName: "",
          appKey: undefined,
        };
      } else if (
        node.type == "diyAbstractMultipleInputAndOutputService" ||
        node.type == "diySingleInputAndMultipleOutputService" ||
        node.type == "diyAbstractSingleInputAndOutputService" ||
        node.type == "diyAbstractSingleInputAndNoOutputService" ||
        node.type == "diyAbstractMultipleInputAndOneOutputService"
      ) {
        node.extraInfo = {
          formId: node.nodeId,
          itemList: [],
        };
      } else if (node.type === "fastFilterColumnService") {
        node.extraInfo = {
          filterColumn: "",
          excludeFilterColumn: false,
          complexRules: false,
          rules: "",
        };
      } else if (node.type === "parquetUploadService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          schemaName: "",
          bucketName: undefined,
          filePath: "",
          storageType: undefined,
          fields: [],
        };
      } else if (node.type === "fileContentCheckService") {
        node.extraInfo = {
          separator: ",",
          separatorType: ",",
          passRate: "99",
          conditionList: [],
        };
      } else if (node.type === "create94AiTaskService") {
        node.extraInfo = {
          appKey: undefined,
          appName: "",
          name: "",
          templateId: undefined,
          templateSelectShow: true,
          templateName: "",
          repeatInterval: 1,
          repeatCount: 1,
        };
      }

      /**
       * 这里可以进行业务判断、是否能够添加该节点
       */
      this.jsplumbSourceOptions.maxConnections = 999;
      this.jsplumbTargetOptions.maxConnections = nodeMenu.maxInputCount;
      this.data.nodeList.push(this.$common.deepClone(node));

      let lineType = this.hasLineCount(nodeId, nodeId);
      if (
        (this.endpointNodeMenuShow && node.maxInputCount === 0) ||
        (this.addIntermediateNodeShadeShow && lineType.status === "start")
      ) {
        this.$message.error("【" + node.name + "】节点为开始节点");
      } else if (
        this.addIntermediateNodeShadeShow &&
        lineType.status === "end"
      ) {
        this.$message.error("【" + lineType.name + "】节点为结束节点");
      } else {
        this.modifyTaskNode("ADD", null, node);
      }
      this.$nextTick(() => {
        this.jsPlumb.makeSource(nodeId, this.jsplumbSourceOptions);
        this.jsPlumb.makeTarget(nodeId, this.jsplumbTargetOptions);
        this.jsPlumb.draggable(nodeId, {
          containment: "parent",
          grid: [15, 15],
        });
        // 端点添加节点
        if (this.endpointNodeMenuShow && !this.addIntermediateNodeShadeShow) {
          // 若选中的节点为开始节点，则不能添加
          if (node.maxInputCount === 0) {
            this.data.nodeList = this.data.nodeList.filter((item) => {
              return item.id !== nodeId;
            });
            this.endpointNodeMenuShow = false;
            return;
          }
          if (this.endpointMenuData.node) {
            let endpointNodeId = this.endpointMenuData.node.id;
            let connParam = {
              source: endpointNodeId,
              target: nodeId,
              anchor: "Continuous",
            };
            this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
          } else {
            this.frameSelectionList.nodeList.forEach((node) => {
              let connParam = {
                source: node.id,
                target: nodeId,
                anchor: "Continuous",
              };
              this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
            });

            this.frameSelectionList.nodeList.splice(0);
            this.frameSelectionList.lineList.splice(0);
            this.$refs.frameSelection.style.width = 0;
            this.$refs.frameSelection.style.height = 0;
            this.$refs.frameSelection.style.left = 0;
            this.$refs.frameSelection.style.top = 0;
            this.frameSelectionList.left = 0;
            this.frameSelectionList.top = 0;
          }
          this.endpointNodeMenuShow = false;
        }
        // 添加中间节点
        if (this.addIntermediateNodeShadeShow) {
          // 若选中的节点为开始节点，则不能添加
          if (lineType.status === "start") {
            this.data.nodeList = this.data.nodeList.filter((item) => {
              return item.id !== nodeId;
            });
            return;
          }
          // 若选中的节点为结束节点，则不能添加
          if (lineType.status === "end") {
            this.data.nodeList = this.data.nodeList.filter((item) => {
              return item.id !== nodeId;
            });
            return;
          }

          let label = "",
            fromLabel = "",
            toLabel = "";
          for (let index = 0; index < this.data.lineList.length; index++) {
            const item = this.data.lineList[index];
            if (
              item.from == this.activeElement.sourceId &&
              item.to == this.activeElement.targetId
            ) {
              var conn = this.jsPlumb.getConnections({
                source: item.from,
                target: item.to,
              })[0];

              if (this.rightMenu.lineParameterShow) {
                label = item.label;
              }
              this.jsPlumb.deleteConnection(conn);
              break;
            }
          }
          if (this.fromShow && this.toShow) {
            fromLabel = label.split(":")[0];
            toLabel = label.split(":")[1];
          } else if (this.fromShow) {
            fromLabel = label;
          } else if (this.toShow) {
            toLabel = label;
          }
          let connParam1 = {
            source: this.activeElement.sourceId,
            target: nodeId,
            label: fromLabel,
            anchor: "Continuous",
          };
          let connParam2 = {
            source: nodeId,
            target: this.activeElement.targetId,
            label: toLabel,
            anchor: "Continuous",
          };
          this.jsPlumb.connect(connParam1, this.jsplumbConnectOptions);
          this.jsPlumb.connect(connParam2, this.jsplumbConnectOptions);
          this.data.lineList.forEach((lineItem) => {
            if (
              connParam1.source == lineItem.from &&
              connParam1.target == lineItem.to
            ) {
              if (lineItem.label !== connParam1.label) {
                this.modifyTaskNodeLine(
                  "MODIFY",
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    initialLabel: lineItem.label,
                  },
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    label: connParam1.label,
                  }
                );
              }
              this.$set(lineItem, "label", connParam1.label);
            }
            if (
              connParam2.source == lineItem.from &&
              connParam2.target == lineItem.to
            ) {
              if (lineItem.label !== connParam2.label) {
                this.modifyTaskNodeLine(
                  "MODIFY",
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    initialLabel: lineItem.label,
                  },
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    label: connParam2.label,
                  }
                );
              }
              this.$set(lineItem, "label", connParam2.label);
            }
          });
          this.getNextNodeAndResetState(nodeId);
          this.addIntermediateNodeShadeShow = false;
        }
        if (ctrlKeyShow) {
          this.endpointNodeMenu(evt, node);
        }
        this.$message.success("添加成功");
      });
    },
    // 返回唯一标识
    async generateNodeId() {
      let nodeId = "";
      await this.$http.get("/sysPipeline/generateNodeId").then((res) => {
        if (res.result === 200) {
          nodeId = res.data.nodeId;
        }
      });
      return nodeId;
    },
    generateRandomLetters(length) {
      const alphabet = "abcdefghijklmnopqrstuvwxyz0123456789";
      let randomString = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        randomString += alphabet.charAt(randomIndex);
      }
      return randomString;
    },
    // 删除激活的元素
    deleteElement() {
      if (this.activeElement.type === "node") {
        this.deleteNode(this.activeElement.nodeId);
      } else if (this.activeElement.type === "line") {
        var conn = this.jsPlumb.getConnections({
          source: this.activeElement.sourceId,
          target: this.activeElement.targetId,
        })[0];
        this.jsPlumb.deleteConnection(conn);
      }
    },
    /**
     * 删除节点
     * @param nodeId 被删除节点的ID
     */
    deleteNode(nodeId) {
      this.data.nodeList = this.data.nodeList.filter((node) => {
        if (node.id == nodeId) {
          this.modifyTaskNode("DELETE", node);
          return false;
        }
        return true;
      });

      let lineFromArr = this.data.lineList.filter((line) => {
        return line.from == nodeId;
      });
      let lineToArr = this.data.lineList.filter((line) => {
        return line.to == nodeId;
      });
      this.jsPlumb.removeAllEndpoints(nodeId);
      this.$nextTick(async () => {
        if (lineFromArr.length == 1 && lineToArr.length == 1) {
          let lineFrom = lineToArr[0];
          let lineTo = lineFromArr[0];
          let conn1 = this.jsPlumb.getConnections({
            source: lineFrom.from,
            target: lineFrom.to,
          })[0];
          let conn2 = this.jsPlumb.getConnections({
            source: lineTo.from,
            target: lineTo.to,
          })[0];
          let line = {
            source: lineFrom.from,
            target: lineTo.to,
            label: "",
            anchor: "Continuous",
          };
          let firstNode = this.data.nodeList.filter((node) => {
            return node.id == lineFrom.from;
          });
          let secondNode = this.data.nodeList.filter((node) => {
            return node.id == nodeId;
          });
          let thirdNode = this.data.nodeList.filter((node) => {
            return node.id == lineTo.to;
          });
          this.getNextNodeAndResetState(line.target);
          await this.getLineLabel(conn1, null, true, firstNode, secondNode);
          if (this.fromShow) {
            line.label = lineFrom.label;
          }
          await this.getLineLabel(conn2, null, true, secondNode, thirdNode);
          if (this.toShow) {
            if (lineFrom.label) {
              line.label = lineFrom.label + ":" + lineTo.label;
            } else {
              line.label = lineTo.label;
            }
          }
          let sameLine = this.data.lineList.filter((lineItem) => {
            return line.source == lineItem.from && line.target == lineItem.to;
          });
          if (sameLine.length == 0) {
            this.jsPlumb.connect(line, this.jsplumbConnectOptions);
          }
          for (let index = 0; index < this.data.lineList.length; index++) {
            const lineItem = this.data.lineList[index];
            if (line.source == lineItem.from && line.target == lineItem.to) {
              if (lineItem.label !== line.label) {
                this.modifyTaskNodeLine(
                  "MODIFY",
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    initialLabel: lineItem.label,
                  },
                  {
                    from: lineItem.from,
                    to: lineItem.to,
                    label: line.label,
                  }
                );
              }
              this.$set(lineItem, "label", line.label);
            }
          }
        } else if (lineFromArr.length == 1) {
          let lineFrom = lineFromArr[0];
          this.getNextNodeAndResetState(lineFrom.from);
        }
      });
    },
    // 改变节点的位置
    changeNodeSite(data) {
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        if (node.id === data.nodeId) {
          if (node.left !== data.left || node.top != data.top) {
            this.rightFormShow = false;
          }
          node.oldLeft = node.left;
          node.oldTop = node.top;
          node.left = data.left;
          node.top = data.top;
          this.modifyTaskNode("MOVE", node, null);
        }
      }
    },
    // 删除线
    deleteLine(from, to) {
      this.data.lineList = this.data.lineList.filter((line) => {
        if (line.from == from && line.to == to) {
          if (this.frameSelectionList.nodeList.length == 0) {
            this.modifyTaskNodeLine("DELETE", line);
          }
          return false;
        }
        return true;
      });
    },
    // 设置连线条件
    setLineLabel(from, to, label) {
      this.data.lineList.forEach((line) => {
        if (line.from == from && line.to == to) {
          let initialLabel = line.label;
          this.$set(line, "label", label);
          if (initialLabel !== line.label) {
            this.modifyTaskNodeLine(
              "MODIFY",
              {
                from: line.from,
                to: line.to,
                initialLabel,
              },
              line
            );
          }
        }
      });
      if (this.rightMenu.lineParameterShow && label) {
        if (this.fromOptionsList.length > 0) {
          let otherLine = this.fromOptionsList.filter((item) => {
            return label.indexOf(item.label) == -1;
          });
          this.data.lineList.forEach((line) => {
            if (line.from == from && line.to !== to) {
              if (line.label) {
                otherLine = otherLine.filter((item) => {
                  return line.label.indexOf(item.label) == -1;
                });
              } else {
                if (otherLine[0].label !== line.label) {
                  this.modifyTaskNodeLine(
                    "MODIFY",
                    {
                      from,
                      to: line.to,
                      initialLabel: line.label,
                    },
                    {
                      from,
                      to: line.to,
                      label: otherLine[0].label,
                    }
                  );
                }
                this.$set(line, "label", otherLine[0].label);
                otherLine.shift();
              }
            }
          });
        }
        if (this.toOptionsList.length > 0) {
          let otherLine = this.toOptionsList.filter((item) => {
            return label.indexOf(item.label) == -1;
          });
          this.data.lineList.forEach((line) => {
            if (line.from !== from && line.to == to) {
              if (line.label) {
                otherLine = otherLine.filter((item) => {
                  return line.label.indexOf(item.label) == -1;
                });
              } else {
                if (otherLine[0].label !== line.label) {
                  this.modifyTaskNodeLine(
                    "MODIFY",
                    {
                      from: line.from,
                      to,
                      initialLabel: line.label,
                    },
                    {
                      from: line.from,
                      to,
                      label: otherLine[0].label,
                    }
                  );
                }
                this.$set(line, "label", otherLine[0].label);
                otherLine.shift();
              }
            }
          });
        }
      }
      this.data.nodeList.forEach((node) => {
        if (node.id == to) {
          if (node.state !== "WAITING") {
            let oldNode = lodash.cloneDeep(node);
            this.modifyTaskNode("RESET", oldNode);
            this.$set(node, "state", "WAITING");
            this.$set(node, "errorMessage", "");
            this.$set(node, "totalCount", 0);
            this.$set(node, "completeCount", 0);
          }
          this.getNextNodeAndResetState(node.id);
        }
      });
    },
    resetState(nodeId, manual) {
      this.data.nodeList.forEach((item) => {
        if (item.id === nodeId) {
          if (item.state !== "WAITING") {
            let oldNode = lodash.cloneDeep(item);
            this.modifyTaskNode("RESET", oldNode);
            this.$set(item, "state", "WAITING");
            this.$set(item, "errorMessage", "");
            this.$set(item, "totalCount", 0);
            this.$set(item, "completeCount", 0);
          }
          if (item.type == "COMPOSE_NODE") {
            this.getNextComposeNodeAndResetState(item.name);
          }
        }
      });
      this.getNextNodeAndResetState(nodeId, manual);
      if (!manual) {
        this.$message.success("重置成功");
      }
    },
    getNextComposeNodeAndResetState(name) {
      this.composeWorkflowTaskList.forEach((item) => {
        if (name == item.name) {
          item.workflowTask.nodeList.forEach((node) => {
            if (node.state !== "WAITING") {
              let oldNode = lodash.cloneDeep(node);
              this.modifyTaskNode("RESET", oldNode);
              this.$set(node, "state", "WAITING");
              this.$set(node, "errorMessage", "");
              this.$set(node, "totalCount", 0);
              this.$set(node, "completeCount", 0);
            }
          });
        }
      });
    },
    getNextNodeAndResetState(from, manual) {
      let arr = this.data.lineList.filter((line) => {
        return line.from == from;
      });
      if (arr.length > 0) {
        arr.forEach((line) => {
          this.data.nodeList.forEach((node) => {
            if (line.to == node.id) {
              if (node.state !== "WAITING") {
                let oldNode = lodash.cloneDeep(node);
                this.modifyTaskNode("RESET", oldNode);
                this.$set(node, "state", "WAITING");
                this.$set(node, "errorMessage", "");
                this.$set(node, "totalCount", 0);
                this.$set(node, "completeCount", 0);
              }

              if (node.type == "COMPOSE_NODE") {
                this.getNextComposeNodeAndResetState(node.name);
              } else {
                this.getNextNodeAndResetState(node.id, manual);
              }
            }
          });
        });
      } else if (!manual) {
        if (this.resetStateTimer) {
          clearTimeout(this.resetStateTimer);
          this.resetStateTimer = null;
        }
        this.resetStateTimer = setTimeout(() => {
          this.dataReload(lodash.cloneDeep(this.data));
        }, 100);
      }
    },
    clickNode(nodeId, nodeName, nodeType) {
      if (
        nodeType == "COMPOSE_NODE" ||
        nodeType == "PROCESS_INPUT" ||
        nodeType == "PROCESS_OUTPUT"
      ) {
        return;
      }
      if (this.rightFormShow) {
        this.nodeFormShow = true;
      }
      this.rightMenu.show = false;
      this.endpointNodeMenuShow = false;
      this.rightFormShow = JSON.parse(localStorage.getItem("rightFormShow"));
      this.nodeId = nodeId;
      this.nodeName = nodeName;
      this.$nextTick(() => {
        this.activeElement.type = "node";
        this.activeElement.nodeId = nodeId;
        this.activeElement.name = nodeName;
        this.$refs.nodeForm.nodeInit(this.data, nodeId);
      });
    },
    // 是否具有该线
    hasLine(from, to) {
      for (var i = 0; i < this.data.lineList.length; i++) {
        var line = this.data.lineList[i];
        if (line.from === from && line.to === to) {
          return true;
        }
      }
      return false;
    },
    // 是否含有相反的线
    hashOppositeLine(from, to) {
      return this.hasLine(to, from);
    },
    // 判断节点连线--开始or结束
    hasLineCount(from, to) {
      let res = {
        status: "process",
        name: "",
      };
      let nodeList = this.data.nodeList;
      let stratNode = nodeList.filter((item) => item.id === from)[0];
      let endNode = nodeList.filter((item) => item.id === to)[0];
      if (stratNode.maxOutputCount === 0) {
        res = {
          status: "end",
          name: stratNode.name,
        };
        return res;
      }
      if (endNode.maxInputCount === 0) {
        res = {
          status: "start",
          name: endNode.name,
        };
        return res;
      }
      return res;
    },
    endpointNodeMenu(e, node) {
      this.endpointNodeMenuShow = true;
      this.rightMenu.show = false;
      let top = 0,
        left = 0;
      let right = window.innerWidth - e.pageX;
      let bottom = window.innerHeight - e.pageY;
      if (right >= 650) {
        left = e.pageX;
      } else {
        left = e.pageX - (650 - right);
      }
      if (bottom >= 500) {
        top = e.pageY;
      } else {
        top = e.pageY - (500 - bottom);
      }

      this.endpointMenuData = {
        node: node ? JSON.parse(JSON.stringify(node)) : null,
        top,
        left,
      };
    },
    async getLineLabel(conn, evt, midNode, fNode, tNode) {
      this.fromOptionsList.splice(0);
      this.toOptionsList.splice(0);
      let fromNode = {},
        toNode = {};
      if (fNode) {
        fromNode = fNode;
        toNode = tNode;
      } else {
        fromNode = this.data.nodeList.filter(
          (item) => item.id === conn.sourceId
        );
        toNode = this.data.nodeList.filter((item) => item.id === conn.targetId);
      }
      if (fromNode.length > 0) {
        let fromType = fromNode[0].type;
        if (fromType == "priorityDiffService") {
          this.fromPriorityDiffShow = true;
        } else {
          this.fromPriorityDiffShow = false;
        }
        switch (fromType) {
          case "calculateService":
            if (fromNode[0].extraInfo.conditionList.length > 0) {
              fromNode[0].extraInfo.conditionList.forEach((item) => {
                this.fromOptionsList.push({
                  value: item.tag,
                  label: item.tag,
                });
              });
            }
            break;
          case "fastFilterColumnService":
            if (fromNode[0].extraInfo.rules) {
              if (fromNode[0].extraInfo.complexRules) {
                // 将数据按行分割
                const rows = fromNode[0].extraInfo.rules.split("\n");

                // 用于存储第二列的字符
                const secondColumn = [];

                // 遍历每一行
                rows.forEach((row) => {
                  // 按制表符或空格分割列
                  const columns = row.split(/\s+/);

                  // 如果第二列存在且不为空，则添加到数组中
                  if (columns[1] && columns[1].trim() !== "") {
                    secondColumn.push(columns[1].trim());
                  }
                });

                // 去重
                const uniqueSecondColumn = [...new Set(secondColumn)];
                uniqueSecondColumn.forEach((rule) => {
                  this.fromOptionsList.push({
                    value: rule,
                    label: rule,
                  });
                });
              } else {
                fromNode[0].extraInfo.rules.split(",").forEach((rule) => {
                  this.fromOptionsList.push({
                    value: rule,
                    label: rule,
                  });
                });
              }
              this.fromOptionsList.push({
                value: "其它",
                label: "其它",
              });
            }
            break;
          default:
            let fromOptionsList = await this.loadLineLabelData(
              fromType,
              "OUT",
              fromNode[0]
            );
            this.fromOptionsList = fromOptionsList.map((item) => {
              return { value: item, label: item };
            });

            break;
        }
      }
      if (toNode.length > 0) {
        let toType = toNode[0].type;
        if (
          toType == "calculateService" ||
          toType == "diyMultipleInputAndOneOutputService" ||
          toType == "diyMultipleInputAndOutputService" ||
          toType == "PROCESS_OUTPUT"
        ) {
          this.tagShow = true;
        } else {
          this.tagShow = false;
        }
        let toOptionsList = await this.loadLineLabelData(
          toType,
          "IN",
          toNode[0]
        );
        this.toOptionsList = toOptionsList.map((item) => {
          return { value: item, label: item };
        });
      }

      if (this.fromOptionsList.length == 0 && this.toOptionsList.length == 0) {
        this.rightMenu.lineParameterShow = false;
      } else {
        this.rightMenu.lineParameterShow = true;
      }
      if (this.fromOptionsList.length > 0) {
        if (this.fromOptionsList[0].value == "@INPUT") {
          this.fromOptionsList.splice(0);
        }
        this.fromShow = true;
      } else {
        this.fromShow = false;
      }
      if (this.toOptionsList.length > 0) {
        if (this.toOptionsList[0].value == "@INPUT") {
          this.toOptionsList.splice(0);
        }
        this.toShow = true;
      } else {
        this.toShow = false;
      }
      if (!midNode) {
        this.nodeRightMenu(evt, "line", {
          from: conn.sourceId,
          to: conn.targetId,
          label: conn.getLabel(),
          nodeList: this.data.nodeList,
        });
      }
    },
    async loadLineLabelData(action, direction, node) {
      let data = {
        action,
        direction,
      };
      if (
        action == "diyAbstractMultipleInputAndOutputService" ||
        action == "diySingleInputAndMultipleOutputService" ||
        action == "diyAbstractSingleInputAndOutputService" ||
        action == "diyAbstractSingleInputAndNoOutputService" ||
        action == "diyAbstractMultipleInputAndOneOutputService"
      ) {
        data.nodeId = node.nodeId;
      }
      let list = [];
      await this.$http
        .get("/sysPipeline/loadLineLabelData", data)
        .then((res) => {
          if (res.result === 200) {
            list = res.data;
          }
        });
      return list;
    },
    nodeRightMenu(evt, menuType, menuData) {
      this.nodeFormShow = false;
      this.endpointNodeMenuShow = false;
      this.rightMenu.show = false;
      if (menuType == "node") {
        let data = {
          pipelineTaskId: this.tabId,
          nodeId: menuData.node.id,
        };
        this.checkNotifyState(data);
      }
      this.$nextTick(() => {
        this.rightMenu.left = evt.pageX + "px";
        this.rightMenu.top = evt.pageY + "px";
        this.rightMenu.menuType = menuType;
        this.rightMenu.data = menuData;
        this.rightMenu.show = true;
      });
    },
    checkNotifyState(data) {
      this.$http.get("/pipelineTaskNode/checkNotifyState", data).then((res) => {
        if (res.result === 200) {
          this.rightMenu.notifyState = res.data;
        }
      });
    },
    // 左击页面
    panelClick() {
      this.rightMenu.show = false;
      this.endpointNodeMenuShow = false;
    },
    // 右击页面
    panelContextmenu() {
      this.rightMenu.show = false;
      this.endpointNodeMenuShow = false;
    },
    repaintEverything(node) {
      let arr = this.data.nodeList.filter((item) => {
        return item.id == node.id;
      });
      if (arr.length > 0) {
        this.data.nodeList.forEach((item) => {
          if (item.id === node.id) {
            let oldNode = lodash.cloneDeep(item);
            item.name = node.name;
            item.property = node.property;
            if (item.state !== "WAITING") {
              this.modifyTaskNode("RESET", oldNode);
              this.$set(item, "state", "WAITING");
              this.$set(item, "errorMessage", "");
              this.$set(item, "totalCount", 0);
              this.$set(item, "completeCount", 0);
            }
            this.modifyTaskNode("MODIFY", oldNode, item);
          }
        });
        this.getNextNodeAndResetState(node.id);
        this.$message.success("编辑成功");
      } else {
        this.$message.warning("该节点不存在");
      }
    },
    // 加载流程图
    dataReload(data) {
      this.easyFlowVisible = false;
      if (this.data.lineList.length > 0) {
        for (let index = 0; index < this.data.lineList.length; index++) {
          const item = this.data.lineList[index];

          var conn = this.jsPlumb.getConnections({
            source: item.from,
            target: item.to,
          })[0];
          this.jsPlumb.deleteConnection(conn);
        }
        this.data.lineList.splice(0);
      }
      if (this.data.nodeList.length > 0) {
        for (let index = 0; index < this.data.nodeList.length; index++) {
          const item = this.data.nodeList[index];

          this.jsPlumb.removeAllEndpoints(item.id);
        }
        this.data.nodeList.splice(0);
      }
      this.$nextTick(() => {
        this.easyFlowVisible = true;
        this.data = lodash.cloneDeep(data);
        this.$nextTick(() => {
          this.jsPlumb = jsPlumb.getInstance();
          if (this.resetStateNodeId) {
            this.resetState(this.resetStateNodeId, true);
          }
          this.$nextTick(() => {
            this.jsPlumbInit();
          });
        });
      });
    },
    getMenuByNodeId(nodeId) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].id === nodeId) {
            return children[j];
          }
        }
      }
    },
    getMenuByType(type) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].type === type) {
            return children[j];
          }
        }
      }
    },
    openHelp() {
      this.flowHelpVisible = true;
    },
    editThreadCountClick() {
      this.originalThreadCount = this.threadCount;
      this.editThreadCountShow = true;
    },
    editThreadCountSubmit() {
      let data = {
        taskId: this.pipelineId,
        originalThreadCount: this.originalThreadCount,
        threadCount: this.threadCount,
      };

      this.$http
        .json_post("/pipelineTask/modifyPipelineTaskThreadCount", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.editThreadCountShow = false;
          }
        });
    },
  },
  beforeDestroy() {
    if (this.refreshProgressTimer) {
      clearInterval(this.refreshProgressTimer);
      this.refreshProgressTimer = null;
    }
    if (this.refreshRunTimeTimer) {
      clearInterval(this.refreshRunTimeTimer);
      this.refreshRunTimeTimer = null;
    }
    if (this.queryTimer) {
      clearInterval(this.queryTimer);
      this.queryTimer = null;
    }

    if (this.shareTimer) {
      clearInterval(this.shareTimer);
      this.shareTimer = null;
    }
    document.body.removeEventListener("keydown", this.keyboardShortcuts);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    sessionStorage.removeItem("modifyMap");
    document.title = "惠誉管理后台";
  },
};
</script>
<style lang="scss">
.panel {
  height: 100vh;
  padding: 0 20px;
  user-select: none;

  .ef-tool-tar {
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .back {
      cursor: pointer;
      height: 100%;
      .button {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .button:hover {
        background-color: #e0e0e0;
      }
      i {
        vertical-align: middle;
      }
    }

    .tabs {
      width: calc(100% - 370px);
      position: relative;
      box-sizing: border-box;
      margin: 0;
      padding-left: 0;
      list-style: none;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      height: 40px;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;

      .tabList {
        max-width: calc(100% - 20px);
        height: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        .active {
          background: #fff !important;
          .ant-btn {
            color: #1890ff !important;
          }
          .borderBottom {
            width: 100% !important;
          }
        }
        .tabCard {
          position: relative;
          height: 34px;
          line-height: 34px;
          margin: 0;
          margin-right: 2px;
          padding: 0 16px 0 10px;
          background: #fafafa;
          border: 1px solid #e8e8e8;
          border-bottom: none;
          border-radius: 4px 4px 0 0;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          .ant-btn {
            font-size: 12px !important;
            color: #000000a6;
          }

          .rage {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .borderBottom {
            width: 0;
            height: 2px;
            position: absolute;
            top: 33px;
            left: 0;
            z-index: 2;
            background-color: #fff;
          }
          .delete {
            position: absolute;
            top: 3px;
            right: 2px;
            color: #aeaeae;
            font-size: 12px;
          }
        }
      }
      .add {
        font-size: 18px !important;
      }
    }

    .tool {
      height: 100%;
      margin-left: auto;
      button {
        width: 40px !important;
        height: 100%;
        margin: 0 !important;
      }
      button:hover {
        background-color: #e0e0e0;
        border-radius: 0 !important;
      }
    }
  }

  .flow-menu {
    .node-menu-ul-item {
      display: inline-block;
      width: 40px;
      position: relative;

      /*节点菜单*/
      .ef-node-pmenu {
        width: 100%;
        text-align: center;
        cursor: pointer;
        height: 42px;
        line-height: 42px;
        display: block;
        font-weight: bold;
        color: #409eff;
        &:hover {
          background-color: #e0e0e0;
        }
        i {
          font-size: 18px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  /*画布容器*/
  #efContainer {
    position: relative;
    overflow: scroll;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#ddd 1px, transparent 0),
      linear-gradient(90deg, #ddd 1px, transparent 0),
      linear-gradient(#f6f6f6 1px, transparent 0),
      linear-gradient(90deg, #f6f6f6 1px, transparent 0);
    background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;

    .suspensionMenu {
      position: fixed;
      top: 50px;
      left: 190px;
      z-index: 1;
      line-height: 35px;
    }

    /* 缩放工具 */
    .zoom-tool {
      opacity: 0.5;
      position: fixed;
      left: 190px;
      bottom: 10vh;
      z-index: 1;
      transition: all 0.5s;

      .enlarged-btn {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

      .shrink-btn {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        opacity: 1;
      }

      &::before {
        content: "";
      }
    }

    .fileMask {
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      color: #fff;
      font-size: 30px;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
    }

    .composeWorkflowTaskList {
      position: fixed;
      margin: 1px 0 0 1px;
      height: calc(100% - 83px);
      width: 30px;
      z-index: 100;
      padding: 5px;

      .workflowTaskItem {
        cursor: pointer;
        text-align: center;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        opacity: 0.8;
      }

      .workflowTaskItemActive {
        border: 2px solid #409eff;
      }
    }

    #eeContainer {
      width: 400%;
      height: 400%;
      transform-origin: center;
    }

    .collapsed {
      left: 90px !important;
    }

    .miniView {
      position: fixed;
      left: 91%;
      top: 83.5%;
      border: 1px solid #4f6f7e;
      width: calc(150% - 322px);
      height: calc(150% - 122px);
      transform: scale(6%, 10%);
      transform-origin: 0% 0%;
      background-color: #4f6f7e52;
      overflow: hidden;
    }

    .miniNode {
      width: 42px;
      height: 15px;
      background-color: #7e919b;
      position: absolute;
      z-index: 999;
    }

    .mask {
      width: 25%;
      height: 25%;
      position: absolute;
      background-color: #ffffffac;
    }

    .rightForm {
      padding: 10px;
      position: fixed;
      right: 45px;
      top: 50px;
      background-color: #fff;
      box-shadow: 0 0 6px rgb(192, 196, 204);
      z-index: 1;
    }

    .frameSelection {
      position: absolute;
      background-color: #efefef8c;
      width: 0px;
      height: 0px;
      left: 0px;
      top: 0px;
      z-index: 10;
    }
  }
}

.nodeIcon {
  background-size: 100% 100%;
  background-position: center;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.iconImg {
  display: inline-block;
  width: 18px;
  height: 18px;
}
.otherActionIconImg {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
.fileAnalysisTable {
  .ant-table-header-column,
  .ant-table-column-title {
    display: inline-block;
    width: 100%;
  }
}
.popover {
  width: 400px;
  height: 500px;
  position: relative;
  .searchInput {
    margin-bottom: 5px;
  }

  .checkList {
    margin: 10px 0;
    height: calc(100% - 100px);
    overflow: auto;

    .ant-checkbox-group-item {
      display: block;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #7c7c7c;
    }
  }

  .popoverFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: right;

    .leftbutton {
      margin-right: 10px;
    }
    .button {
      font-size: 12px;
    }
  }
}
</style>
<style scoped>
/* 连线中的label 样式*/
:deep().jtk-overlay {
  padding: 4px 10px;
  background-color: white;
  color: #565758 !important;
  border: 1px solid #e0e3e7;
  border-radius: 5px;
}

:deep().aLabel {
  background-color: transparent !important;
  border: transparent;
}

:deep().animated-connection path {
  stroke: #3c8eff;
  stroke-width: 2;
}

:deep().animated-connection path:not(:nth-of-type(3)) {
  stroke-dasharray: 10%;
  animation: dash 2s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 100%;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
