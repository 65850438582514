<template>
  <div class="taskList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>创建时间：</span>
        <a-range-picker
          v-model="condition.time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 370px"
        ></a-range-picker>
      </div>
      <div class="searchConditionItem">
        <span>状态：</span>
        <a-select v-model="condition.status" allowClear placeholder="请选择">
          <a-select-option
            v-for="item in statusList"
            :key="item.code"
            :value="item.code"
          >
            {{ item.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>任务ID：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入任务名称"
          @change="allowClearChange"
          @pressEnter="query(1)"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        <span>任务类型：</span>
        <el-cascader
          :options="taskList"
          :props="optionProps"
          v-model="condition.taskType"
          :show-all-levels="false"
          allowClear
          filterable
        >
        </el-cascader>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="totalCount" slot-scope="text, record">
        <template v-if="record.totalCount == record.completeCount">
          {{ record.totalCount }}
        </template>
        <template v-else>
          <a-tooltip placement="left">
            <div slot="title">
              已完成条数:{{ record.completeCount }} <br />
              总条数:{{ record.totalCount }}
            </div>
            {{ record.completeCount }}/{{ record.totalCount }}
          </a-tooltip>
        </template>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tooltip
          :title="record.executeMessage"
          placement="top"
          v-if="record.status === '错误' || record.status === '重复执行'"
        >
          <a-tag :color="record.statusColor">{{ record.status }}</a-tag>
        </a-tooltip>
        <a-tag v-else :color="record.statusColor">{{ record.status }}</a-tag>
        <a-tooltip
          v-if="record.status == '已定时'"
          :title="'执行时间:' + record.executeDate"
          placement="top"
        >
          <a-icon type="exclamation-circle" style="color: #d2983c" />
        </a-tooltip>
      </span>
      <span slot="name" slot-scope="text, record">
        <a href="javascript:void(0)" @click="downloadFile(record)">
          {{ record.name }}
        </a>
      </span>
      <span slot="progress" slot-scope="text, record">
        <el-progress
          color="rgba(142, 113, 199, 0.7)"
          :percentage="record.progress"
          :text-inside="true"
          :stroke-width="18"
        ></el-progress>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          size="small"
          :disabled="record.expired"
          @click="showFileList(record)"
          >文件列表</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          v-if="record.show"
          @confirm="stop(record)"
        >
          <template slot="title">确认终止任务？</template>
          <a href="javascript:;">终止任务</a>
        </a-popconfirm>
        <a-divider type="vertical" v-if="record.show" />
        <a-popconfirm
          placement="left"
          v-if="record.show"
          @confirm="pause(record)"
        >
          <template slot="title">确认暂停任务？</template>
          <a href="javascript:;">暂停任务</a>
        </a-popconfirm>
        <a-popconfirm
          placement="left"
          v-if="record.show_recover"
          @confirm="recover(record)"
        >
          <template slot="title">确认恢复？</template>
          <a href="javascript:;">恢复</a>
        </a-popconfirm>
        <a-divider type="vertical" v-if="record.show || record.show_recover" />
        <a-popconfirm
          placement="left"
          v-if="record.show_wait"
          okText="通过"
          cancelText="驳回"
          @confirm="toExamineConfirm(record)"
          @cancel="toExamineCancel(record)"
        >
          <template slot="title">确认审核？</template>
          <a href="javascript:;">审核</a>
        </a-popconfirm>
        <a-divider type="vertical" v-if="record.show_wait" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="doTaskNow(record)"
                :disabled="record.status !== '已定时'"
              >
                <template slot="title">立即执行{{ record.name }}任务?</template>
                <a-button
                  type="link"
                  size="small"
                  :disabled="record.status !== '已定时'"
                  >立即执行</a-button
                >
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="cancelPipelineTask(record)"
                :disabled="record.status !== '已定时'"
              >
                <template slot="title">取消执行{{ record.name }}任务?</template>
                <a-button
                  type="link"
                  size="small"
                  :disabled="record.status !== '已定时'"
                  >取消执行</a-button
                >
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      width="30%"
      title="分享设置"
      v-model="innerVisible"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-date-picker
            v-model="expirationTime"
            placeholder="选择日期时间"
            style="width: 230px"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select
            v-model="fileSpaceId"
            placeholder="请选择"
            style="width: 230px"
          >
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      :maskClosable="false"
      width="30%"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-textarea
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="fileContent.split('\n').length == 500"
      >
        最多只能显示五百行
      </h4>
    </a-modal>
    <!-- 文件列表 -->
    <a-modal
      :maskClosable="false"
      title="文件列表"
      v-model="listFiles"
      width="1000px"
      :afterClose="closeDialogFiles"
      :footer="null"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <template v-if="index !== fileListTableData.length - 1">
            <a-button type="link" size="small" @click="downloadClick(record)"
              >下载</a-button
            >
            <a-divider type="vertical" />
            <a-button
              type="link"
              size="small"
              @click="readFileContent(record)"
              :disabled="
                record.name.split('.')[record.name.split('.').length - 1] ==
                'zip'
              "
              >查看</a-button
            >
            <a-divider type="vertical" />
            <a-button type="link" size="small" @click="shareClick(record)"
              >分享</a-button
            >
            <a-divider type="vertical" />
            <a-button type="link" size="small" @click="copyPath(record)"
              >复制路径</a-button
            >
          </template>
        </span>
      </a-table>
    </a-modal>
    <!-- 辅助弹框 -->
    <a-modal
      :maskClosable="false"
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="null"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        :data-clipboard-text="inviteCode"
        @click="copy"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>

    <a-modal
      width="800px"
      title="文件下载"
      v-model="downloadFileShow"
      :footer="null"
      :maskClosable="false"
    >
      <div class="flex" style="margin-top: 20px">
        <div class="hidden">{{ parseJobCopyFilePath }}</div>
        <a-button
          type="primary"
          v-copy="parseJobCopyFilePath"
          style="margin-left: auto"
          >复制路径</a-button
        >
        <a-button
          type="primary"
          @click="downloadLocalFile"
          style="margin-left: 10px"
          >下载</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  name: "etaApiTaskList",
  data() {
    return {
      optionProps: {
        value: "en",
        label: "cn",
        children: "list",
        emitPath: false,
      },
      taskList: [],
      statusList: [],
      copyVisible: false,
      inviteCode: "",
      innerVisible: false,
      passwordSwitch: false,
      password: "",
      fileId: "",
      expirationTime: "",
      shareUrl: "",
      jobId: "",
      timer: null,
      condition: {
        checked: false,
        taskType: "",
        status: undefined,
        time: [],
      },
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "requestId",
          ellipsis: true,
          dataIndex: "requestId",
          align: "center",
          width: 200,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
        },
        {
          title: "任务类型",
          ellipsis: true,
          dataIndex: "typeName",
          width: 180,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 100,
        },
        {
          title: "总条数",
          ellipsis: true,
          dataIndex: "totalCount",
          scopedSlots: { customRender: "totalCount" },
          align: "center",
          width: 150,
        },
        {
          title: "提交用户",
          ellipsis: true,
          dataIndex: "author",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "进度",
          ellipsis: true,
          dataIndex: "progress",
          scopedSlots: { customRender: "progress" },
          align: "center",
          width: 180,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "fileId",
          width: 90,
          align: "center",
          width: 100,
          customRender: (text, row, index) => {
            if (index === this.fileListTableData.length - 1) {
              return {
                children: <span>汇总</span>,
                attrs: {
                  colSpan: 1,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
        },
        {
          title: "条数",
          ellipsis: true,
          dataIndex: "lineCount",
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "time",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      fileListTableData: [],
      fileListLoadingTable: false,
      listFiles: false,
      name: "",
      asyncList: [],
      asyncIds: null,
      num: 0,
      fileSpaceId: -1,
      fileSpaceList: [],
      checkFileContentShow: false,
      fileContent: "",
      downloadFileShow: false,
      downloadLocalFileHref: "",
      parseJobCopyFilePath: "",
    };
  },
  mounted() {
    this.query();
    this.get_taskList();
    this.get_statusList();
  },
  methods: {
    // 查询文件空间
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    get_taskList() {
      //获取任务列表
      this.$http.get("/job/type").then((res) => {
        if (res.result == 200) {
          let list = res.data;
          this.taskList = list;
        }
      });
    },
    get_statusList() {
      //获取状态
      this.$http.get("/job/statusList").then((res) => {
        if (res.result == 200) {
          let list = res.data;
          this.statusList = list;
        }
      });
    },
    // 密码随机
    random() {
      this.password = this.$common.random();
    },
    // 点击审核--是
    toExamineConfirm(row) {
      let data = {
        id: row.id,
        passed: true,
      };
      this.$http.json_post("/job/audit", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("审核通过");
          this.query();
        }
      });
    },
    // 点击审核--否
    toExamineCancel(row) {
      let data = {
        id: row.id,
        passed: false,
      };
      this.$http.json_post("/job/audit", data).then((res) => {
        if (res.result === 200) {
          this.$message.warning("审核驳回");
          this.query();
        }
      });
    },
    recover(val) {
      this.$http.json_post("/job/recover", { id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("恢复成功");
          this.query();
        }
      });
    },
    pause(val) {
      this.$http.json_post("/job/pause", { id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("暂停成功");
          this.query();
        }
      });
    },
    stop(val) {
      this.$http.post("/job/stop", { id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("终止成功");
          this.query();
        }
      });
    },
    downloadFile(val) {
      this.downloadLocalFileHref =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/job/download/localFile?id=" +
        val.id;

      this.$http
        .get("/job/parseJob/copyFilePath", { id: val.id })
        .then((res) => {
          if (res.result == 200) {
            this.parseJobCopyFilePath = res.data;
            this.downloadFileShow = true;
          }
        });
    },
    downloadLocalFile() {
      window.location.href = this.downloadLocalFileHref;
    },
    // 点击分享
    shareClick(val) {
      this.fileId = val.fileId;
      this.shareUrl = val.link;
      this.password = "";
      this.expirationTime = "";
      this.fileSpaceId = -1;
      this.passwordSwitch = false;
      this.innerVisible = true;
      this.getSpaceList();
      var date = new Date();
      //今天时间
      var time =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 2 >= 10
          ? date.getMonth() + 2
          : "0" + (date.getMonth() + 2)) +
        "-" +
        (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate());
      this.expirationTime = time;
    },
    // 分享确定
    shareConfirm() {
      let data = {
        id: this.jobId,
        fileId: this.fileId,
        password: this.password,
        expirationTime: this.expirationTime,
        fileSpaceId: this.fileSpaceId,
      };
      if (this.shareUrl.indexOf("notFound=true") != -1) {
        data.notFound = true;
      } else {
        data.notFound = false;
      }
      this.$http.get("/job/getFileShareLink", data).then((res) => {
        if (res.result === 200) {
          this.innerVisible = false;
          this.copyVisible = true;
          this.inviteCode = res.data.url;
          this.$message.success("分享成功");
        }
      });
    },
    copyPath(val) {
      let data = {
        id: val.fileId,
      };
      this.$http.get("/job/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    copy() {
      console.log("复制");
      var clipboard = new Clipboard(".copy-code-button"); // 这里可以理解为选择器，选择上面的复制按钮
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.success("手机权限不支持复制功能");
        // 释放内存
        clipboard.destroy();
      });
    },
    // 点击下载--文件列表
    downloadClick(val) {
      let href = location.protocol + process.env.VUE_APP_BASE_API + val.link;
      window.location.href = href;
      this.listFiles = false;
    },
    readFileContent(val) {
      this.$http
        .get("/job/readFileContent", { fileId: val.fileId })
        .then((res) => {
          if (res.result == 200) {
            if (res.data) {
              this.fileContent = res.data.join("\n");
            }
            this.checkFileContentShow = true;
          }
        });
    },
    // 关闭文件列表
    closeDialogFiles() {
      this.fileListTableData = [];
    },
    showFileList(val) {
      this.jobId = val.id;
      let data = {
        parseJobId: val.id,
      };
      this.$http.get("/job/parseJobFileList", data).then((res) => {
        if (res.result == 200) {
          this.listFiles = true;
          this.fileListTableData.splice(0);
          this.fileListTableData.push(...res.data);
          let lineCount = 0;
          this.fileListTableData.forEach((item) => {
            lineCount += item.lineCount;
          });
          this.fileListTableData.push({ lineCount });
        }
      });
    },
    currentChange_data(index) {
      this.query(index);
    },
    async() {
      //异步刷新
      let params = {
        pageNo: this.currentPage,
        pageSize: 10,
        idString: this.asyncIds,
        name: this.name,
      };
      this.$http.get("/job/status", params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list,
            index = null,
            percent = null,
            asyncIds = [];
          list.map((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.totalCount == -1 || item.completeCount == -1) {
              item.totalCount = "未知";
              item.progress = 0;
            } else {
              percent = (
                (item.completeCount / item.totalCount) *
                100
              ).toString();
              index = percent.indexOf(".");
              if (index > -1) {
                percent = percent.substr(0, index + 3);
              }
              percent = Number(percent);
              if (percent > 100) {
                item.progress = 0;
              } else {
                item.progress = percent;
              }
            }
            switch (item.status.toLowerCase()) {
              case "wait_audit":
                item.status = "待审核";
                item.show_wait = true;
                item.statusColor = "orange";
                break;
              case "audit_failure":
                item.status = "审核失败";
                item.statusColor = "red";
                break;
              case "receipt":
                item.status = "等待回调";
                item.statusColor = "orange";
                break;
              case "terminated":
                item.status = "已终止";
                item.statusColor = "red";
                break;
              case "paused":
                item.status = "已暂停";
                item.show_recover = true;
                item.statusColor = "red";
                break;
              case "pauseing":
                item.status = "暂停中";
                item.statusColor = "orange";
                break;
              case "done":
                item.status = "已完成";
                item.statusColor = "success";
                break;
              case "init":
                item.status = "已开始";
                item.statusColor = "orange";
                break;
              case "scheduled":
                item.status = "已定时";
                item.statusColor = "orange";
                break;
              case "cancel":
                item.status = "已取消";
                item.statusColor = "orange";
                break;
              case "error":
                item.status = "错误";
                item.statusColor = "red";
                break;
              case "again":
                item.status = "重复执行";
                item.statusColor = "orange";
                break;
              case "processing":
                item.status = "处理中";
                item.show = true;
                asyncIds.push(item.id);
                item.statusColor = "orange";
                break;
            }
          });

          this.asyncIds = asyncIds.join();
          if (!this.asyncIds) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.tableData = list;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.currentPage = index;
      }
      this.loadingTable = true;
      let params = {
        pageNo: this.currentPage,
        pageSize: 10,
        idString: null,
        type: this.condition.taskType,
        status: this.condition.status,
        name: this.name,
        queryApiList: true,
      };

      if (this.condition.time.length > 0) {
        data.gmtCreatedStart = this.condition.time[0]
          ? this.condition.time[0] + " 00:00:00"
          : null;
        data.gmtCreatedEnd = this.condition.time[1]
          ? this.condition.time[1] + " 23:59:59"
          : null;
      }
      this.$http.get("/job/status", params).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.total = res.data.total;
            let list = res.data.records,
              percent = null,
              index = null,
              asyncIds = [];
            list.map((item) => {
              // 时间不超过3天可下载
              let current = new Date();
              current.setHours(17);
              current.setMinutes(0);
              current.setSeconds(0);
              current.setMilliseconds(0);
              item.currentTime = current.getTime();
              let diff = item.currentTime - item.gmtCreated;
              if (diff > 60 * 60 * 24 * 3 * 1000) {
                item.isDownload = false;
              } else {
                item.isDownload = true;
              }
              if (item.totalCount == -1 || item.completeCount == -1) {
                item.totalCount = "未知";
                item.progress = 0;
              } else {
                percent = (
                  (item.completeCount / item.totalCount) *
                  100
                ).toString();
                index = percent.indexOf(".");
                if (index > -1) {
                  percent = percent.substr(0, index + 3);
                }
                percent = Number(percent);
                if (percent > 100) {
                  item.progress = 0;
                } else {
                  item.progress = percent;
                }
              }
              switch (item.status.toLowerCase()) {
                case "wait_audit":
                  item.status = "待审核";
                  item.show_wait = true;
                  item.statusColor = "orange";
                  break;
                case "audit_failure":
                  item.status = "审核失败";
                  item.statusColor = "red";
                  break;
                case "receipt":
                  item.status = "等待回调";
                  item.statusColor = "orange";
                  break;
                case "terminated":
                  item.status = "已终止";
                  item.statusColor = "red";
                  break;
                case "paused":
                  item.status = "已暂停";
                  item.show_recover = true;
                  item.statusColor = "red";
                  break;
                case "pauseing":
                  item.status = "暂停中";
                  item.statusColor = "orange";
                  break;
                case "done":
                  item.status = "已完成";
                  item.statusColor = "green";
                  break;
                case "init":
                  item.status = "已开始";
                  item.statusColor = "orange";
                  break;
                case "scheduled":
                  item.status = "已定时";
                  item.statusColor = "orange";
                  break;
                case "cancel":
                  item.status = "已取消";
                  item.statusColor = "orange";
                  break;
                case "error":
                  item.status = "错误";
                  item.statusColor = "red";
                  break;
                case "again":
                  item.status = "重复执行";
                  item.statusColor = "orange";
                  break;
                case "processing":
                  item.status = "处理中";
                  item.show = true;
                  asyncIds.push(item.id);
                  item.statusColor = "orange";
                  break;
              }
            });
            this.asyncIds = asyncIds.join();
            if (this.timer) {
              clearInterval(this.timer);
              this.timer = null;
            }
            if (this.asyncIds) {
              this.timer = setInterval(() => {
                this.async();
              }, 3000);
            }
            this.tableData = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentChange_data(current),
            };
            this.loadingTable = false;
          }
        }
      });
    },
    // 立即执行
    doTaskNow(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/job/doTaskNow", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
    // 取消执行
    cancelPipelineTask(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/job/cancelTask", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
  flex-wrap: nowrap;
  .hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
    line-height: 32px;
  }
}
</style>
