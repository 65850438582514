<template>
  <!-- 节点的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-if="menuData.menuType == 'node' && !processCompose"
  >
    <ul>
      <template
        v-if="
          this.menuData.data.node.type == 'COMPOSE_NODE' &&
          !viewOnly &&
          composeNodeId == ''
        "
      >
        <li @click="composeNodeReName()">
          <span>
            <a-icon type="edit" />
            重命名
          </span>
        </li>
        <li
          style="border-top: 1px solid #e9edf2; padding-top: 4px"
          @click="deComposeClick()"
        >
          <span>
            <a-icon type="down" />
            展开
          </span>
        </li>
      </template>

      <template v-if="this.menuData.data.node.type !== 'COMPOSE_NODE'">
        <li @click="dataClick()" v-if="menuData.data.node.extraInfoShow">
          <span>
            <a-icon type="edit" />
            {{ viewOnly ? "查看" : "参数设置" }}
          </span>
        </li>

        <template v-if="!viewOnly">
          <li @click="deleteClick" style="color: #ff4d4f">
            <span>
              <a-icon type="delete" />
              删除
            </span>
          </li>
        </template>

        <li @click="copyClick('node', $event)">
          <span>
            <a-icon type="copy" />
            复制
          </span>
        </li>
        <li
          @click="resetStateClick"
          v-if="resetStateShow && status !== 'RUNNING'"
        >
          <span>
            <a-icon type="reload" />
            重置状态
          </span>
        </li>
        <span style="border-top: 1px solid #e9edf2; padding-top: 4px"></span>
        <li @click="testClick" v-if="menuData.data.testable && !viewOnly">
          <span>
            <a-icon type="share-alt" />
            测试
          </span>
        </li>

        <li v-if="processClickSwitch">
          <span>
            <i
              class="nodeIcon"
              :style="{
                backgroundImage: `url(${require('../../assets/icon/processNode.png')})`,
                display: 'inline-block',
                width: '20px',
                height: '20px',
              }"
            ></i>
            自定义节点
          </span>
          <div class="sub_right_menu right_menu">
            <ul>
              <li
                @click="getCustomerNodeProcessTask()"
                v-if="
                  menuData.data.node.state == 'SUCCESS' ||
                  menuData.data.node.state == 'ERROR' ||
                  menuData.data.node.state == 'RUNNING'
                "
              >
                <span>
                  <a-icon type="arrow-right" />
                  查看执行过程
                </span>
              </li>
              <li @click="processClick()">
                <span>
                  <a-icon type="arrow-right" />
                  查看自定义节点
                </span>
              </li>
            </ul>
          </div>
        </li>
        <li v-copy="nodeUrl">
          <span>
            <a-icon type="copy" />
            复制节点地址
          </span>
        </li>
        <li
          @click="notifyChange"
          v-if="
            status == 'RUNNING' &&
            menuData.data.node.state !== 'SUCCESS' &&
            menuData.data.node.state !== 'ERROR'
          "
        >
          <span>
            <a-icon type="notification" />
            {{ this.menuData.notifyState ? "取消完成通知" : "完成后通知我" }}
          </span>
        </li>
      </template>

      <li @click="selectSubsequentNodes" v-if="selectSubsequentNodesSwitch">
        <span>
          <a-icon type="table" />
          选中后续节点
        </span>
      </li>

      <template v-if="!viewOnly && menuData.data.node.state !== 'SUCCESS'">
        <li @click="nodeEnabledChange(false)">
          <span>
            <a-icon
              :type="
                menuData.data.node.enabled ? 'close-circle' : 'check-circle'
              "
            />
            {{ menuData.data.node.enabled ? "禁用节点" : "启用当前节点" }}
          </span>
        </li>
        <li v-if="!menuData.data.node.enabled" @click="nodeEnabledChange(true)">
          <span>
            <a-icon type="check-circle" />
            启用后续节点
          </span>
        </li>
      </template>

      <li @click="updateRemark">
        <span>
          <a-icon type="edit" />
          修改备注
        </span>
      </li>
    </ul>
  </div>

  <!-- 线的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'line' && !viewOnly"
  >
    <ul>
      <li v-if="menuData.lineParameterShow" @click="lineClick">
        <span>
          <a-icon type="edit" />
          {{ viewOnly ? "查看" : "参数设置" }}
        </span>
      </li>
      <template v-if="!viewOnly">
        <li @click.stop="addIntermediateNodeClick">
          <span>
            <a-icon type="plus" />
            添加中间节点
          </span>
        </li>
        <li
          v-if="pipelineTaskType !== 'PROCESS_COPY'"
          @click="deleteLineClick"
          style="color: #ff4d4f"
        >
          <span>
            <a-icon type="delete" />
            删除
          </span>
        </li>
      </template>
    </ul>
  </div>

  <!-- 画布的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'panel'"
  >
    <ul>
      <li
        v-if="menuData.data.pasteShow && !viewOnly"
        @click="pasteClick($event)"
      >
        <span>
          <a-icon type="copy" />
          粘贴
        </span>
      </li>
      <li @click="checkAllClick($event)">
        <span>
          <a-icon type="table" />
          一键全选
        </span>
      </li>
      <li @click="analysisPriority()">
        <span>
          <i
            class="nodeIcon"
            :style="{
              backgroundImage: `url(${require('../../assets/icon/priority.png')})`,
              display: 'inline-block',
              width: '20px',
              height: '20px',
            }"
          ></i>
          {{ nodePriorityShow ? "隐藏" : "展示" }}节点优先级
        </span>
      </li>
    </ul>
  </div>

  <!-- 框选的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'frameSelection'"
  >
    <ul>
      <li @click.stop="batchConnect">
        <span>
          <a-icon type="plus" />
          批量连接
        </span>
      </li>
      <li
        v-if="
          !viewOnly &&
          frameSelectionNodeListHasComposeNodeAndDelete &&
          pipelineTaskType !== 'PROCESS_COPY'
        "
        @click="deleteFrameSelectionClick"
        style="color: #ff4d4f"
      >
        <span>
          <a-icon type="delete" />
          删除
        </span>
      </li>
      <li @click="copyClick('FrameSelection')">
        <span>
          <a-icon type="copy" />
          复制
        </span>
      </li>
      <li v-if="!viewOnly" @click="resetStateAllClick">
        <span>
          <a-icon type="reload" />
          重置状态
        </span>
      </li>
      <li
        v-if="
          !viewOnly &&
          composeNodeId == '' &&
          frameSelectionNodeListHasComposeNodeAndCompose
        "
        @click="composeClick()"
      >
        <span>
          <a-icon type="up" />
          收缩
        </span>
      </li>

      <li @click="createTaskClick">
        <span>
          <a-icon type="plus" />
          创建任务
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "right_menu",
  props: [
    "menuData",
    "viewOnly",
    "data",
    "taskId",
    "composeNodeId",
    "frameSelectionNodeListHasComposeNodeAndCompose",
    "frameSelectionNodeListHasComposeNodeAndDelete",
    "status",
    "pipelineTaskType",
    "menuList",
    "nodePriorityShow",
  ],
  data() {
    return {
      resetStateShow: false,
      nodeId: "",
    };
  },
  computed: {
    // 自定义节点
    processClickSwitch() {
      return (
        this.menuData.data.node.type ==
          "diyAbstractMultipleInputAndOutputService" ||
        this.menuData.data.node.type ==
          "diySingleInputAndMultipleOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractSingleInputAndOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractSingleInputAndNoOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractMultipleInputAndOneOutputService"
      );
    },
    // 输入输出节点
    processCompose() {
      return (
        (this.menuData.data.node.type == "PROCESS_INPUT" ||
          this.menuData.data.node.type == "PROCESS_OUTPUT") &&
        this.composeNodeId !== ""
      );
    },
    // 后续是否有节点
    selectSubsequentNodesSwitch() {
      let arr = this.data.lineList.filter((line) => {
        return line.from == this.nodeId;
      });
      if (arr.length > 0) {
        return true;
      }
      return false;
    },
    nodeUrl() {
      const currentQuery = { ...this.$route.query };

      currentQuery.nodeId = this.nodeId;

      const newPath = {
        path: this.$route.path,
        query: currentQuery,
      };

      const resolvedRoute = this.$router.resolve(newPath);
      return location.origin + resolvedRoute.href;
    },
  },
  mounted() {
    let div1 = document.getElementById("right_menu");
    if (div1) {
      div1.style.left = this.menuData.left;
      div1.style.top = this.menuData.top;
    }
    if (this.menuData.menuType == "node") {
      this.nodeId = this.menuData.data.node.id;
      if (this.menuData.data.node.state !== "WAITING") {
        this.resetStateShow = true;
        if (this.menuData.data.node.type == "ftpReceiverService") {
          this.resetStateShow = false;
        }
      } else {
        this.resetStateShow = false;
      }
    }
  },
  methods: {
    batchConnect(e) {
      this.$emit("FrameSelectionBatchConnectClick", e);
    },
    deleteFrameSelectionClick() {
      this.$emit("FrameSelectionClick", "删除");
    },
    copyClick(type) {
      if (type == "node") {
        this.$emit("FrameSelectionClick", "复制", null, type, this.nodeId);
      } else {
        this.$emit("FrameSelectionClick", "复制", null, type);
      }
    },
    composeClick() {
      this.$emit("FrameSelectionClick", "收缩");
    },
    deComposeClick() {
      this.$emit("FrameSelectionClick", "展开", null, null, this.nodeId);
    },
    composeNodeReName() {
      this.$emit("FrameSelectionClick", "重命名", null, null, this.nodeId);
    },
    createTaskClick() {
      this.$emit("FrameSelectionClick", "创建任务");
    },
    pasteClick(e) {
      this.$emit("FrameSelectionClick", "粘贴", e);
    },
    checkAllClick() {
      this.$emit("checkAllClick");
    },
    analysisPriority() {
      this.$emit("analysisPriority");
    },
    // 点击测试
    testClick() {
      let taskType = this.menuData.data.node.type.split("Service")[0];
      this.$emit("setClick", this.nodeId, taskType, null, true);
    },
    addIntermediateNodeClick(e) {
      this.$emit("addIntermediateNode", e, this.menuData.data.from);
    },
    deleteLineClick() {
      this.$emit("deleteElement");
    },
    // 点击删除
    deleteClick() {
      this.$emit("setClick", this.nodeId, "delete_click");
    },
    nodeEnabledChange(after) {
      this.$emit("nodeEnabledChange", this.nodeId, after);
    },
    lineClick() {
      this.$emit("setClick", null, null, this.menuData.data);
    },
    resetStateAllClick() {
      this.$emit("FrameSelectionClick", "重置状态");
    },
    resetStateClick() {
      this.$emit("setClick", this.nodeId, "resetState_click");
    },
    selectSubsequentNodes() {
      this.$emit("setClick", this.nodeId, "selectSubsequentNodes");
    },
    updateRemark() {
      this.$emit("setClick", this.nodeId, "updateRemark");
    },
    notifyChange() {
      this.$emit("setClick", this.nodeId, "notifyChange");
    },
    // 参数设置
    dataClick() {
      let taskType = this.menuData.data.node.type.split("Service")[0];
      this.$emit("setClick", this.nodeId, taskType);
    },
    processClick() {
      let arr = this.data.nodeList.filter((node) => {
        return node.id == this.nodeId;
      });

      if (arr.length > 0) {
        this.$router.push({
          path: "/panel",
          query: {
            id: this.getMenuByNodeId(arr[0].nodeId).sourceId,
          },
        });
      }
    },
    getMenuByNodeId(nodeId) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].id === nodeId) {
            return children[j];
          }
        }
      }
    },
    getCustomerNodeProcessTask() {
      let data = {
        taskId: this.taskId,
        nodeId: this.nodeId,
      };
      this.$http
        .get("/pipelineTask/getCustomerNodeProcessTask", data)
        .then((res) => {
          if (res.result == 200) {
            if (res.data.taskId == -1) {
              this.$message.error("查看失败");
            } else {
              this.$router.push({
                path: "/panel",
                query: {
                  id: res.data.taskId,
                },
              });
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 右击框 */
.right_menu {
  width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1px solid #e9edf2;
  z-index: 1000;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 13px;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      padding: 0 4px;
      position: relative;
      margin-bottom: 2px;

      span {
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        cursor: pointer;
        padding-left: 36px;
        padding-right: 8px;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        i {
          position: absolute;
          left: 8px;
          font-size: 18px;
        }
        &:hover {
          background-color: #f3f5f9;
        }
      }
      &:hover {
        .sub_right_menu {
          display: block;
        }
      }
      .sub_right_menu {
        display: none;
        position: absolute;
        left: 180px;
        top: 0px;
      }
    }
  }
}
</style>
