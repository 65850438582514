<template>
  <div class="pipelineList">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>我的收藏：</span>
        <a-checkbox v-model="myCollect" @change="query(1)"></a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus"
          >新增{{
            type == "single_task_form" ? "单" : "批量"
          }}任务表单</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="relationTemplateName" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 跳转 </span>
          <a-button type="link" @click="jumpPipelineList(record)">
            {{ record.relationTemplateName }}
          </a-button>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="addTask_pipeline(record)"
          >创建任务</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="edit_form(record.id)">修改表单</a-button>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button type="link" size="small" @click="collect(record)">{{
                record.collect ? "取消收藏" : "收藏"
              }}</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button type="link" size="small" @click="edit_pipeline(record)">
                修改
              </a-button>
            </a-menu-item>
            <a-menu-item v-if="type == 'multi_task_form'">
              <a-button type="link" size="small" @click="setConcurrent(record)"
                >设置并发数</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <!-- 创建任务 -->
    <a-modal
      :maskClosable="false"
      :title="addTaskTitle"
      v-model="addTaskShow"
      width="600px"
      :afterClose="addTaskClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item
          :required="formItem.required"
          v-for="(formItem, index) in addTaskList"
          :key="formItem.id"
        >
          <span slot="label">
            {{ formItem.label }}
            <a-tooltip placement="top" v-if="formItem.tips">
              <div slot="title">
                {{ formItem.tips }}
              </div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px; margin-left: 5px"
              />
            </a-tooltip>
          </span>
          <a-input
            v-if="formItem.componentType == 'INPUT'"
            v-model.trim="form[index].value"
            :placeholder="
              '请输入' +
              formItem.label +
              (formItem.variableFormat
                ? '(' + formItem.variableFormat + ')'
                : '')
            "
            style="width: 80%"
            allowClear
            :disabled="!formItem.editable"
          />
          <a-textarea
            v-if="formItem.componentType == 'TEXT_SCOPE'"
            v-model="form[index].value"
            :placeholder="
              '请输入' +
              formItem.label +
              (formItem.variableFormat
                ? '(' + formItem.variableFormat + ')'
                : '')
            "
            style="width: 80%"
            allowClear
            :rows="5"
            :disabled="!formItem.editable"
          />
          <a-select
            v-model="form[index].value"
            v-if="
              formItem.componentType == 'SELECT' ||
              formItem.componentType == 'DB_SELECT' ||
              formItem.componentType == 'DB_MULTIPLE_SELECT'
            "
            :placeholder="'请选择' + formItem.label"
            :disabled="!formItem.editable"
            showSearch
            :mode="
              formItem.componentType == 'DB_MULTIPLE_SELECT'
                ? 'multiple'
                : 'default'
            "
          >
            <a-select-option
              v-for="item in formItem.formOptionList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-upload-dragger
            v-if="formItem.componentType == 'FILE'"
            :fileList="fileData[formItem.id]"
            :customRequest="(info) => customRequest(info, formItem.id)"
            :remove="(file) => handleRemove(file, formItem.id)"
            :beforeUpload="
              (files, fileList) => beforeUpload(files, fileList, formItem.id)
            "
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
          <a-date-picker
            v-if="formItem.componentType == 'DATE_PICKER'"
            v-model="form[index].value"
            :placeholder="'请选择' + formItem.label"
            :disabled="!formItem.editable"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
          />
          <a-date-picker
            v-if="formItem.componentType == 'DATE_TIME_PICKER'"
            v-model="form[index].value"
            showTime
            :placeholder="'请选择' + formItem.label"
            :disabled="!formItem.editable"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            :picker-options="pickerOptions"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="addTask_submit" :loading="loading"
            >确定</a-button
          >
          <a-button @click="addTaskShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="editTitle"
      v-model="addFormShow"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:" required>
          <a-input
            v-model.trim="nameAdd"
            placeholder="请输入名称"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="关联模板:" required>
          <a-select
            v-model="relationTemplateIdAdd"
            placeholder="请选择关联模板"
            showSearch
            :filterOption="false"
            @search="getPipelineList"
          >
            <a-select-option
              v-for="item in pipelineList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-input
            v-model.trim="remarkAdd"
            placeholder="请输入备注"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="addForm_submit" :loading="loading"
            >确定</a-button
          >
          <a-button @click="addFormShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="修改表单"
      v-if="editFormShow"
      v-model="editFormShow"
      width="59.5%"
      :footer="null"
    >
      <formOptionList :formId="formId" :type="type" />
    </a-modal>

    <a-modal
      width="500px"
      title="设置并发数"
      v-model="setConcurrentShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="并发数:">
          <a-switch
            checked-children="限制"
            un-checked-children="不限制"
            v-model="maxConcurrentShow"
          />
          <a-input
            v-if="maxConcurrentShow"
            v-model.trim="maxConcurrent"
            placeholder="请输入并发数"
            allowClear
            style="width: 200px; margin-left: 10px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setConcurrentShow = false">取 消</a-button>
          <a-button type="primary" @click="setConcurrentSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import formOptionList from "./formOptionList.vue";

export default {
  name: "formTask",
  components: { formOptionList },
  props: ["type"],
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "关联模板",
          ellipsis: true,
          dataIndex: "relationTemplateName",
          scopedSlots: { customRender: "relationTemplateName" },
          width: 200,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 240,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      pipelineId: "",
      addTaskTitle: "",
      addTaskShow: false,
      name: "",
      myCollect: false,
      fileData: {},
      addTaskList: [],
      form: [],
      formId: "",
      pipelineList: [],
      relationTemplateIdAdd: undefined,
      nameAdd: "",
      remarkAdd: "",
      addFormShow: false,
      loading: false,
      editTitle: "",
      editFormShow: false,
      maxConcurrent: "",
      maxConcurrentShow: false,
      setConcurrentShow: false,
    };
  },
  watch: {
    myCollect: {
      handler(newVal) {
        localStorage.setItem("formTaskMyCollect", newVal);
      },
    },
  },
  created() {
    if (
      localStorage.getItem("formTaskMyCollect") &&
      localStorage.getItem("formTaskMyCollect") == "true"
    ) {
      this.myCollect = true;
    } else {
      this.myCollect = false;
    }
    this.query(1);
    this.getPipelineList();
  },
  methods: {
    edit_form(formId) {
      this.editFormShow = true;
      this.formId = formId;
    },
    add() {
      this.editTitle = "新增表单";
      this.relationTemplateIdAdd = undefined;
      this.nameAdd = "";
      this.remarkAdd = "";
      this.addFormShow = true;
    },
    edit_pipeline(val) {
      this.formId = val.id;
      this.editTitle = "修改模板";
      this.relationTemplateIdAdd = val.relationTemplateId;
      this.nameAdd = val.name;
      this.remarkAdd = val.remark;
      this.addFormShow = true;
    },
    addForm_submit() {
      this.loading = true;
      let data = {
        name: this.nameAdd,
        remark: this.remarkAdd,
        relationTemplateId: this.relationTemplateIdAdd,
      };
      if (this.editTitle == "新增表单") {
        data.type = this.type;
        this.$http
          .json_post("/pipelineForm/addForm", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("添加成功");
              this.addFormShow = false;
              this.loading = false;
              this.query();
            }
          })
          .catch(() => {
            this.addFormShow = false;
            this.loading = false;
          });
      } else {
        data.formId = this.formId;
        this.$http
          .json_post("/pipelineForm/updatePipelineTemplate", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.addFormShow = false;
              this.loading = false;
              this.query();
            }
          })
          .catch(() => {
            this.addFormShow = false;
            this.loading = false;
          });
      }
    },
    setConcurrent(val) {
      this.formId = val.id;
      this.maxConcurrentShow = val.maxConcurrent !== -1;
      this.maxConcurrent = val.maxConcurrent;
      this.setConcurrentShow = true;
    },
    setConcurrentSubmit() {
      let data = {
        id: this.formId,
        maxConcurrent: this.maxConcurrent,
      };
      if (!this.maxConcurrentShow) {
        data.maxConcurrent = -1;
      }
      this.$http
        .json_post("/pipelineForm/updateConcurrent", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setConcurrentShow = false;
            this.query();
          }
        });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineForm/delete", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    getPipelineList(name) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        name: name,
        pipelineTaskType: "TEMPLATE",
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          this.pipelineList.splice(0);
          this.pipelineList.push(...res.data.records);
        }
      });
    },
    jumpPipelineList(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.relationTemplateId },
      });
    },
    customRequest(info, id) {
      this.loading = true;
      this.$http
        .files("/uploadTmpFile", {
          file: info.file,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("上传成功");
            this.form.map((item, index) => {
              if (item.componentId == id) {
                this.$set(this.form[index], "value", res.data);
              }
            });
            this.loading = false;
          }
        });
    },
    beforeUpload(files, fileList, id) {
      if (this.fileData[id].length > 0) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${
            fileList.length
          } 个文件，共选择了 ${
            this.fileData[id].length + fileList.length
          } 个文件`
        );
        return false;
      } else {
        this.fileData[id] = fileList;
        return true;
      }
    },
    handleRemove(file, id) {
      const index = this.fileData[id].indexOf(file);
      const newFileList = this.fileData[id].slice();
      newFileList.splice(index, 1);
      this.fileData[id] = newFileList;
      this.$message.success("删除成功");
    },
    collect(val) {
      let data = {
        sourceId: val.id,
        collect: !val.collect,
      };
      this.$http.json_post("/pipelineForm/collectForm", data).then((res) => {
        if (res.result === 200) {
          this.$message.success((data.collect ? "" : "取消") + "收藏成功");
          this.query();
        }
      });
    },
    // 创建任务
    addTask_pipeline(val) {
      this.formId = val.id;
      this.addTaskList.splice(0);
      this.form.splice(0);
      this.fileData = {};
      this.$http
        .get("/pipelineForm/listAllComponent", { formId: val.id })
        .then((res) => {
          if (res.result === 200) {
            this.addTaskList.push(...res.data);
            res.data.map((item) => {
              this.form.push({
                componentId: item.id,
                value:
                  item.componentType == "DB_MULTIPLE_SELECT"
                    ? item.fillValue || []
                    : item.fillValue || "",
                required: item.required,
              });

              if (item.componentType == "FILE") {
                this.$set(this.fileData, item.id, []);
              }
            });
            this.addTaskTitle = "创建任务" + val.name;
            this.addTaskShow = true;
          }
        });
    },
    // 确定创建任务
    addTask_submit() {
      this.loading = true;
      // 过滤数组中的非必选项
      let formFilter = this.form.filter((item) => {
        return item.required;
      });
      // 校验数组中必填项是否填写
      let flag = formFilter.every((item) => {
        return item.value !== "";
      });
      let itemList = JSON.parse(JSON.stringify(this.form));
      // 删除后台不需要的required属性
      itemList.forEach((item) => {
        this.$delete(item, "required");
        this.addTaskList.forEach((formItem) => {
          if (
            formItem.componentType == "DB_MULTIPLE_SELECT" &&
            item.componentId == formItem.id
          ) {
            item.value = item.value.join(",");
          }
        });
      });
      if (flag) {
        let data = {
          formId: this.formId,
          itemList,
        };
        this.$http
          .json_post("/pipelineForm/createTaskFromForm", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("创建成功");
              this.addTaskShow = false;
              this.loading = false;
              this.$router.push("/tabTaskList/dataRecord");
            }
          })
          .catch(() => {
            this.addTaskShow = false;
            this.loading = false;
          });
      } else {
        this.$message.error("请填写必填项");
        this.loading = false;
      }
    },
    // 关闭创建任务
    addTaskClose() {
      this.form.forEach((item) => {
        this.$set(item, "value", "");
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        myCollect: this.myCollect,
        type: this.type,
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http.get("/pipelineForm/list", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
<style scoped>
.dropbox-ts {
  font-size: 12px;
  color: #999;
  display: block;
  line-height: 16px;
}
</style>
