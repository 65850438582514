<template>
  <div class="muchTask">
    <a-tabs v-model="activeIndex" @change="tabChange">
      <a-tab-pane tab="文件上传" key="文件上传">
        <div class="condition">
          <div class="taskName">
            任务名称 <i style="margin-left: 6px"></i>：
            <a-input v-model="condition.taskName" placeholder="请输入内容" />
          </div>
          <div class="taskType">
            任务类型 <i style="margin-left: 6px"></i>：
            <el-cascader
              style="width: 360px"
              :options="taskList"
              :props="optionProps"
              v-model="condition.taskType"
              :show-all-levels="false"
              filterable
            >
            </el-cascader>
          </div>
          <div class="remindNum">
            返回原文 <i style="margin-left: 6px"></i>：
            <a-radio-group v-model="condition.needInputString">
              <a-radio value="true">是</a-radio>
              <a-radio value="false">否</a-radio>
            </a-radio-group>
          </div>
          <span>文件上传：</span>
          <a-upload-dragger
            class="upload-demo"
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
          <div class="sign" style="height: 40px; line-height: 40px">
            延迟执行 <i style="margin-left: 6px"></i>：
            <a-checkbox v-model="checked" @change="checkboxChange"></a-checkbox>
            <a-date-picker
              style="margin-left: 20px"
              v-if="checked"
              v-model="condition.executeDate"
              showTime
              placeholder="选择日期时间"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss"
            />
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane tab="手动上传" key="手动上传">
        <div class="taskType">
          任务类型 <i style="margin-left: 6px"></i>：
          <el-cascader
            style="width: 360px"
            :options="taskList"
            :props="optionProps"
            v-model="condition.taskType"
            :show-all-levels="false"
            filterable
          >
          </el-cascader>
        </div>
        <div class="remindNum">
          返回原文 <i style="margin-left: 6px"></i>：
          <a-radio-group v-model="condition.needInputString">
            <a-radio value="true">是</a-radio>
            <a-radio value="false">否</a-radio>
          </a-radio-group>
        </div>
        <span>文件上传：</span>
        <div class="hand-upload">
          <a-textarea
            placeholder="请手动输入文字"
            v-model="uploadContent"
            :spellcheck="false"
            resize="none"
          />
          <i></i>
          <a-textarea
            placeholder="提交结果反馈"
            v-model="submitResult"
            :spellcheck="false"
            resize="none"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane tab="任务类型" key="任务类型">
        <div class="searchCondition">
          <div class="searchConditionItem">
            <span>分组：</span>
            <a-select
              v-model="parentId"
              placeholder="请选择分组"
              @change="getTaskTypeList()"
            >
              <a-select-option
                v-for="item in groupList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.cn }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchConditionItem">
            <span>英文名称：</span>
            <a-input
              v-model.trim="en"
              placeholder="请输入英文名称"
              style="width: 230px"
              allowClear
              @change="allowClearChange"
              @pressEnter="getTaskTypeList(1)"
            />
          </div>
          <div class="searchConditionItem">
            <span>中文名称：</span>
            <a-input
              v-model.trim="cn"
              placeholder="请输入中文名称"
              style="width: 230px"
              allowClear
              @change="allowClearChange"
              @pressEnter="getTaskTypeList(1)"
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="getTaskTypeList(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addTaskType" icon="plus"
              >新增任务类型</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableData"
          :pagination="tablePagination"
          size="small"
          :rowKey="(record) => record.id"
          :loading="loadingTable"
        >
          <span slot="speedMap" slot-scope="text, record">
            <a-tooltip placement="left">
              <template slot="title">
                最近一分钟:{{ record.speedMap.speed1 }}<br />
                最近五分钟:{{ record.speedMap.speed5 }}<br />
                最近十分钟:{{ record.speedMap.speed10 }}
              </template>
              {{ record.speedMap.speed1 }} | {{ record.speedMap.speed5 }} |
              {{ record.speedMap.speed10 }}
            </a-tooltip>
          </span>
          <span slot="enable" slot-scope="text, record">
            <a-switch
              checked-children="已启用"
              un-checked-children="已禁用"
              v-model="record.enable"
              @change="enableChange(record)"
            />
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="editTaskType(record)">修改</a-button>
            <a-divider type="vertical" />
            <a-popconfirm
              placement="left"
              okText="确认"
              cancelText="取消"
              @confirm="deleteTaskType(record)"
            >
              <template slot="title">
                是否删除【{{ record.name }}】任务类型
              </template>
              <a href="javascript:;" style="color: #ff4d4f">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <div class="content" v-if="activeIndex !== '任务类型'">
      <a-button type="primary" @click="submit()">提 交</a-button>
      <a-button @click="reset()" icon="reload">重 置</a-button>
    </div>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="editTaskTypeShow"
      width="700px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="分组:">
          <a-select v-model="parentIdAdd" placeholder="请选择分组">
            <a-select-option
              v-for="item in groupList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.cn }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="英文名称:">
          <a-input
            v-model="enAdd"
            placeholder="请输入英文名称"
            style="width: 240px"
            allowClear
          />
        </a-form-item>
        <a-form-item label="中文名称:">
          <a-input
            v-model="cnAdd"
            placeholder="请输入中文名称"
            style="width: 240px"
            allowClear
          />
        </a-form-item>
        <a-form-item label="是否在手动提交显示:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="manual"
          />
        </a-form-item>
        <a-form-item label="审核状态:">
          <a-switch
            checked-children="需审核"
            un-checked-children="无需审核"
            v-model="verifiable"
          />
        </a-form-item>
        <a-form-item label="是否在pipeline里面显示:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="showInPipeline"
          />
        </a-form-item>
        <template v-if="parentName == '撞库'">
          <a-form-item label="脏数据文件名称:">
            <a-select
              v-if="fileSelectShow"
              v-model="dirtyDataFile"
              placeholder="请选择文件"
              showSearch
              allowClear
              :filterOption="false"
              @search="searchFileList"
              @blur="searchFileList()"
              style="width: 94%"
            >
              <a-select-option
                v-for="item in pipelineSourceFileList"
                :key="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-input
              v-else
              v-model="dirtyDataFile"
              placeholder="请输入脏数据文件名称"
              allowClear
              style="width: 94%"
            />

            <a-tooltip placement="left">
              <div slot="title">
                {{ fileSelectShow ? "手动输入" : "选择文件" }}
              </div>
              <a-checkbox v-model="fileSelectShow" style="margin-left: 5px" />
            </a-tooltip>
          </a-form-item>
          <a-form-item label="脏数据系数:">
            <a-input
              v-model="dirtyDataPert"
              placeholder="请输入脏数据系数"
              allowClear
            />
          </a-form-item>
          <a-form-item label="速度控制:">
            <a-switch v-model="speedControl" />
            <template v-if="speedControl">
              <a-input
                style="margin-left: 10px; width: 80px"
                v-model="speed.speedSecond"
              />
              秒
              <a-input
                style="margin-left: 10px; width: 80px"
                v-model="speed.speedCount"
              />
              条
            </template>
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            @click="editTaskType_submit()"
            style="width: 80px"
            >确 定</a-button
          >
          <a-button @click="editTaskTypeShow = false" style="width: 80px"
            >取 消</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "MuchTask",
  data() {
    return {
      optionProps: {
        value: "en",
        label: "cn",
        children: "list",
        emitPath: false,
      },
      msg: "批量任务",
      taskList: [],
      taskList1: [],
      loading: false,
      condition: {
        repeat: "",
        interval: "",
        taskName: "",
        taskType: "",
        needInputString: "false",
        executeType: "NO_DELAY",
        executeDate: "",
      },
      fileList: [],
      activeIndex: "文件上传",
      uploadContent: "",
      submitResult: "",
      cookie: true,
      checked: false,
      typeName: "",
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 60,
        },
        {
          title: "中文名称",
          ellipsis: true,
          dataIndex: "cn",
          width: 200,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "en",
          width: 200,
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "parentName",
          width: 150,
        },
        {
          title: "api",
          ellipsis: true,
          dataIndex: "code",
          width: 150,
        },
        {
          title: "速度",
          ellipsis: true,
          dataIndex: "speedMap",
          width: 150,
          scopedSlots: { customRender: "speedMap" },
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enable",
          scopedSlots: { customRender: "enable" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      en: "",
      cn: "",
      groupList: [],
      parentId: undefined,
      editTaskTypeShow: false,
      title: "",
      parentIdAdd: undefined,
      enAdd: "",
      cnAdd: "",
      manual: true,
      verifiable: true,
      showInPipeline: true,
      dirtyDataFile: "",
      dirtyDataPert: "",
      speedControl: true,
      speed: { speedCount: "", speedSecond: "" },
      taskTypeId: "",
      searchTimer: "",
      fileSelectShow: false,
      pipelineSourceFileList: [],
    };
  },
  watch: {
    activeIndex: {
      handler(newVal, oldVal) {
        localStorage.setItem("activeIndex", newVal);
      },
    },
    "condition.taskType": {
      immediate: true,
      handler(newVal) {
        this.taskList.forEach((menu) => {
          menu.list.forEach((item) => {
            if (item.en == newVal) {
              this.typeName = item.cn;
            }
          });
        });
      },
    },
  },
  computed: {
    parentName() {
      let parentName = "";
      this.groupList.forEach((item) => {
        if (item.id == this.parentIdAdd) {
          parentName = item.cn;
        }
      });
      return parentName;
    },
  },
  created() {
    if (localStorage.getItem("activeIndex")) {
      this.activeIndex = localStorage.getItem("activeIndex");
    }
  },
  mounted() {
    this.get_taskList();
    this.getGroupList();
  },
  methods: {
    searchFileList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getFileList(name);
      }, 300);
    },
    getFileList(name) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        name,
      };
      this.$http
        .get("/pipelineSourceFile/pipelineSourceFilePage", data)
        .then((res) => {
          if (res.result == 200) {
            this.pipelineSourceFileList = res.data.records;
          }
        });
    },
    enableChange(val, enable) {
      this.loading = true;
      let data = {
        id: val.id,
        enable: val.enable,
      };
      this.$http.json_post("/taskType/enableTaskType", data).then((res) => {
        if (res.result === 200) {
          if (enable) {
            this.$message.success("启用成功");
          } else {
            this.$message.success("禁用成功");
          }
          this.getTaskTypeList();
          this.loading = false;
        }
      });
    },
    addTaskType(val) {
      this.title = "新增任务类型";
      this.parentIdAdd = undefined;
      this.enAdd = "";
      this.cnAdd = "";
      this.manual = true;
      this.verifiable = true;
      this.showInPipeline = true;
      this.dirtyDataFile = "";
      this.dirtyDataPert = "";
      this.speedControl = true;
      this.speed = { speedCount: "", speedSecond: "" };
      this.editTaskTypeShow = true;
    },
    editTaskType(val) {
      this.title = "修改任务类型";
      this.getFileList();
      this.fileSelectShow = false;
      this.taskTypeId = val.id;
      this.parentIdAdd = val.parentId;
      this.enAdd = val.en;
      this.cnAdd = val.cn;
      this.manual = val.manual;
      this.verifiable = val.verifiable;
      this.showInPipeline = val.showInPipeline;
      this.dirtyDataFile = val.dirtyDataFile;
      this.dirtyDataPert = val.dirtyDataPert;
      this.speedControl = val.speedControl;
      if (val.speed) {
        this.speed = JSON.parse(val.speed);
      } else {
        this.speed = { speedCount: "", speedSecond: "" };
      }
      this.editTaskTypeShow = true;
    },
    editTaskType_submit() {
      let data = {
        parentId: this.parentIdAdd,
        en: this.enAdd,
        cn: this.cnAdd,
        manual: this.manual,
        verifiable: this.verifiable,
        showInPipeline: this.showInPipeline,
      };
      if (this.parentName == "撞库") {
        data.dirtyDataFile = this.dirtyDataFile;
        data.dirtyDataPert = this.dirtyDataPert;
        data.speedControl = this.speedControl;
        if (this.speedControl) {
          data.speed = JSON.stringify(this.speed);
        }
      }
      if (this.title == "新增任务类型") {
        this.$http.json_post("/taskType/addTaskType", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.editTaskTypeShow = false;
            this.getTaskTypeList(1);
          }
        });
      } else {
        data.id = this.taskTypeId;
        this.$http.json_post("/taskType/editTaskType", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.editTaskTypeShow = false;
            this.getTaskTypeList();
          }
        });
      }
    },
    deleteTaskType(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/taskType/deleteTaskType", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.getTaskTypeList();
        }
      });
    },
    pageChange(index) {
      this.getTaskTypeList(index);
    },
    getGroupList() {
      this.$http.get("/taskType/groupList").then((res) => {
        if (res.result === 200) {
          this.groupList = res.data;
          if (this.activeIndex == "任务类型") {
            this.getTaskTypeList(1);
          }
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.getTaskTypeList(1);
    },
    getTaskTypeList(index) {
      this.loadingTable = true;
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        parentId: this.parentId,
        en: this.en,
        cn: this.cn,
      };
      this.$http.get("/taskType/taskTypePage", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          let typeList = [];
          this.tableData.forEach((item) => {
            let speedMap = { speed1: 0, speed5: 0, speed10: 0 };
            let speedMapJson = sessionStorage.getItem(item.en + "speedMap");
            if (speedMapJson) {
              speedMap = JSON.parse(speedMapJson);
            }
            this.$set(item, "speedMap", speedMap);
            this.groupList.forEach((group) => {
              if (item.parentId == group.id) {
                this.$set(item, "parentName", group.cn);
              }
            });
            typeList.push(item.en);
          });
          let types = typeList.join(",");
          this.getDumpSpeed(types);
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.pageChange(current),
          };
          this.loadingTable = false;
        }
      });
    },
    getDumpSpeed(types) {
      this.$http.get("/taskType/dumpSpeed", { types }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.tableData.forEach((item) => {
            list.forEach((speedItem) => {
              if (item.en == speedItem.type) {
                this.$set(item, "speedMap", speedItem.countMap);
                sessionStorage.setItem(
                  speedItem.type + "speedMap",
                  JSON.stringify(speedItem.countMap)
                );
              }
            });
          });
        }
      });
    },
    // 立即执行复选框
    checkboxChange(val) {
      if (val) {
        // this.data.executeDate1 = new Date();
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        var h = date.getHours();
        h = h < 10 ? "0" + h : h;
        var min = date.getMinutes();
        min = min < 10 ? "0" + min : min;
        var s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        this.condition.executeDate =
          y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
        this.condition.executeType = "DELAY";
      } else {
        this.condition.executeDate = "";
        this.condition.executeType = "NO_DELAY";
      }
    },
    get_taskList(type) {
      //获取任务列表
      this.$http.get("/job/type").then((res) => {
        if (res.result == 200) {
          let list = res.data;
          if (type === "手动上传") {
            for (let i = list.length - 1; i >= 0; i--) {
              if (!list[i].manual) {
                list.splice(i, 1);
              }
            }
          }
          this.taskList = list;
          this.condition.taskType = list[0].list[0].en;
        }
      });
    },
    tabChange() {
      //tab切换
      if (this.activeIndex === "文件上传") {
        this.uploadContent = "";
        this.submitResult = "";
        this.get_taskList();
      } else if (this.activeIndex === "手动上传") {
        this.get_taskList("手动上传");
      } else {
        this.getTaskTypeList(1);
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.condition.taskName = "";
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      if (!this.condition.taskName) {
        console.log(file);
        // 任务名称为空且没有上传时给任务名称赋值
        let index = file.name.lastIndexOf(".");
        this.condition.taskName = file.name.substring(0, index);
      }
      return false;
    },
    submit() {
      // 提交
      switch (this.activeIndex) {
        case "文件上传":
          let taskName = this.condition.taskName.trim(),
            taskType = this.condition.taskType.trim();
          if (taskName && taskType) {
            let data = {
              taskName: taskName,
              type: taskType,
              executeType: this.condition.executeType,
              executeDate: this.condition.executeDate,
              needInputString: this.condition.needInputString,
              repeat: this.condition.repeat,
              interval: this.condition.interval,
              typeName: this.typeName,
              file: this.fileList[0],
            };
            this.$http.files("/job/add/persistent", data).then((res) => {
              if (res.result === 200) {
                this.uploadContent = "";
                this.submitResult = "";
                this.condition.taskName = "";
                this.condition.confusionTaskId = "";
                this.condition.executeType = "NO_DELAY";
                this.condition.executeDate = "";
                this.checked = false;
                this.isSuccessUpload = true;
                this.$message.success("文件上传成功！");
              }
            });
          } else {
            this.$message.warning("信息填写不完整！");
          }
          break;
        case "手动上传":
          let content = this.uploadContent.replace(/\n/g, ","),
            data = {
              inputString: content,
              type: this.condition.taskType,
              needInputString: this.condition.needInputString,
            };
          this.$http.post("/job/add/temporary", data).then((res) => {
            if (res.result == 200) {
              let str = "";
              res.data.map((s) => {
                str += s + "\n";
              });
              this.submitResult = str;
              this.$message.success("文件上传成功！");
            }
          });
          break;
      }
    },
    reset() {
      this.uploadContent = "";
      this.submitResult = "";
      this.condition = {
        repeat: "",
        interval: "",
        taskName: "",
        executeDate: "",
        executeType: "NO_DELAY",
        needInputString: "false",
      };
      this.checked = false;
      this.condition.taskType = this.taskList[0].en;
    },
  },
};
</script>

<style scoped="scoped">
.muchTask {
  height: 800px;
  margin-bottom: 30px;
}
.content {
  margin: 25px 0 0 15px;
}
.taskName,
.taskType,
.taskSet,
.remindNum,
.sign {
  width: 600px;
  margin-bottom: 25px;
}

.taskName .ant-input,
.remindNum .ant-input,
.sign .ant-input,
.taskType .ant-select {
  width: 360px;
}

/* 文件上传 */
.upload-demo {
  width: 500px;
  display: block;
  margin: 30px 0 50px;
}
.hand-upload .ant-input:nth-of-type(1) {
  height: 300px;
  width: 400px;
}
.hand-upload .ant-input:nth-of-type(2) {
  height: 300px;
  width: 600px;
}
/* 手动上传 */
.hand-upload {
  margin: 30px 0 50px;
}
.hand-upload i {
  margin: 0 30px 0 20px;
  display: inline-block;
  width: 60px;
  height: 5px;
  background: lightgray;
  vertical-align: 750%;
  position: relative;
}
.hand-upload i::after {
  display: inline-block;
  content: "";
  border: 10px solid;
  border-color: transparent transparent transparent lightgray;
  position: absolute;
  top: -8px;
  right: -20px;
}
.content button {
  width: 120px;
  margin-right: 20px;
}
</style>
