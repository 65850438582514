<template>
  <div class="timedTask">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>节点类型：</span>
        <a-input
          v-model.trim="type"
          placeholder="请输入节点类型"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>节点名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入节点名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>分组：</span>
        <a-select v-model="group" placeholder="请选择" allowClear>
          <a-select-option
            v-for="(item, index) in groupList"
            :key="index"
            :value="item.group"
          >
            {{ item.group }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add()" icon="plus">新增节点</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="icon" slot-scope="text, record">
        <img :src="record.icon" alt="" style="width: 25px; height: 25px" />
      </span>
      <span slot="starterNode" slot-scope="text, record">
        <a-tag v-if="text" color="green"> 是 </a-tag>
        <a-tag v-if="!text" color="red"> 否 </a-tag>
      </span>
      <span slot="minInputCount" slot-scope="text, record">
        [{{ record.minInputCount }}:{{ record.maxInputCount }}]
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="update_node(record)">修改</a-button>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="dialogVisible"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="节点名称:">
          <a-input
            v-model="nameAdd"
            placeholder="请输入节点名称"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="节点类型:">
          <a-input
            v-model="typeAdd"
            placeholder="请输入节点类型"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="分组:">
          <a-select v-model="groupAdd" placeholder="请选择" allowClear>
            <a-select-option
              v-for="(item, index) in groupList"
              :key="index"
              :value="item.group"
            >
              {{ item.group }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="输入文件类型:">
          <a-select
            v-model="inFileType"
            placeholder="请选择"
            mode="multiple"
            allowClear
          >
            <a-select-option
              v-for="item in filetypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="输出文件类型:">
          <a-select
            v-model="outFileType"
            placeholder="请选择"
            mode="multiple"
            allowClear
          >
            <a-select-option
              v-for="item in filetypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="输入节点数:">
          <a-input
            v-model="minInputCount"
            placeholder="最小节点数"
            style="width: 30%"
          />:
          <a-input
            v-model="maxInputCount"
            placeholder="最大节点数"
            style="width: 30%"
          />
        </a-form-item>
        <a-form-item label="适用类型:">
          <a-input
            v-model="availableType"
            placeholder="请输入适用类型"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="搜索关键词:">
          <a-textarea
            v-model="tag"
            placeholder="请输入搜索关键词"
            style="width: 80%"
            :rows="5"
          />
        </a-form-item>
        <a-form-item label="图标:">
          <a-select
            v-model="icon"
            placeholder="请选择图标"
            showSearch
            :filterOption="false"
            @search="queryImageList"
            style="width: 201px"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            dropdownClassName="iconSelect"
          >
            <a-select-option
              v-for="(item, index) in imageList"
              :key="index"
              :value="item.imageUrl"
            >
              <img :src="item.imageUrl" style="width: 20px; height: 20px" />
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            :rows="4"
            placeholder="请输入备注"
            style="width: 80%"
            v-model="remark"
          />
        </a-form-item>
        <a-form-item label="起始节点:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="starterNode"
          />
          需要审核:
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="needAudit"
          />
        </a-form-item>
        <a-form-item label="动态计算百分比:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="dynamicUpdateProgress"
          />
          可测试:
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="testable"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="update_submit">确定</a-button>
          <a-button @click="dialogVisible = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "nodeList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 60,
        },
        {
          title: "节点类型",
          ellipsis: true,
          dataIndex: "type",
          width: 200,
        },
        {
          title: "节点名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "图标",
          ellipsis: true,
          dataIndex: "icon",
          scopedSlots: { customRender: "icon" },
          align: "center",
          width: 50,
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "group",
          align: "center",
          width: 120,
        },
        {
          title: "起始节点",
          ellipsis: true,
          dataIndex: "starterNode",
          scopedSlots: { customRender: "starterNode" },
          align: "center",
          width: 80,
        },
        {
          title: "输入节点数",
          ellipsis: true,
          dataIndex: "minInputCount",
          scopedSlots: { customRender: "minInputCount" },
          align: "center",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      total: 0,
      id: "",
      type: "",
      dialogVisible: false,
      name: "",
      nameAdd: "",
      typeAdd: "",
      starterNode: undefined,
      needAudit: undefined,
      dynamicUpdateProgress: undefined,
      minInputCount: 1,
      maxInputCount: 1,
      remark: "",
      testable: undefined,
      title: "",
      availableType: "",
      tag: "",
      imageList: [],
      icon: "",
      groupList: [],
      group: undefined,
      groupAdd: undefined,
      filetypeList: [
        { id: 1, value: "excel" },
        { id: 2, value: "csv" },
        { id: 3, value: "text" },
        { id: 4, value: "zip" },
      ],
      inFileType: [],
      outFileType: [],
      iconShow: true,
      watchIcon: undefined,
      loading: false,
    };
  },
  mounted() {
    this.query(1);
    this.queryImageList();
    this.getGroupList();
  },
  methods: {
    iconChange() {
      this.icon = undefined;
      this.iconShow = false;
      this.$nextTick(() => {
        this.$refs.iconSelect.focus();
      });
    },
    getGroupList() {
      this.$http.get("/pipelineNodeInfo/groupList").then((res) => {
        if (res.result === 200) {
          this.groupList = res.data;
        }
      });
    },
    focusQueryImageList() {
      this.$nextTick(() => {
        this.iconShow = true;
        if (!this.icon) {
          this.icon = this.watchIcon;
        } else {
          this.watchIcon = this.icon;
        }
      });
      this.queryImageList();
    },
    queryImageList(key) {
      this.imageList.splice(0);
      this.$http
        .get("/pipelineNodeInfo/queryImageList", { key })
        .then((res) => {
          if (res.result === 200) {
            this.imageList.push(...res.data);
          }
        });
    },
    add() {
      this.title = "新增";
      this.iconShow = true;
      this.dialogVisible = true;
      this.nameAdd = "";
      this.id = "";
      this.typeAdd = "";
      this.groupAdd = undefined;
      this.inFileType = ["csv", "text"];
      this.outFileType = ["csv", "text"];
      this.starterNode = undefined;
      this.needAudit = undefined;
      this.dynamicUpdateProgress = undefined;
      this.minInputCount = 1;
      this.maxInputCount = 1;
      this.remark = "";
      this.testable = undefined;
      this.availableType = "";
      this.tag = "";
      this.icon = undefined;
      this.watchIcon = undefined;
    },
    update_submit() {
      let data = {
        name: this.nameAdd,
        type: this.typeAdd,
        group: this.groupAdd,
        inFileType: this.inFileType.join(","),
        outFileType: this.outFileType.join(","),
        starterNode: this.starterNode,
        needAudit: this.needAudit,
        dynamicUpdateProgress: this.dynamicUpdateProgress,
        minInputCount: this.minInputCount,
        maxInputCount: this.maxInputCount,
        remark: this.remark,
        testable: this.testable,
        availableType: this.availableType,
        tag: this.tag,
        icon: this.icon,
      };
      if (this.title == "新增") {
        this.$http
          .json_post("/pipelineNodeInfo/addPipelineNodeInfo", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("添加成功");
              this.dialogVisible = false;
              this.query();
            }
          });
      } else {
        data.id = this.id;
        this.$http
          .json_post("/pipelineNodeInfo/editPipelineNodeInfo", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.query();
            }
          });
      }
    },
    // 修改
    update_node(val) {
      this.title = "修改";
      this.iconShow = true;
      this.dialogVisible = true;
      this.nameAdd = val.name;
      this.id = val.id;
      this.typeAdd = val.type;
      this.groupAdd = val.group;
      if (val.inFileType) {
        this.inFileType = val.inFileType.split(",");
      } else {
        this.inFileType = [];
      }
      if (val.outFileType) {
        this.outFileType = val.outFileType.split(",");
      } else {
        this.outFileType = [];
      }
      this.starterNode = val.starterNode;
      this.needAudit = val.needAudit;
      this.dynamicUpdateProgress = val.dynamicUpdateProgress;
      this.minInputCount = val.minInputCount;
      this.maxInputCount = val.maxInputCount;
      this.remark = val.remark;
      this.testable = val.testable;
      this.availableType = val.availableType;
      this.tag = val.tag;
      this.icon = val.icon;
      this.watchIcon = val.icon;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        type: this.type,
        name: this.name,
        group: this.group,
      };
      this.$http
        .get("/pipelineNodeInfo/pipelineNodeInfoPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
<style>
.iconSelect ul {
  display: flex;
  flex-wrap: wrap;
  width: 201px;
}
</style>
