<template>
  <div class="dataRecord">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>序号：</span>
        <a-input
          v-model.trim="id"
          placeholder="请输入"
          style="width: 90px"
          allowClear
          @change="idChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>任务名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入任务名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>标签：</span>
        <a-input
          v-model.trim="flag"
          placeholder="请输入标签"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>状态：</span>
        <a-select
          v-model="statusSelect"
          allowClear
          placeholder="请选择"
          @change="query(1)"
        >
          <a-select-option
            v-for="item in statusList"
            :key="item.code"
            :value="item.code"
          >
            {{ item.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>提交人：</span>
        <a-select
          v-model="submitter"
          allowClear
          placeholder="请选择"
          @change="query(1)"
          style="width: 150px"
        >
          <a-select-option
            v-for="item in submitterList"
            :key="item.id"
            :value="item.userLoginName"
          >
            {{ item.userName || item.userLoginName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>创建时间：</span>
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 370px"
        ></a-range-picker>
      </div>
      <div class="searchConditionItem">
        <span>我的收藏：</span>
        <a-checkbox v-model="myCollect" @change="query(1)"></a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="newTask" icon="plus"
          >创建任务</a-button
        >
        <a-button
          type="primary"
          @click="queryNodeByTask('TEMPLATE', 1)"
          icon="search"
          >高级搜索</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: tableDataAmountId,
        onSelect: onSelectRow,
        onSelectAll: onSelectAll,
      }"
      childrenColumnName="groupList"
      :expandedRowKeys.sync="expandedRowKeys"
      @expand="expand"
      :loading="loadingTable"
    >
      <span slot="userName" slot-scope="text, record">
        {{ record.userName || record.userLoginName }}
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
        <a-tooltip
          v-if="record.status == 'SCHEDULED'"
          :title="'执行时间:' + record.executeDate"
          placement="top"
        >
          <a-icon type="exclamation-circle" style="color: #d2983c" />
        </a-tooltip>
      </span>
      <span slot="executeSecond" slot-scope="text, record">
        <span v-if="record.status == 'SCHEDULED'">-</span>
        <span v-else>{{ $common.timeConsuming(record.executeSecond) }}</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <template v-if="record.groupId !== 0">
          <a-button type="link" @click="checkPipeline(record)">{{
            record.status == "DRAFT" ? "编辑" : "查看"
          }}</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topLeft"
            v-if="record.status === 'WAITING_AUDIT'"
            okText="通过"
            cancelText="驳回"
            @confirm="auditConfirm(record)"
            @cancel="auditCancel(record)"
          >
            <template slot="title">确认审核？</template>
            <a href="javascript:;">审核</a>
          </a-popconfirm>
          <a-button
            type="link"
            class="text-button"
            @click="up_pipeline(record)"
            v-if="record.status !== 'WAITING_AUDIT'"
            :disabled="record.status !== 'WAITING'"
            >启动任务</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            class="text-button"
            @click="file_download(record)"
            :disabled="
              record.status !== 'SUCCESS' &&
              record.status !== 'ERROR' &&
              record.expired !== true
            "
            >文件下载</a-button
          >
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              其它 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="copyPipelineClick(record)"
                  >复制任务</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="copyTemplatePipelineClick(record)"
                  >复制为模板</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button type="link" size="small" @click="modifyFlag(record)"
                  >修改标签</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="doTaskNow(record)"
                  :disabled="record.status !== 'SCHEDULED'"
                  >立即执行</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="stopTaskNow(record)"
                  :disabled="record.status !== 'RUNNING'"
                  >中断执行</a-button
                >
              </a-menu-item>
              <a-menu-item
                v-if="record.status == 'RUNNING' || record.status == 'PAUSED'"
              >
                <a-button
                  type="link"
                  size="small"
                  @click="pause(record)"
                  v-if="record.status == 'RUNNING'"
                  >暂停</a-button
                >
                <a-button
                  type="link"
                  size="small"
                  @click="resume(record)"
                  v-if="record.status == 'PAUSED'"
                  >恢复</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="cancelPipelineTask(record)"
                  :disabled="record.status !== 'SCHEDULED'"
                  >取消</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-button type="link" size="small" @click="collect(record)">{{
                  record.collect ? "取消收藏" : "收藏"
                }}</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button
                  type="link"
                  size="small"
                  @click="deletePipelineTask(record)"
                  style="color: #ff0000b0"
                  >删除</a-button
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </a-table>
    <div style="margin-top: 20px">
      <a-button
        @click="batchDelete()"
        type="danger"
        :disabled="this.tableDataAmountId.length == 0"
        icon="delete"
        >批量删除</a-button
      >
      <a-button
        @click="batchStart()"
        type="primary"
        style="margin-left: 10px"
        :disabled="batchStartDisable"
        >批量启动</a-button
      >
    </div>

    <a-modal
      :maskClosable="false"
      title="下载文件列表"
      v-model="fileListShow"
      width="1000px"
      :afterClose="fileListClosed"
      :footer="null"
    >
      <a-tabs v-model="active">
        <a-tab-pane tab="重要文件" key="first"></a-tab-pane>
        <a-tab-pane tab="临时文件" key="second"></a-tab-pane>
      </a-tabs>

      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        size="small"
        :row-selection="{
          onChange: fileSelectionChange,
          selectedRowKeys: fileSelectedRowKeys,
        }"
        :rowKey="(record) => record.id"
        :loading="fileListLoadingTable"
      >
        <span slot="lineCountLabel" slot-scope="text, record">
          <a-tooltip placement="left">
            <div slot="title">
              {{ record.lineCount }}
            </div>
            <span> {{ text }} </span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="downloadClick(record)"
            :disabled="record.expired"
            >下载</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="shareClick(record)"
            >分享</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            @click="checkFileContent(record)"
            :disabled="
              record.fileName.split('.')[
                record.fileName.split('.').length - 1
              ] == 'zip'
            "
            >查看</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="copyPath(record)"
            >复制路径</a-button
          >
        </span>
      </a-table>

      <a-button
        @click="batch_download"
        type="primary"
        size="small"
        style="margin-top: 20px"
        :disabled="this.fileSelectedRowKeys.length > 0 ? false : true"
        >批量下载</a-button
      >
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="30%"
      title="分享设置"
      v-model="innerVisible"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-select v-model="expireSeconds" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireSecondList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select v-model="fileSpaceId" placeholder="请选择">
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xls' || fileType == 'xlsx'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      />

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>
    <!-- 辅助弹框 -->
    <a-modal
      :maskClosable="false"
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="null"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        :data-clipboard-text="inviteCode"
        @click="copy"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>
    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="createShow"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input v-model="taskName" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="标签">
          <a-input v-model="flagAdd" placeholder="请输入标签" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="修改标签"
      v-model="modifyFlagShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="标签">
          <a-input
            v-model="flagAdd"
            placeholder="请输入标签"
            style="width: 90%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="modifyFlag_submit()">确定</a-button>
          <a-button @click="modifyFlagShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="搜索节点关联任务"
      v-model="relationTaskShow"
      width="1000px"
      :footer="null"
    >
      <a-tabs v-model="relationTaskActive" @change="relationTaskActiveChange">
        <a-tab-pane tab="数据库操作节点" key="1"></a-tab-pane>
        <a-tab-pane tab="所有节点" key="2"></a-tab-pane>
      </a-tabs>
      <div class="searchCondition">
        <div class="searchConditionItem" v-if="relationTaskActive == '1'">
          表名：
          <a-input
            v-model.trim="tableName"
            placeholder="请输入表名"
            allowClear
            @change="allowClearNodeByTaskChange"
            @pressEnter="nodeByTaskActiveChange(nodeByTaskActive)"
          />
        </div>
        <div class="searchConditionItem" v-if="relationTaskActive == '2'">
          节点：
          <a-select
            v-model="nodeType"
            allowClear
            placeholder="请选择"
            showSearch
            optionFilterProp="children"
            @change="nodeByTaskActiveChange(nodeByTaskActive)"
          >
            <a-select-option
              v-for="item in nodeList"
              :key="item.id"
              :value="item.type"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div
          class="searchConditionItem"
          v-if="
            (relationTaskActive == '1' && nodeByTaskActive == 'NORMAL_TASK') ||
            relationTaskActive == '2'
          "
        >
          创建时间：
          <a-range-picker
            v-model="nodeByTaskTime"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            style="width: 250px"
            @change="nodeByTaskActiveChange(nodeByTaskActive)"
          ></a-range-picker>
        </div>
        <div class="searchButton">
          <a-button
            type="primary"
            @click="nodeByTaskActiveChange(nodeByTaskActive)"
            icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <a-tabs v-model="nodeByTaskActive" @change="nodeByTaskActiveChange">
        <a-tab-pane tab="任务模板" key="1"></a-tab-pane>
        <a-tab-pane tab="定时任务" key="2"></a-tab-pane>
        <a-tab-pane tab="接口任务" key="3"></a-tab-pane>
        <a-tab-pane tab="普通任务" key="4"></a-tab-pane>
      </a-tabs>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(nodeByTaskColumns)"
        :columns="nodeByTaskColumns"
        :dataSource="nodeByTaskList"
        :pagination="nodeByTaskPagination"
        size="small"
        :rowKey="(record) => record.id"
        :loading="loading"
      >
        <span slot="name" slot-scope="text, record">
          <a-button type="link" @click="taskDetail(record)">{{
            text
          }}</a-button>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
          <a-tooltip
            v-if="record.status == 'SCHEDULED'"
            :title="'执行时间:' + record.executeDate"
            placement="top"
          >
            <a-icon type="exclamation-circle" style="color: #d2983c" />
          </a-tooltip>
        </span>
        <span slot="executeSecond" slot-scope="text, record">
          <span v-if="record.status == 'SCHEDULED'">-</span>
          <span v-else>
            {{ $common.timeConsuming(record.executeSecond) }}
          </span>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import moment from "moment";

export default {
  name: "dataRecord",
  props: ["loginName", "userName"],
  data() {
    return {
      innerVisible: false,
      inviteCode: "",
      password: "",
      remark: "",
      passwordSwitch: false,
      expireSecondList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 86400 },
        { id: 3, label: "2天", value: 172800 },
        { id: 4, label: "3天", value: 259200 },
        { id: 5, label: "7天", value: 604800 },
        { id: 6, label: "15天", value: 1296000 },
        { id: 7, label: "一个月", value: 2592000 },
        { id: 8, label: "31天", value: 2592000 },
        { id: 9, label: "45天", value: 3888000 },
        { id: 10, label: "6个月", value: 15552000 },
        { id: 11, label: "1年", value: 31536000 },
      ],
      fileId: "",
      expireSeconds: "",
      time: [],
      jobId: "",
      copyVisible: false,
      statusSelect: undefined,
      statusList: [],
      tableDataAmountId: [], //复选框数据id
      expandedRowKeys: [],
      tableDataAmount: [], //复选框数据
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 120,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "flag",
          width: 400,
        },
        {
          title: "提交人",
          ellipsis: true,
          dataIndex: "userName",
          width: 100,
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 120,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 270,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
          width: 180,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          width: 100,
          align: "center",
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "lineCountLabel",
          scopedSlots: { customRender: "lineCountLabel" },
          width: 80,
          align: "center",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "label",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      fileListTableData: [],
      fileListLoadingTable: false,
      fileListShow: false,
      refreshProgressTimer: {},
      fileSpaceId: -1,
      fileSpaceList: [],
      createShow: false,
      taskName: "",
      fileSelectedRowKeys: [],
      active: "first",
      name: "",
      flag: "",
      flagAdd: "",
      id: "",
      myCollect: false,
      modifyFlagShow: false,
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      submitter: undefined,
      submitterList: [],
      ids: [],
      shareTimer: null,
      syncLoading: null,
      title: "",
      pipelineTaskId: "",
      queryRestoreStatusTimer: null,
      relationTaskShow: false,
      relationTaskActive: "1",
      nodeByTaskActive: "1",
      nodeType: undefined,
      nodeByTaskList: [],
      nodeList: [],
      nodeByTaskPagination: {},
      tableName: "",
      nodeByTaskColumns: [
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "flag",
          width: 200,
        },
        {
          title: "提交人",
          ellipsis: true,
          dataIndex: "userName",
          align: "center",
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 120,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      nodeByTaskTime: [],
      relationPageNo: 1,
      loading: false,
    };
  },
  watch: {
    active: {
      handler(newVal, oldVal) {
        this.clearSelect();
        switch (newVal) {
          case "first":
            this.getFileListTableData(this.jobId, true);
            break;
          case "second":
            this.getFileListTableData(this.jobId, false);
            break;
        }
      },
    },
    submitter: {
      handler(newVal) {
        localStorage.setItem("submitter", newVal);
      },
    },
    myCollect: {
      handler(newVal) {
        localStorage.setItem("normalTaskMyCollect", newVal);
      },
    },
  },
  computed: {
    batchStartDisable() {
      let arr = this.tableDataAmount.filter((item) => {
        return item.status == "WAITING";
      });
      if (arr.length > 0) {
        return false;
      }
      return true;
    },
  },
  created() {
    if (
      localStorage.getItem("submitter") !== "undefined" &&
      localStorage.getItem("submitter")
    ) {
      this.submitter = localStorage.getItem("submitter");
    }
    if (
      localStorage.getItem("normalTaskMyCollect") &&
      localStorage.getItem("normalTaskMyCollect") == "true"
    ) {
      this.myCollect = true;
    } else {
      this.myCollect = false;
    }
    this.query(1);
    this.getStatus();
    this.getNodeList();
    this.getSubmitterList();
  },
  methods: {
    getSubmitterList() {
      this.$http.get("/pipelineTask/usersBy7dayNewTask").then((res) => {
        if (res.result === 200) {
          this.submitterList = res.data.filter((item) => {
            return item.userLoginName !== this.loginName;
          });
          this.submitterList.unshift({
            userName: this.userName,
            userLoginName: this.loginName,
          });
        }
      });
    },
    clearSelect() {
      this.fileSelectedRowKeys.splice(0);
    },
    fileSelectionChange(selectedRowKeys, selectedRows) {
      this.fileSelectedRowKeys.splice(0);
      this.fileSelectedRowKeys.push(...selectedRowKeys);
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileName.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      let id = val.id;
      this.$http
        .get("/pipelineFile/checkFileContent", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            if (res.data.state == "ARCHIVED") {
              this.$confirm({
                title: "文件已归档，查看需要进行解冻",
                content: (h) => <div style="color:red;">确认解冻吗</div>,
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.restore(id);
                },
                onCancel: () => {},
              });
            } else if (res.data.state == "SUCCESS") {
              if (this.fileType == "xlsx" || this.fileType == "xls") {
                res.data.data.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                  };
                  item.forEach((value, key) => {
                    obj["column" + (key + 1)] = value;
                  });
                  this.excelFileList.push(obj);
                });

                res.data.data[0].forEach((value, index) => {
                  this.excelFileColumns.push({
                    title: "列" + (index + 1),
                    ellipsis: true,
                    dataIndex: "column" + (index + 1),
                    width: 100,
                  });
                });
              } else {
                this.fileContent = res.data.data[0].join("\n");
              }
              this.checkFileContentShow = true;
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    restore(id) {
      this.syncLoading = this.$syncLoading({
        message: "解冻中...",
      });
      this.$http
        .post("/pipelineFile/restore", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.queryRestoreStatus(id);
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    queryRestoreStatus(id) {
      this.$http
        .get("/pipelineFile/queryRestoreStatus", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            if (res.data !== "RUNNING") {
              clearTimeout(this.queryRestoreStatusTimer);
              this.queryRestoreStatusTimer = null;
              this.syncLoading.close();
              if (res.data == "DONE") {
                this.$message.success("解冻成功");
              } else {
                this.$message.error("解冻失败");
              }
            } else {
              this.queryRestoreStatusTimer = setTimeout(() => {
                this.queryRestoreStatus(id);
              }, 1000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    copyPath(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/pipelineFile/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    // 点击分享
    shareClick(val) {
      this.fileId = val.id;
      this.innerVisible = true;
      this.jobId = val.pipelineTaskId;
      this.passwordSwitch = false;
      this.password = "";
      this.remark = "";
      this.expireSeconds = 2592000;
      this.fileSpaceId = -1;
    },
    // 分享确定
    shareConfirm() {
      this.syncLoading = this.$syncLoading({
        message: "分享链接生成中...",
      });
      this.getFileShareLink();
    },
    getFileShareLink() {
      let data = {
        id: this.jobId,
        fileId: this.fileId,
        password: this.password,
        expireSeconds: this.expireSeconds,
        fileSpaceId: this.fileSpaceId,
        remark: this.remark,
      };
      this.innerVisible = false;
      this.$http
        .get("/pipelineTask/getFileShareLink", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.url) {
              this.copyVisible = true;
              this.inviteCode = res.data.url;
              this.syncLoading.close();
              this.$message.success("分享成功");
              clearInterval(this.shareTimer);
              this.shareTimer = null;
            } else if (!this.shareTimer) {
              this.shareTimer = setInterval(() => {
                this.getFileShareLink();
              }, 2000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    // 密码随机
    random() {
      this.password = this.$common.random();
    },
    copy() {
      var clipboard = new Clipboard(".copy-code-button"); // 这里可以理解为选择器，选择上面的复制按钮
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.success("手机权限不支持复制功能");
        // 释放内存
        clipboard.destroy();
      });
    },
    generateTaskName() {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.taskName = res.data.name;
          this.createShow = true;
        }
      });
    },
    // 新建任务
    newTask() {
      this.title = "创建任务";
      this.flagAdd = "";
      this.generateTaskName();
    },
    allowClearNodeByTaskChange(e) {
      if (e.target.value) {
        return;
      }
      this.nodeByTaskActiveChange(this.nodeByTaskActive);
    },
    getNodeList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http
        .get("/pipelineNodeInfo/pipelineNodeInfoPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.nodeList = res.data.records;
          }
        });
    },
    relationTaskActiveChange() {
      this.nodeByTaskTime = [
        moment().subtract(7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      this.tableName = "";
      this.nodeType = undefined;
      this.nodeByTaskActive = "1";
      this.nodeByTaskList.splice(0);
      this.nodeByTaskPagination = {};
      if (this.relationTaskActive == "1") {
        this.nodeByTaskActiveChange("1");
      }
    },
    nodeByTaskActiveChange(key) {
      switch (key) {
        case "1":
          this.queryNodeByTask("TEMPLATE", 1);
          break;
        case "2":
          this.queryNodeByTask("SCHEDULED_TASK", 1);
          break;
        case "3":
          this.queryNodeByTask("API_TASK_TEMPLATE", 1);
          break;
        case "4":
          this.queryNodeByTask("NORMAL_TASK", 1);
          break;
      }
    },
    queryNodeByTask(pipelineTaskType, index) {
      if (!this.relationTaskShow) {
        this.nodeByTaskTime = [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
        this.tableName = "";
        this.nodeType = undefined;
        this.relationTaskActive = "1";
      }
      this.nodeByTaskList.splice(0);
      this.nodeByTaskPagination = {};

      let url = "";
      if (index) {
        this.relationPageNo = index;
      }
      let data = {
        pageNo: this.relationPageNo,
        pageSize: 10,
        pipelineTaskType,
      };
      if (this.relationTaskActive == "1") {
        data.tableName = this.tableName;
        url = "/pipelineTask/queryOperateDBTask";
      } else if (this.nodeType) {
        data.nodeType = this.nodeType;
        url = "/pipelineNodeInfo/relationTask";
      } else {
        this.$message.warning("请选择节点");
        return;
      }
      this.loading = true;
      if (
        this.nodeByTaskTime.length > 0 &&
        ((this.relationTaskActive == "1" &&
          this.nodeByTaskActive == "NORMAL_TASK") ||
          this.relationTaskActive == "2")
      ) {
        data.startTime = this.nodeByTaskTime[0]
          ? this.nodeByTaskTime[0] + " 00:00:00"
          : null;
        data.endTime = this.nodeByTaskTime[1]
          ? this.nodeByTaskTime[1] + " 23:59:59"
          : null;
      }
      this.$http.get(url, data).then((res) => {
        if (res.result === 200) {
          this.nodeByTaskList = res.data.records;
          this.nodeByTaskList.forEach((item) => {
            this.nodeByTaskListStatusFormat(item);
          });
          this.loading = false;

          this.nodeByTaskPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) =>
              this.queryNodeByTask(pipelineTaskType, current),
          };
          if (!this.relationTaskShow) {
            this.nodeByTaskActive = "1";
            this.relationTaskShow = true;
          }
        }
      });
    },
    nodeByTaskListStatusFormat(item) {
      switch (item.status) {
        case "QUEUEING":
          item.statusColor = "orange";
          item.statusLabel = "排队中";
          break;
        case "RUNNING":
          item.statusColor = "orange";
          item.statusLabel = "运行中";
          break;
        case "SUCCESS":
          item.statusColor = "green";
          item.statusLabel = "执行成功";
          break;
        case "ERROR":
          item.statusColor = "red";
          item.statusLabel = "执行失败";
          break;
        case "WAITING":
          item.statusColor = "orange";
          item.statusLabel = "等待执行";
          break;
        case "CANCELED":
          item.statusColor = "orange";
          item.statusLabel = "已取消";
          break;
        case "WAITING_AUDIT":
          item.statusColor = "orange";
          item.statusLabel = "等待审核";
          break;
        case "AUDIT_FAILURE":
          item.statusColor = "red";
          item.statusLabel = "审核失败";
          break;
        case "PART_READY":
          item.statusColor = "orange";
          item.statusLabel = "部分就绪";
          break;
        case "SCHEDULED":
          item.statusColor = "orange";
          item.statusLabel = "已定时";
          break;
        case "DRAFT":
          item.statusColor = "orange";
          item.statusLabel = "草稿";
          break;
        case "STOP":
          item.statusColor = "red";
          item.statusLabel = "已停止";
          break;
        case "PAUSED":
          item.statusColor = "orange";
          item.statusLabel = "已暂停";
          break;
        case "PAUSEING":
          item.statusColor = "orange";
          item.statusLabel = "暂停中";
          break;
      }
    },
    taskDetail(record) {
      let url = this.$router.resolve({
        path: "/panel",
        query: {
          id: record.id,
        },
      });
      window.open(url.href);
    },
    // 点击复制任务
    copyPipelineClick(val) {
      this.title = "复制任务";
      this.flagAdd = val.flag;
      this.pipelineTaskId = val.id;
      this.generateTaskName();
    },
    copyTemplatePipelineClick(val) {
      this.title = "复制为模板";
      this.flagAdd = val.flag;
      this.pipelineTaskId = val.id;
      this.generateTaskName();
    },
    create_submit() {
      let data = {
        name: this.taskName,
        flag: this.flagAdd,
      };
      if (this.title == "创建任务") {
        data.model = "BATCH";
        data.pipelineTaskType = "NORMAL_TASK";
        this.$http
          .json_post("/pipelineTask/initBlankTask", data)
          .then((res) => {
            this.createShow = false;
            this.$router.push({
              path: "/panel",
              query: {
                id: res.data.pipelineTask.id,
              },
            });
          });
      } else if (this.title == "复制任务") {
        data.id = this.pipelineTaskId;
        this.$http
          .json_post("/sysPipeline/copyPipelineTask", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("复制成功");
              this.createShow = false;
              this.$router.push({
                path: "/panel",
                query: {
                  id: res.data.pipelineTask.id,
                },
              });
            }
          });
      } else {
        data.id = this.pipelineTaskId;
        this.$http
          .json_post("/pipelineTask/copyToTemplate", data)
          .then((res) => {
            if (res.result === 200) {
              this.createShow = false;
              this.$message.success("复制成功");
              this.$router.push("/pipelineListOrigin/pipelineList");
            }
          });
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.tableDataAmountId.splice(0);
      this.tableDataAmountId.push(...selectedRowKeys);
      this.tableDataAmount.splice(0);
      this.tableDataAmount.push(...selectedRows);
    },
    onSelectAll(selected, selectedRows, changeRows) {
      let newArr = [];
      // 将自定义字段 修改为对应状态
      if (selected) {
        // 递归添加自定义字段 checkBox: true (选中), false(未选中)
        this.traverseTree(this.tableData, newArr, true);
      } else {
        this.traverseTree(this.tableData, newArr, false);
      }
      this.tableData = newArr;
    },
    // 表格单行数据被选中事件
    onSelectRow(record, selected, selectedRows) {
      record.checkFlag = !record.checkFlag; // 更改选中row的选中状态
      // antD此版本 注：暂不支持父子数据递归关联选择。选中第一级时需要手动添一级下的所有第二级
      // 选中父级&&父级有子元素
      if (record.groupId !== -1) {
        if (record.groupId === 0) {
          if (record.groupList.length > 0) {
            // 修改子级的自定义checkFlag值
            record.groupList.forEach((item) => {
              item.checkFlag = record.checkFlag;
            });
            // 判断是选中还是取消选中
            if (record.checkFlag) {
              // 遍历选中的一级的子级 子级全部存入到选中的key集合中、row集合中
              record.groupList.map((account) => {
                this.tableDataAmountId.push(account.id);
                // 去重- 关键
                this.tableDataAmountId = this.tableDataAmountId.filter(
                  (x, index, self) => self.indexOf(x) === index
                );
              });
              this.tableDataAmount = this.tableDataAmount.concat(
                record.groupList
              );
              // 去重- 关键
              this.tableDataAmount = this.tableDataAmount.filter(
                (x, index, self) => self.indexOf(x) === index
              );
            } else {
              // 手动取消一级下所有二级的选中状态
              record.groupList.forEach((account) => {
                this.tableDataAmountId.forEach((selectKey, index) => {
                  if (account.id === selectKey) {
                    this.tableDataAmountId.splice(index, 1);
                  }
                });
                this.tableDataAmount.forEach((selectRow, index) => {
                  if (account.id === selectRow.id) {
                    this.tableDataAmount.splice(index, 1);
                  }
                });
              });
            }
          } else {
            this.expand(true, record, true);
          }
        } else {
          // 操作子级checkbox
          // 找到子级所属父级
          let parent = "";
          this.tableData.forEach((item) => {
            if (item.id === record.groupId) {
              parent = item;
            }
          });
          if (record.checkFlag) {
            // 选中
            // 选中子级 如果子级全部被选中，对应父级被选中
            let checkArr = [];
            checkArr = parent.groupList.filter((account) => account.checkFlag);
            if (checkArr.length === parent.groupList.length) {
              // 子级全部被选中
              // 父级变更为被选中
              this.tableData.forEach((item) => {
                if (item.id === record.groupId) {
                  item.checkFlag = true;
                }
              });
              this.tableDataAmountId.push(parent.id);
              this.tableDataAmount.push(parent);
            }
          } else {
            // 取消任意子级选中状态 父级取消被选中
            this.tableDataAmountId.forEach((selectKey, index) => {
              if (parent.id === selectKey) {
                this.tableDataAmountId.splice(index, 1);
              }
            });
            this.tableDataAmount.forEach((selectRow, index) => {
              if (parent.id === selectRow.id) {
                this.tableDataAmount.splice(index, 1);
              }
            });
            // 父级变更为取消选中
            this.tableData.forEach((item) => {
              if (item.id === record.groupId) {
                item.checkFlag = false;
              }
            });
          }
        }
      }
      this.tableDataAmountId = [...new Set(this.tableDataAmountId)];
      this.tableDataAmount = [...new Set(this.tableDataAmount)];
    },
    /**
     * 递归给数据添加自定字段 checkFlag：true 选中 false 未选中 初始状态：false
     * @param list 递归list
     * @param arr
     * @param booleanFlag
     * */
    traverseTree(list, arr, booleanFlag) {
      list.forEach((ele) => {
        this.$set(ele, "checkFlag", booleanFlag);
        if (ele.groupList && ele.groupList.length > 0) {
          let t = []; // 重要定义一个空array
          this.traverseTree(ele.groupList, t, booleanFlag);
        }
        arr.push(ele);
        return arr;
      });
    },
    expand(expanded, record, select) {
      if (expanded) {
        this.$http
          .get("/pipelineTask/queryGroupTaskList", { groupId: record.id })
          .then((res) => {
            if (res.result === 200) {
              let list = res.data;
              list.forEach((item) => {
                this.statusFormat(item);
              });
              this.$set(record, "groupList", list);

              if (select) {
                record.groupList.map((account) => {
                  this.tableDataAmountId.push(account.id);
                  // 去重- 关键
                  this.tableDataAmountId = this.tableDataAmountId.filter(
                    (x, index, self) => self.indexOf(x) === index
                  );
                });
                this.tableDataAmount = this.tableDataAmount.concat(
                  record.groupList
                );
                // 去重- 关键
                this.tableDataAmount = this.tableDataAmount.filter(
                  (x, index, self) => self.indexOf(x) === index
                );
                this.expandedRowKeys.push(record.id);
              }
              this.refreshProgress();
            }
          });
      }
    },
    // 批量删除
    batchDelete() {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">
            删除{this.tableDataAmountId.length}个任务, 是否继续?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          if (this.tableDataAmountId.length > 0) {
            var idList = this.tableDataAmountId;
            let data = {
              idList,
            };
            this.$http
              .json_post("/pipelineTask/batchDelete", data)
              .then((res) => {
                if (res.result === 200) {
                  this.$message.success({
                    type: "success",
                    message: "批量删除成功!",
                  });
                  this.query();
                } else {
                  this.$message.success({
                    type: "warning",
                    message: "失败!",
                  });
                }
                this.tableDataAmountId.splice(0);
                this.tableDataAmount.splice(0);
              });
          }
        },
        onCancel: () => {},
      });
    },
    batchStart() {
      let ids = [];
      this.tableDataAmount.forEach((item) => {
        if (item.status == "WAITING" && item.groupId !== 0) {
          ids.push(item.id);
        }
      });
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">批量启动{ids.length}个任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$axios
            .all(
              ids.map((id) => {
                let data = { id };
                return this.$http.json_post("/pipelineTask/start", data);
              })
            )
            .then((resArr) => {
              this.$message.success("启动成功");
              this.refreshProgress();
              this.tableDataAmountId.splice(0);
              this.tableDataAmount.splice(0);
            });
        },
        onCancel: () => {},
      });
    },
    // 审核通过
    auditConfirm(val) {
      let data = {
        id: val.id,
        passed: true,
      };
      this.getAudit(data);
    },
    // 审核驳回
    auditCancel(val) {
      let data = {
        id: val.id,
        passed: false,
      };
      this.getAudit(data);
    },
    // 提交审核
    getAudit(data) {
      this.$http.json_post("/sysPipeline/audit", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.refreshProgress();
        }
      });
    },
    modifyFlag(val) {
      this.jobId = val.id;
      this.flagAdd = val.flag;
      this.modifyFlagShow = true;
    },
    modifyFlag_submit() {
      let data = {
        id: this.jobId,
        flag: this.flagAdd,
      };
      this.$http.json_post("/pipelineTask/modifyFlag", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.modifyFlagShow = false;
          this.query();
        }
      });
    },
    // 中断执行
    stopTaskNow(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">中断执行【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/pipelineTask/interruptTask", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("操作成功");
                this.query();
              }
            });
        },
        onCancel: () => {},
      });
    },
    pause(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">暂停【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/pause", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("暂停成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    resume(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">恢复【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/resume", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("恢复成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 立即执行
    doTaskNow(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">立即执行【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/doTaskNow", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("操作成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 查看
    checkPipeline(val) {
      sessionStorage.setItem("pageNo", this.currentPage);
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    collect(val) {
      let data = {
        sourceId: val.id,
        collect: !val.collect,
      };
      this.$http
        .json_post("/pipelineTask/collectNormalTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success((data.collect ? "" : "取消") + "收藏成功");
            this.query();
          }
        });
    },
    // 删除任务
    deletePipelineTask(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">删除【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 取消任务
    cancelPipelineTask(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">取消【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/cancel", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("取消成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 启动任务
    up_pipeline(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">启动【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/start", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("启动成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 点击文件下载
    file_download(val) {
      this.fileListShow = true;
      this.jobId = val.id;
      this.getSpaceList();
      this.getFileListTableData(val.id, true);
    },
    getFileListTableData(pipelineLogId, importantFile) {
      this.fileListLoadingTable = true;
      let data = {
        pipelineLogId,
        importantFile,
      };
      this.$http.get("/pipelineFile/fileList", data).then((res) => {
        if (res.result === 200) {
          this.fileListTableData = res.data;
          this.fileListTableData.map((item) => {
            if (item.lineCount == -1) {
              this.$set(item, "lineCount", "未知");
              this.$set(item, "lineCountLabel", "未知");
            } else {
              this.$set(
                item,
                "lineCountLabel",
                this.$common.formatTotalCount(item.lineCount)
              );
            }
          });
          this.fileListLoadingTable = false;
        }
      });
    },
    // 点击下载
    downloadClick(val) {
      let path = location.protocol + process.env.VUE_APP_BASE_API;
      let href = path + "/pipelineFile/download?id=" + val.id;
      window.location.href = href;
    },
    // 批量下载
    batch_download() {
      let ids = this.fileSelectedRowKeys;
      ids = ids.join(",");
      location.href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/pipelineFile/download?id=" +
        ids;
      this.clearSelect();
    },
    // 关闭下载文件
    fileListClosed() {
      this.fileListTableData = [];
      this.clearSelect();
      this.active = "first";
    },
    // 查询状态
    getStatus() {
      this.$http.get("/sysPipeline/statusList").then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    // 查询文件空间
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    idChange(e) {
      const { value } = e.target;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;

      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.id = value;
      } else {
        this.id = "";
      }
      this.allowClearChange(e);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      if (sessionStorage.getItem("pageNo")) {
        this.currentPage = sessionStorage.getItem("pageNo") * 1;
        sessionStorage.removeItem("pageNo");
      }

      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        status: this.statusSelect,
        parentTaskId: 0,
        name: this.name,
        flag: this.flag,
        submitter: this.submitter,
        id: this.id,
        pipelineTaskType: "NORMAL_TASK",
        myCollect: this.myCollect,
      };
      if (this.time.length > 0) {
        data.startTime = this.time[0] ? this.time[0] + " 00:00:00" : null;
        data.endTime = this.time[1] ? this.time[1] + " 23:59:59" : null;
      }
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            this.statusFormat(item);
            this.$set(item, "groupList", null);
            if (item.groupId == 0) {
              this.$set(item, "groupList", []);
              if (this.expandedRowKeys.length > 0) {
                this.expandedRowKeys.forEach((id) => {
                  if (item.id == id) {
                    this.expand(true, item);
                  }
                });
              }
            }
          });
          this.refreshProgress();
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    statusFormat(item) {
      switch (item.status) {
        case "QUEUEING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "排队中");
          break;
        case "RUNNING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "运行中");
          break;
        case "SUCCESS":
          this.$set(item, "statusColor", "green");
          this.$set(item, "statusLabel", "执行成功");
          break;
        case "ERROR":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "执行失败");
          break;
        case "WAITING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "等待执行");
          break;
        case "CANCELED":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "已取消");
          break;
        case "WAITING_AUDIT":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "等待审核");
          break;
        case "AUDIT_FAILURE":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "审核失败");
          break;
        case "PART_READY":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "部分就绪");
          break;
        case "SCHEDULED":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "已定时");
          break;
        case "DRAFT":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "草稿");
          break;
        case "STOP":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "已停止");
          break;
        case "PAUSED":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "已暂停");
          break;
        case "PAUSEING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "暂停中");
          break;
      }
    },
    // 刷新
    refreshProgress() {
      this.tableData.forEach((item) => {
        if (
          item.status == "RUNNING" ||
          item.status == "WAITING" ||
          item.status == "PAUSEING"
        ) {
          this.ids.push(item.id);
        }
        if (item.groupId == 0) {
          if (item.groupList.length > 0) {
            item.groupList.forEach((groupItem) => {
              if (
                groupItem.status == "RUNNING" ||
                groupItem.status == "WAITING" ||
                groupItem.status == "PAUSEING"
              ) {
                this.ids.push(groupItem.id);
              }
            });
          }
        }
      });
      this.ids = [...new Set(this.ids)];
      if (this.ids.length > 0) {
        this.clear();
        this.refreshProgressTimer = setInterval(() => {
          this.$http
            .get("/pipelineTask/queryTaskStateAndRunTime", {
              ids: this.ids.join(","),
            })
            .then((res) => {
              if (res.result === 200) {
                this.tableData.forEach((item) => {
                  this.refreshItemStatus(res, item);
                  if (item.groupId == 0) {
                    if (item.groupList.length > 0) {
                      item.groupList.forEach((groupItem) => {
                        this.refreshItemStatus(res, groupItem);
                      });
                    }
                  }
                });
                if (this.ids.length == 0) {
                  this.clear();
                }
              }
            })
            .catch(() => {
              this.clear();
            });
        }, 1000);
      }
    },
    refreshItemStatus(res, item) {
      res.data.forEach((dataItem) => {
        if (dataItem.id == item.id) {
          this.$set(item, "status", dataItem.status);
          this.$set(item, "executeSecond", dataItem.executeSecond);
          this.statusFormat(item);
          if (dataItem.status !== "RUNNING" && dataItem.status !== "WAITING") {
            this.ids = this.ids.filter((id) => {
              return id !== dataItem.id;
            });
          }
        }
      });
    },
    clear() {
      if (this.refreshProgressTimer) {
        clearInterval(this.refreshProgressTimer);
        this.refreshProgressTimer = null;
      }
    },
  },
  beforeDestroy() {
    this.clear();
    if (this.shareTimer) {
      clearInterval(this.shareTimer);
      this.shareTimer = null;
    }
  },
};
</script>
