<template>
  <div class="formOptionList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <a-button
          type="primary"
          @click="addFormOption"
          :disabled="formTableData.length == 1 && type == 'multi_task_form'"
          icon="plus"
          >新增表单项</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(formTableColumns)"
      :columns="formTableColumns"
      :dataSource="formTableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      style="margin-top: 20px"
      :loading="formLoadingTable"
      :customRow="customRow"
    >
      <span slot="variableFormat" slot-scope="text, record">
        {{ variableFormatLabel(record.variableFormat) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          @click="edit_selectOptions(record)"
          v-if="record.componentType == 'SELECT'"
          >修改选项</a-button
        >
        <a-button
          type="link"
          @click="edit_db_selectOptions(record)"
          v-if="
            record.componentType == 'DB_SELECT' ||
            record.componentType == 'DB_MULTIPLE_SELECT'
          "
          >修改选项</a-button
        >
        <a-divider
          type="vertical"
          v-if="
            record.componentType == 'SELECT' ||
            record.componentType == 'DB_SELECT' ||
            record.componentType == 'DB_MULTIPLE_SELECT'
          "
        />
        <a-button type="link" @click="edit_formItem(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="delete_formItem(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="updateFormOptionShow"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item
          label="组件类型:"
          required
          v-if="type !== 'multi_task_form'"
        >
          <a-select
            v-model="componentTypeAdd"
            placeholder="请选择组件类型"
            showSearch
            @change="componentTypeChange"
          >
            <a-select-option
              v-for="(item, index) in componentTypeList"
              :key="index"
              :value="item.type"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <span
            class="dropbox-ts"
            v-if="
              componentTypeAdd == 'SELECT' ||
              componentTypeAdd == 'DB_SELECT' ||
              componentTypeAdd == 'DB_MULTIPLE_SELECT'
            "
            >新增保存后可修改选项</span
          >
        </a-form-item>
        <a-form-item label="标签名:" required>
          <a-input v-model="labelAdd" placeholder="请输入标签名" allowClear />
        </a-form-item>
        <a-form-item label="提示:">
          <a-input v-model="tipsAdd" placeholder="请输入提示" allowClear />
        </a-form-item>
        <a-form-item required>
          <span slot="label">
            替换变量
            <a-tooltip placement="left">
              <div slot="title">
                格式: ${parameter}{{
                  type == "multi_task_form" ? ",多个用英文状态下逗号分割" : ""
                }}
              </div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px; margin-left: 5px"
              />
            </a-tooltip>
          </span>
          <a-input
            v-model="bindVariableAdd"
            placeholder="请输入替换变量"
            allowClear
          />
        </a-form-item>
        <a-form-item label="变量格式:" v-if="type == 'single_task_form'">
          <a-select v-model="variableFormatAdd" placeholder="请选择变量格式">
            <a-select-option
              v-for="item in variableFormatList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="type == 'single_task_form'">
          <span slot="label">
            默认值
            <a-tooltip
              placement="left"
              v-if="
                componentTypeAdd == 'DATE_PICKER' ||
                componentTypeAdd == 'DATE_TIME_PICKER'
              "
            >
              <div slot="title">
                <template v-if="componentTypeAdd == 'DATE_PICKER'">
                  ${yyyy-MM-dd}<br />
                  ${yyyy-MM-dd:+1d}<br />
                  ${yyyy-MM-dd:-1d}<br />
                </template>
                <template v-if="componentTypeAdd == 'DATE_TIME_PICKER'">
                  ${yyyy-MM-dd HH:mm:ss}<br />
                  ${yyyy-MM-dd HH:mm:ss:+1s}<br />
                  ${yyyy-MM-dd HH:mm:ss:-1s}<br />
                  ${yyyy-MM-dd HH:mm:ss:+1m}<br />
                  ${yyyy-MM-dd HH:mm:ss:-1m}<br />
                  ${yyyy-MM-dd HH:mm:ss:+1h}<br />
                  ${yyyy-MM-dd HH:mm:ss:-1h}<br />
                  ${yyyy-MM-dd HH:mm:ss:+1d}<br />
                  ${yyyy-MM-dd HH:mm:ss:-1d}<br />
                </template>
              </div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px; margin-left: 5px"
              />
            </a-tooltip>
          </span>
          <a-select
            v-model="defaultValueAdd"
            v-if="selectShow"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in selectOptionList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input
            v-else
            v-model="defaultValueAdd"
            placeholder="请输入默认值"
            allowClear
          />
        </a-form-item>
        <a-form-item label="分隔符:" v-if="type == 'multi_task_form'">
          <a-input
            v-model="separatorAdd"
            placeholder="请输入分隔符"
            allowClear
          />
        </a-form-item>
        <a-form-item label="任务名称:" v-if="type == 'multi_task_form'">
          <a-switch
            checked-children="自定义"
            un-checked-children="默认"
            v-model="taskNameShow"
          />
          <a-input
            v-if="taskNameShow"
            v-model="taskNameAdd"
            placeholder="请输入任务名称"
            style="width: 77%; margin-left: 10px"
            allowClear
          />
        </a-form-item>
        <a-form-item label="必填:" v-if="type == 'single_task_form'">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="requiredAdd"
          />
        </a-form-item>
        <a-form-item label="可编辑:" v-if="type == 'single_task_form'">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="editableAdd"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            @click="updateFormOption_submit"
            :loading="loading"
            >确定</a-button
          >
          <a-button @click="updateFormOptionShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="updateSelectOptionShow"
      width="500px"
      :afterClose="updateSelectOptionClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="选项列表:" required>
          <a-button type="primary" @click="selectOptionListAdd"
            >添加选项</a-button
          >
        </a-form-item>
        <a-form-item
          v-for="(item, index) in selectOptionList"
          :key="index"
          required
          :wrapper-col="{ span: 24 }"
        >
          <a-input
            v-model="item.label"
            placeholder="请输入选项"
            allowClear
            style="width: 40%; margin-left: 40px"
          />
          <a-input
            v-model="item.value"
            placeholder="请输入选项"
            allowClear
            style="width: 40%; margin-left: 10px"
          />
          <a-button
            type="link"
            @click="delete_selectOption(index)"
            style="margin-left: 10px; color: #ff4d4f"
            >删除</a-button
          >
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            @click="updateSelectOption_submit"
            :loading="loading"
            >确定</a-button
          >
          <a-button @click="updateSelectOptionShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="updateDbSelectOptionShow"
      width="500px"
      :afterClose="updateDbSelectOptionClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="数据库:" required>
          <a-select
            v-model="databaseId"
            placeholder="请选择数据库"
            showSearch
            :filterOption="false"
            @search="getDataBase"
          >
            <a-select-option
              v-for="item in databaseList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="查询sql:">
          <a-textarea
            v-model="sql"
            placeholder="请输入查询sql"
            style="width: 80%"
            allowClear
            :rows="6"
          />
        </a-form-item>
        <a-form-item label="缓存刷新间隔(s):">
          <a-input
            v-model="refreshIntervalSecond"
            placeholder="请输入缓存刷新间隔(s)"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            @click="updateDbSelectOption_submit"
            :loading="loading"
            >确定</a-button
          >
          <a-button @click="updateDbSelectOptionShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "formOptionList",
  props: ["formId", "type", "nodeName"],
  data() {
    return {
      formTableColumns: [],
      formTableData: [],
      formLoadingTable: false,
      title: "添加表单选项",
      updateFormOptionShow: false,
      componentTypeList: [],
      componentTypeAdd: "",
      labelAdd: "",
      tipsAdd: "",
      bindVariableAdd: "",
      variableFormatAdd: "",
      defaultValueAdd: "",
      separatorAdd: ",",
      taskNameAdd: "",
      taskNameShow: false,
      requiredAdd: true,
      editableAdd: true,
      formOptionsId: "",
      selectOptionList: [],
      componentId: "",
      updateSelectOptionShow: false,
      updateDbSelectOptionShow: false,
      DbSelectOptionList: [],
      databaseId: "",
      refreshIntervalSecond: "600",
      sql: "",
      databaseList: [],
      selectShow: false,
      variableFormatList: [
        { label: "无", value: "" },
        { label: "in (1,2,3)", value: "IN_NUMBER" },
        { label: "in ('1','2','3')", value: "IN_VARCHAR" },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "零点",
            onClick(picker) {
              const date = new Date();
              date.setHours(0, 0, 0, 0);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      loading: false,
      sourceObj: null,
      targetObj: null,
      sourceIndex: null,
      targetIndex: null,
    };
  },
  computed: {
    selectOptionListData() {
      return JSON.parse(JSON.stringify(this.selectOptionList));
    },
    variableFormatLabel() {
      return (variableFormat) => {
        let arr = this.variableFormatList.filter((item) => {
          return item.value == variableFormat;
        });
        return arr[0].label;
      };
    },
  },
  watch: {
    selectOptionListData: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal.length > 0) {
          newVal.map((item, index) => {
            if (item.label && item.label !== oldVal[index].label) {
              this.$set(this.selectOptionList[index], "value", item.label);
            }
          });
        }
      },
    },
    type: {
      immediate: true,
      handler(newVal) {
        if (newVal == "single_task_form" || newVal == "process_node_form") {
          this.formTableColumns.push(
            ...[
              {
                title: "序号",
                ellipsis: true,
                dataIndex: "id",
                align: "center",
                width: 80,
              },
              {
                title: "组件类型",
                ellipsis: true,
                dataIndex: "componentTypeLabel",
                width: 120,
              },
              {
                title: "标签名",
                ellipsis: true,
                dataIndex: "label",
                width: 120,
              },
              {
                title: "替换变量",
                ellipsis: true,
                dataIndex: "bindVariable",
                width: 200,
              },
              {
                title: "变量格式",
                ellipsis: true,
                dataIndex: "variableFormat",
                width: 200,
              },
              {
                title: "默认值",
                ellipsis: true,
                dataIndex: "defaultValue",
                width: 150,
              },
              {
                title: "操作",
                key: "action",
                fixed: "right",
                align: "center",
                scopedSlots: { customRender: "action" },
                width: 200,
              },
            ]
          );
        } else {
          this.formTableColumns.push(
            ...[
              {
                title: "序号",
                ellipsis: true,
                dataIndex: "id",
                align: "center",
                width: 80,
              },
              {
                title: "组件类型",
                ellipsis: true,
                dataIndex: "componentTypeLabel",
                width: 120,
              },
              {
                title: "标签名",
                ellipsis: true,
                dataIndex: "label",
                width: 120,
              },
              {
                title: "替换变量",
                ellipsis: true,
                dataIndex: "bindVariable",
                width: 200,
              },
              {
                title: "分隔符",
                ellipsis: true,
                dataIndex: "separator",
                align: "center",
                width: 150,
              },
              {
                title: "操作",
                key: "action",
                fixed: "right",
                align: "center",
                scopedSlots: { customRender: "action" },
                width: 200,
              },
            ]
          );
        }
      },
    },
  },
  created() {
    this.getComponentTypeList();
    this.getDataBase();
    this.query();
  },
  methods: {
    componentTypeChange(val) {
      if (val == "SELECT") {
        this.selectShow = true;
      } else {
        this.selectShow = false;
      }
      this.defaultValueAdd = "";
    },
    updateDbSelectOptionClose() {
      this.databaseId = "";
      this.sql = "";
      this.refreshIntervalSecond = "600";
    },
    updateDbSelectOption_submit() {
      this.loading = true;
      let data = {
        componentId: this.componentId,
        databaseId: this.databaseId,
        sql: this.sql.toString().trim(),
        refreshIntervalSecond: this.refreshIntervalSecond.toString().trim(),
      };
      this.$http
        .json_post("/pipelineForm/updateFormComponentDbOption", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.updateDbSelectOptionShow = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.updateDbSelectOptionShow = false;
          this.loading = false;
        });
    },
    getDataBase(name) {
      this.databaseList.splice(0);
      let data = {
        name,
      };
      this.$http
        .get("/pipelineDatasource/pipelineDatasourceList", data)
        .then((res) => {
          if (res.result === 200) {
            this.databaseList.push(...res.data);
          }
        });
    },
    edit_db_selectOptions(val) {
      this.componentId = val.id;
      this.$http
        .get("/pipelineForm/listFormDbOption", { componentId: val.id })
        .then((res) => {
          if (res.result === 200) {
            if (res.data) {
              this.databaseId = res.data.databaseId;
              this.sql = res.data.sql;
              this.refreshIntervalSecond = res.data.refreshIntervalSecond;
            }
            this.updateDbSelectOptionShow = true;
          }
        });
    },
    updateSelectOption_submit() {
      this.loading = true;
      let flag = this.selectOptionList.every((item) => {
        return item.value !== "" && item.label !== "";
      });
      if (flag) {
        let optionList = [];
        this.selectOptionList.map((item) => {
          optionList.push(item.label + "|" + item.value);
        });
        let data = {
          componentId: this.componentId,
          optionList,
        };
        this.$http
          .json_post("/pipelineForm/updateFormComponentOption", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.loading = false;
              this.updateSelectOptionShow = false;
            }
          })
          .catch(() => {
            this.updateSelectOptionShow = false;
            this.loading = false;
          });
      } else {
        this.$message.error("请填写为空的选项");
        this.loading = false;
      }
    },
    selectOptionListAdd() {
      let data = {
        label: "",
        value: "",
      };
      this.selectOptionList.push(data);
    },
    delete_selectOption(index) {
      this.selectOptionList.splice(index, 1);
    },
    updateSelectOptionClose() {
      this.selectOptionList.splice(0);
    },
    getComponentTypeList() {
      this.$http.get("/pipelineForm/componentTypeList").then((res) => {
        if (res.result === 200) {
          this.componentTypeList.push(...res.data);
        }
      });
    },
    addFormOption() {
      this.title = "添加表单选项";
      this.selectShow = false;
      this.selectOptionList.splice(0);
      this.formOptionsId = "";
      if (this.type == "multi_task_form") {
        this.componentTypeAdd = "TEXT_SCOPE";
      } else {
        this.componentTypeAdd = undefined;
      }
      this.labelAdd = "";
      this.tipsAdd = "";
      this.bindVariableAdd = "";
      this.variableFormatAdd = "";
      this.defaultValueAdd = "";
      this.taskNameAdd = "";
      this.taskNameShow = false;
      this.separatorAdd = ",";
      this.requiredAdd = true;
      this.editableAdd = true;
      this.updateFormOptionShow = true;
    },
    updateFormOption_submit() {
      this.loading = true;
      let data = {
        formId: this.formId,
        componentType: this.componentTypeAdd,
        label: this.labelAdd.toString().trim(),
        tips: this.tipsAdd.toString().trim(),
        bindVariable: this.bindVariableAdd.toString().trim(),
        variableFormat: this.variableFormatAdd,
        defaultValue: this.defaultValueAdd.toString().trim(),
        required: this.requiredAdd,
        editable: this.editableAdd,
      };
      if (this.type == "multi_task_form") {
        data.separator = this.separatorAdd;
        if (this.taskNameShow) {
          data.taskName = this.taskNameAdd;
        } else {
          data.taskName = "";
        }
      }
      if (this.title == "添加表单选项") {
        if (this.type == "process_node_form") {
          data.diyNodeId = this.formId;
        }
        this.$http
          .json_post("/pipelineForm/addFormComponent", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("添加成功");
              this.query();
              this.loading = false;
              this.updateFormOptionShow = false;
            }
          })
          .catch(() => {
            this.updateFormOptionShow = false;
            this.loading = false;
          });
      } else {
        data.id = this.formOptionsId;
        this.$http
          .json_post("/pipelineForm/updateFormComponent", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.query();
              this.loading = false;
              this.updateFormOptionShow = false;
            }
          })
          .catch(() => {
            this.updateFormOptionShow = false;
            this.loading = false;
          });
      }
    },
    // 通过a-table自定义列来实现拖拽行修改优先级
    customRow(record, index) {
      if (this.formTableData.length == 1 && this.type == "multi_task_form") {
        return {};
      }
      return {
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            if (this.sourceObj.id !== this.targetObj.id) {
              this.formTableData.splice(this.sourceIndex, 1);
              this.formTableData.splice(this.targetIndex, 0, this.sourceObj);
              this.sort();
            }
            this.targetObj = null;
            this.sourceObj = null;
            this.sourceIndex = null;
            this.targetIndex = null;
          },
        },
      };
    },
    sort() {
      let idList = [];
      this.formTableData.forEach((item) => {
        idList.push(item.id);
      });
      this.$http
        .json_post("/pipelineForm/changeFormComponentOrder", { idList })
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("排序修改成功");
            this.query();
          }
        });
    },
    edit_selectOptions(val) {
      this.componentId = val.id;
      this.$http
        .get("/pipelineForm/listFormComponentOption", { componentId: val.id })
        .then((res) => {
          if (res.result === 200) {
            this.selectOptionList.splice(0);
            this.selectOptionList.push(...res.data);
            this.updateSelectOptionShow = true;
          }
        });
    },
    edit_formItem(val) {
      this.title = "修改表单选项";
      this.formOptionsId = val.id;
      this.componentTypeAdd = val.componentType;
      this.labelAdd = val.label;
      this.tipsAdd = val.tips;
      this.bindVariableAdd = val.bindVariable;
      this.variableFormatAdd = val.variableFormat;
      this.separatorAdd = val.separator;
      this.taskNameAdd = val.taskName;
      if (this.taskNameAdd) {
        this.taskNameShow = true;
      } else {
        this.taskNameShow = false;
      }
      if (val.componentType == "SELECT") {
        this.selectOptionList.splice(0);
        this.$http
          .get("/pipelineForm/listFormComponentOption", { componentId: val.id })
          .then((res) => {
            if (res.result === 200) {
              this.selectOptionList.push(...res.data);
            }
          });
        this.selectShow = true;
        if (val.defaultValue.indexOf("#") != -1) {
          this.defaultValueAdd = val.defaultValue.split("#")[1];
        } else {
          this.defaultValueAdd = val.defaultValue;
        }
      } else {
        this.selectShow = false;
        this.defaultValueAdd = val.defaultValue;
      }
      this.requiredAdd = val.required ? true : false;
      this.editableAdd = val.editable ? true : false;
      this.updateFormOptionShow = true;
    },
    delete_formItem(val) {
      this.$http
        .json_post("/pipelineForm/deleteFormComponent", { id: val.id })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    query() {
      this.formTableData.splice(0);
      this.formLoadingTable = true;
      let data = {
        formId: this.formId,
      };
      if (this.type == "process_node_form") {
        data.nodeName = this.nodeName;
      }
      this.$http.get("/pipelineForm/listFormComponent", data).then((res) => {
        if (res.result === 200) {
          this.formTableData.push(...res.data);
          this.formTableData.forEach((item) => {
            this.componentTypeList.map((typeItem) => {
              if (item.componentType == typeItem.type) {
                item.componentTypeLabel = typeItem.label;
              }
            });
          });
          this.formLoadingTable = false;
        }
      });
    },
  },
};
</script>

<style></style>
