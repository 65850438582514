<template>
  <div class="tabTaskList">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="普通任务" key="1">
        <router-view
          name="second"
          v-if="activeName == '1'"
          :loginName="loginName"
          :userName="userName"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="定时任务" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="接口任务" key="3">
        <router-view name="second" v-if="activeName == '3'"></router-view>
      </a-tab-pane>
    </a-tabs>
    <div class="lockStatus">
      <a-tooltip placement="leftBottom" overlayClassName="lockStatusTip">
        <template slot="title" v-if="lockStatus !== '已锁定'">
          撞库任务数量:
          <a-button type="link" @click="viewTaskList('1')">{{
            parseJobList.length
          }}</a-button
          ><br />
          pipeline任务数量:
          <a-button type="link" @click="viewTaskList('2')">{{
            pipelineTaskList.length
          }}</a-button>
        </template>
        <a-tag :color="statusColor">{{ lockStatus }}</a-tag>
      </a-tooltip>
      <a-button type="link" @click="modifyPipelineLockStatus">
        {{ lockStatus == "未锁定" ? "锁定" : "解除锁定" }}
      </a-button>
    </div>

    <a-modal
      v-if="viewTaskListShow"
      v-model="viewTaskListShow"
      width="1000px"
      :footer="null"
      title="任务列表"
      :maskClosable="false"
      :afterClose="viewTaskListClose"
    >
      <a-tabs v-model="taskActive">
        <a-tab-pane tab="撞库任务" key="1">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(parseJobColumns)"
            :columns="parseJobColumns"
            :dataSource="parseJobList"
            size="small"
            :rowKey="(record) => record.id"
          >
            <span slot="status" slot-scope="text, record">
              <a-tooltip
                :title="record.executeMessage"
                placement="top"
                v-if="
                  record.statusLabel === '错误' ||
                  record.statusLabel === '重复执行'
                "
              >
                <a-tag :color="record.statusColor">{{
                  record.statusLabel
                }}</a-tag>
              </a-tooltip>
              <a-tag v-else :color="record.statusColor">{{
                record.statusLabel
              }}</a-tag>
              <a-tooltip
                v-if="record.statusLabel == '已定时'"
                :title="'执行时间:' + record.executeDate"
                placement="top"
              >
                <a-icon type="exclamation-circle" style="color: #d2983c" />
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button
                type="link"
                size="small"
                @click="pauseParseJob(record)"
                :disabled="record.disabled"
                >暂停</a-button
              >
              <a-divider type="vertical" />
              <a-button
                type="link"
                size="small"
                @click="recover(record)"
                :disabled="record.disabled_recover"
                >恢复</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="pipeline任务" key="2">
          <div class="searchCondition">
            <div class="searchButton">
              <a-button
                type="primary"
                @click="getPipelineLockStatus"
                icon="sync"
                >刷新</a-button
              >
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(pipelineTaskColumns)"
            :columns="pipelineTaskColumns"
            :dataSource="pipelineTaskList"
            size="small"
            :rowKey="(record) => record.id"
          >
            <span slot="userName" slot-scope="text, record">
              {{ record.userName || record.userLoginName }}
            </span>
            <span slot="status" slot-scope="text, record">
              <a-tag :color="record.statusColor">{{
                record.statusLabel
              }}</a-tag>
              <a-tooltip
                v-if="record.status == 'SCHEDULED'"
                :title="'执行时间:' + record.executeDate"
                placement="top"
              >
                <a-icon type="exclamation-circle" style="color: #d2983c" />
              </a-tooltip>
            </span>
            <span slot="executeSecond" slot-scope="text, record">
              <span v-if="record.status == 'SCHEDULED'">-</span>
              <span v-else>
                {{ $common.timeConsuming(record.executeSecond) }}
              </span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button type="link" @click="jumpPipelineList(record)">
                跳转
              </a-button>
              <a-divider
                v-if="record.status == 'RUNNING' || record.status == 'PAUSED'"
                type="vertical"
              />
              <a-button
                type="link"
                size="small"
                @click="pause(record)"
                v-if="record.status == 'RUNNING'"
                >暂停</a-button
              >
              <a-button
                type="link"
                size="small"
                @click="resume(record)"
                v-if="record.status == 'PAUSED'"
                >恢复</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "tabTaskList",
  props: ["loginName", "userName"],
  data() {
    return {
      activeName: "1",
      lockStatus: "未锁定",
      statusColor: "green",
      parseJobColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 400,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 120,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeName",
          width: 200,
        },
        {
          title: "创建人",
          ellipsis: true,
          dataIndex: "author",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      parseJobList: [],
      pipelineTaskColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "提交人",
          ellipsis: true,
          dataIndex: "userName",
          scopedSlots: { customRender: "userName" },
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 120,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      pipelineTaskList: [],
      taskActive: "1",
      viewTaskListShow: false,
      refreshProgressTimer: null,
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tabTaskList/dataRecord") {
          this.activeName = "1";
        } else if (newVal.indexOf("/tabTaskList/timedTaskTab") !== -1) {
          this.activeName = "2";
        } else if (newVal.indexOf("/tabTaskList/apiList") !== -1) {
          this.activeName = "3";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          this.$router.replace("/tabTaskList/dataRecord");
        } else if (newVal === "2") {
          this.$router.replace("/tabTaskList/timedTaskTab");
        } else {
          this.$router.replace("/tabTaskList/apiList");
        }
      },
    },
    viewTaskListShow: {
      handler(newVal) {
        if (!newVal) {
          this.viewTaskListClose();
        }
      },
    },
  },
  created() {
    this.getPipelineLockStatus();
  },
  methods: {
    getPipelineLockStatus() {
      this.$http.get("/pipelineTask/pipelineLockStatus").then((res) => {
        if (res.result == 200) {
          switch (res.data.status) {
            case "UNLOCK" || null:
              this.statusColor = "green";
              this.lockStatus = "未锁定";
              break;
            case "LOCKING":
              this.statusColor = "orange";
              this.lockStatus = "锁定中";
              break;
            case "LOCKED":
              this.statusColor = "red";
              this.lockStatus = "已锁定";
              break;
          }
          this.parseJobList = res.data.parseJobList;
          this.pipelineTaskList = res.data.pipelineTaskList;
          this.parseJobList.forEach((item) => {
            this.$set(item, "disabled", true);
            this.$set(item, "disabled_recover", true);
            switch (item.status.toLowerCase()) {
              case "wait_audit":
                this.$set(item, "statusLabel", "待审核");
                this.$set(item, "statusColor", "orange");
                break;
              case "audit_failure":
                this.$set(item, "statusLabel", "审核失败");
                this.$set(item, "statusColor", "red");
                break;
              case "receipt":
                this.$set(item, "statusLabel", "等待回调");
                this.$set(item, "statusColor", "orange");
                break;
              case "terminated":
                this.$set(item, "statusLabel", "已终止");
                this.$set(item, "statusColor", "red");
                break;
              case "paused":
                this.$set(item, "statusLabel", "已暂停");
                this.$set(item, "statusColor", "red");
                this.$set(item, "disabled_recover", false);
                break;
              case "pauseing":
                this.$set(item, "statusLabel", "暂停中");
                this.$set(item, "statusColor", "orange");
                break;
              case "done":
                this.$set(item, "statusLabel", "已完成");
                this.$set(item, "statusColor", "green");
                break;
              case "init":
                this.$set(item, "statusLabel", "已开始");
                this.$set(item, "statusColor", "orange");
                break;
              case "scheduled":
                this.$set(item, "statusLabel", "已定时");
                this.$set(item, "statusColor", "orange");
                break;
              case "cancel":
                this.$set(item, "statusLabel", "已取消");
                this.$set(item, "statusColor", "orange");
                break;
              case "error":
                this.$set(item, "statusLabel", "错误");
                this.$set(item, "statusColor", "red");
                break;
              case "again":
                this.$set(item, "statusLabel", "重复执行");
                this.$set(item, "statusColor", "orange");
                break;
              case "processing":
                this.$set(item, "statusLabel", "处理中");
                this.$set(item, "statusColor", "orange");
                this.$set(item, "disabled", false);
                break;
            }
          });
          this.pipelineTaskList.forEach((item) => {
            this.statusFormat(item);
          });
        }
      });
    },
    viewTaskListClose() {
      if (this.refreshProgressTimer) {
        clearInterval(this.refreshProgressTimer);
        this.refreshProgressTimer = null;
      }
    },
    modifyPipelineLockStatus() {
      this.$http.post("/pipelineTask/modifyPipelineLockStatus").then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getPipelineLockStatus();
        }
      });
    },
    viewTaskList(taskActive) {
      this.taskActive = taskActive;
      this.viewTaskListShow = true;
      if (this.parseJobList.length > 0 || this.pipelineTaskList.length > 0) {
        this.refreshProgressTimer = setInterval(() => {
          if (this.taskActive == "1") {
            if (this.parseJobList.length > 0) {
              this.getPipelineLockStatus();
            }
          } else {
            let idList = this.pipelineTaskList.map((item) => {
              return item.id;
            });
            if (idList.length > 0) {
              this.$http
                .get("/pipelineTask/queryTaskStateAndRunTime", {
                  ids: idList.join(","),
                })
                .then((res) => {
                  if (res.result === 200) {
                    this.pipelineTaskList.forEach((item) => {
                      res.data.forEach((dataItem) => {
                        if (dataItem.id == item.id) {
                          this.$set(
                            item,
                            "executeSecond",
                            dataItem.executeSecond
                          );
                          this.$set(item, "status", dataItem.status);
                          this.statusFormat(item);
                        }
                      });
                    });
                  }
                });
            }
          }
        }, 2000);
      }
    },
    statusFormat(item) {
      switch (item.status) {
        case "QUEUEING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "排队中");
          break;
        case "RUNNING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "运行中");
          break;
        case "SUCCESS":
          this.$set(item, "statusColor", "green");
          this.$set(item, "statusLabel", "执行成功");
          break;
        case "ERROR":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "执行失败");
          break;
        case "WAITING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "等待执行");
          break;
        case "CANCELED":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "已取消");
          break;
        case "WAITING_AUDIT":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "等待审核");
          break;
        case "AUDIT_FAILURE":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "审核失败");
          break;
        case "PART_READY":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "部分就绪");
          break;
        case "SCHEDULED":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "已定时");
          break;
        case "DRAFT":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "草稿");
          break;
        case "STOP":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "已停止");
          break;
        case "PAUSED":
          this.$set(item, "statusColor", "red");
          this.$set(item, "statusLabel", "已暂停");
          break;
        case "PAUSEING":
          this.$set(item, "statusColor", "orange");
          this.$set(item, "statusLabel", "暂停中");
          break;
      }
    },
    pauseParseJob(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">暂停【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$http.json_post("/job/pause", { id: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("暂停成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    recover(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">恢复【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$http.json_post("/job/recover", { id: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("恢复成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    jumpPipelineList(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.id },
      });
    },
    pause(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">暂停【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/pause", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("暂停成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    resume(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">恢复【{val.name}】任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/pipelineTask/resume", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("恢复成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tabTaskList {
  position: relative;

  .lockStatus {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
<style lang="scss">
.lockStatusTip {
  .ant-tooltip-inner {
    width: 160px !important;
  }
}
</style>
