<template>
  <div
    class="ef-node-form"
    v-if="editShow"
    @click.stop="stop"
    @mousedown.stop="stop"
  >
    <a-icon class="form-close-btn" type="close" @click="closed" />
    <div class="ef-node-form-header">编辑</div>
    <div class="ef-node-form-body">
      <a-form
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        v-if="type === 'node'"
      >
        <a-form-item label="名称">
          <a-input
            :disabled="viewOnly"
            v-model="node.name"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="保留文件">
          <a-switch
            :disabled="viewOnly"
            v-model="node.property.keepResultFile"
          />
        </a-form-item>
        <a-form-item
          label="选择文件类型"
          v-if="node.type === 'databaseLoadService'"
        >
          <a-select
            :disabled="viewOnly"
            v-model="node.property.resultFileType"
            placeholder="请选择文件类型"
            @change="dataChange"
          >
            <a-select-option
              v-for="(item, index) in optionsData"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            文件名称
            <a-tooltip placement="left" overlayClassName="imgTip">
              <div slot="title" style="width: 300px; height: 200px">
                <img
                  src="../../assets/icon/WechatIMG661.png"
                  alt=""
                  style="width: 300px; height: 200px"
                />
              </div>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input
            :disabled="viewOnly"
            v-model="node.property.resultFileName"
            placeholder="请输入保留文件名称"
            @change="changeMessage"
            style="width: 200px; margin-right: 10px"
          />
          <a-tooltip placement="left">
            <span slot="title"> 重置文件名称 </span>
            <a-button
              type="link"
              icon="sync"
              :disabled="viewOnly"
              @click="resetResultFileName"
            ></a-button>
          </a-tooltip>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            icon="check"
            @click="save"
            :disabled="viewOnly"
            style="margin-left: 150px"
            >确定</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  props: ["viewOnly"],
  data() {
    return {
      optionsData: [
        {
          value: "txt",
          label: "txt",
        },
        {
          value: "excel",
          label: "excel",
        },
        {
          value: "csv",
          label: "csv",
        },
      ],
      type: "node",
      node: {},
      line: {},
      data: {},
      editShow: false,
    };
  },
  methods: {
    resetResultFileName() {
      if (this.node.property.resultFileType) {
        this.node.property.resultFileName =
          this.node.id +
          "." +
          (this.node.property.resultFileType == "excel"
            ? "xlsx"
            : this.node.property.resultFileType);
      } else {
        this.node.property.resultFileName = this.node.id + ".txt";
        this.node.property.resultFileType = "txt";
      }
      if (this.node.type == "textToExcelService") {
        if (this.node.extraInfo.type == "CSV") {
          this.node.property.resultFileName =
            this.node.property.resultFileName.split(".")[0] + ".csv";
          this.node.property.resultFileType = "csv";
        } else {
          this.node.property.resultFileName =
            this.node.property.resultFileName.split(".")[0] + ".xlsx";
          this.node.property.resultFileType = "xlsx";
        }
      }
      if (
        this.node.type == "columnGroupService" ||
        this.node.type == "zipService" ||
        this.node.type == "fileCompressionService"
      ) {
        this.node.property.resultFileName =
          this.node.property.resultFileName.split(".")[0] + ".zip";
        this.node.property.resultFileType = "zip";
      }
    },
    // 强制更新测试文本信息框的值
    changeMessage() {
      this.$forceUpdate();
    },
    stop() {},
    dataChange(val) {
      this.node.property.resultFileName =
        this.node.property.resultFileName.split(".")[0] +
        "." +
        (val == "excel" ? "xlsx" : val);
    },
    /**
     * 表单修改，这里可以根据传入的ID进行业务信息获取
     * @param data
     * @param id
     */
    nodeInit(data, id) {
      this.type = "node";
      this.data = cloneDeep(data);
      data.nodeList.forEach((node) => {
        if (node.id === id) {
          this.node = cloneDeep(node);
          this.editShow = true;
        }
      });
      if (!this.node.property.resultFileName) {
        this.node.property.resultFileName = this.node.id + ".txt";
        this.node.property.resultFileType = "txt";
      }
      if (this.node.type == "textToExcelService") {
        if (this.node.extraInfo.type == "CSV") {
          this.node.property.resultFileName =
            this.node.property.resultFileName.split(".")[0] + ".csv";
          this.node.property.resultFileType = "csv";
        } else {
          this.node.property.resultFileName =
            this.node.property.resultFileName.split(".")[0] + ".xlsx";
          this.node.property.resultFileType = "xlsx";
        }
      }
    },
    save() {
      this.editShow = false;
      this.$emit("repaintEverything", this.node);
    },
    closed() {
      this.$emit("closed", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.ef-node-form {
  position: relative;

  .ef-node-form-header {
    height: 32px;
    border-top: 1px solid #dce3e8;
    border-bottom: 1px solid #dce3e8;
    background: #f1f3f4;
    color: #000;
    line-height: 32px;
    padding-left: 12px;
    font-size: 14px;
  }

  .ef-node-form-body {
    margin-top: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    width: 380px;
  }

  .form-close-btn {
    position: absolute;
    top: 7px;
    right: 5px;
    cursor: pointer;

    &:hover {
      color: #409eff;
    }
  }
}
</style>
<style lang="scss">
.imgTip {
  .ant-tooltip-inner {
    width: 316px !important;
  }
}
</style>
