<template>
  <div class="pipelineList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>我的收藏：</span>
        <a-checkbox v-model="myCollect" @change="query(1)"></a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPipeline()" icon="plus"
          >创建模板</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="modify_pipeline(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-button type="link" @click="addTask_pipeline(record)"
          >创建任务</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="batchAddTask_pipeline(record)"
          >批量创建</a-button
        >
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button type="link" size="small" @click="collect(record)">{{
                record.collect ? "取消收藏" : "收藏"
              }}</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="delete_pipeline(record)"
              >
                <template slot="title">删除{{ record.name }}任务?</template>
                <a-button type="link" size="small" style="color: #ff4d4f"
                  >删除</a-button
                >
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <!-- 创建任务 -->
    <a-modal
      :maskClosable="false"
      title="创建任务"
      v-model="addTaskShow"
      width="600px"
      :afterClose="addTaskClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="任务名称:" required>
          <a-input
            v-model="addTaskName"
            placeholder="请输入任务名称"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="文件上传:" required v-if="fileShow">
          <a-upload-dragger
            class="upload-demo"
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="标签">
          <a-input v-model="flag" placeholder="请输入标签" style="width: 80%" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="addTask_submit">确定</a-button>
          <a-button @click="addTaskShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      :maskClosable="false"
      title="创建模板"
      v-model="createShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input
            v-model="taskName"
            placeholder="请输入名称"
            style="width: 90%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "pipelineList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 120,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 400,
        },
        {
          title: "流程说明",
          ellipsis: true,
          dataIndex: "remark",
          width: 300,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      pipelineId: "",
      addTaskName: "",
      addTaskShow: false,
      name: "",
      createShow: false,
      taskName: "",
      fileList: [],
      fileShow: false,
      flag: "",
      myCollect: false,
    };
  },
  watch: {
    myCollect: {
      handler(newVal) {
        localStorage.setItem("pipelineListMyCollect", newVal);
      },
    },
  },
  mounted() {
    if (
      localStorage.getItem("pipelineListMyCollect") &&
      localStorage.getItem("pipelineListMyCollect") == "true"
    ) {
      this.myCollect = true;
    } else {
      this.myCollect = false;
    }
    this.query(1);
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    generateTaskName(val) {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          if (val) {
            this.taskName = res.data.name;
            this.createShow = true;
          } else {
            this.addTaskName = res.data.name;
            this.addTaskShow = true;
          }
        }
      });
    },
    create_submit() {
      let data = {
        name: this.taskName,
        model: "BATCH",
        pipelineTaskType: "TEMPLATE",
      };
      this.$http.json_post("/pipelineTask/initBlankTask", data).then((res) => {
        this.$router.push({
          path: "/panel",
          query: {
            id: res.data.pipelineTask.id,
          },
        });
      });
    },
    // 点击新增
    addPipeline() {
      this.generateTaskName(true);
    },
    collect(val) {
      let data = {
        sourceId: val.id,
        collect: !val.collect,
        type: "TEMPLATE",
      };
      this.$http
        .json_post("/pipelineTask/collectTaskResource", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success((data.collect ? "" : "取消") + "收藏成功");
            this.query();
          }
        });
    },
    // 修改
    modify_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    // 删除
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 创建任务
    addTask_pipeline(val) {
      this.pipelineId = val.id;
      this.flag = val.name;
      this.generateTaskName(false);
    },
    batchAddTask_pipeline(val) {
      this.pipelineId = val.id;
      this.flag = val.name;
      this.fileShow = true;
      this.generateTaskName(false);
    },
    // 确定创建任务
    addTask_submit() {
      if (!this.addTaskName) {
        this.$message.warning("请输入任务名称");
        return;
      }

      let data = {
        id: this.pipelineId,
        name: this.addTaskName,
        flag: this.flag,
      };
      if (this.fileShow) {
        if (this.fileList.length == 0) {
          this.$message.warning("请先上传文件");
          return;
        }
        data.file = this.fileList[0];
        this.$http
          .files("/sysPipeline/batchReCreatePipelineTask", data)
          .then((res) => {
            if (res.result === 200) {
              this.addTaskShow = false;
              this.$message.success("创建成功");
            }
          });
      } else {
        this.$http
          .json_post("/sysPipeline/copyPipelineTask", data)
          .then((res) => {
            if (res.result === 200) {
              this.addTaskShow = false;
              this.$message.success("创建成功");
              this.$router.push({
                path: "/panel",
                query: {
                  id: res.data.pipelineTask.id,
                },
              });
            }
          });
      }
    },
    // 关闭创建任务
    addTaskClose() {
      this.addTaskName = "";
      this.fileShow = false;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        myCollect: this.myCollect,
        pipelineTaskType: "TEMPLATE",
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
