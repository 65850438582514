<template>
  <div class="ftpFileListByDir">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>文件名称：</span>
        <a-input
          placeholder="请输入"
          v-model.trim="fileName"
          @pressEnter="queryFileFilter()"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem" style="height: 32px">
        路径：<template v-for="(item, index) in pathArr">
          <template v-if="index !== pathArr.length - 1">
            <a-button
              type="link"
              @click="skipDirectory(item)"
              style="font-size: 14px !important"
            >
              {{ item }}
              /
            </a-button>
          </template>
          <template v-else> {{ item }}/ </template>
        </template>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query()" icon="search">查询</a-button>
        <a-button type="primary" @click="upload()" icon="plus"
          >上传文件</a-button
        >
      </div>
    </div>

    <a-table
      size="small"
      class="fileList"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      :rowKey="(record) => record.path"
      :scroll="{ y: '600px' }"
    >
      <span slot="fileName" slot-scope="text, record">
        <a-button
          type="link"
          style="padding: 0"
          @click="clickName(record)"
          v-if="record.dir"
        >
          {{ text }}
        </a-button>
        <template v-else>{{ text }}</template>
      </span>
      <span slot="dir" slot-scope="text, record">
        <template v-if="record.dir"> 文件夹 </template>
        <template v-else> 文件 </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template v-if="!record.dir">
          <a href="javascript:;" @click="download(record)"> 下载 </a>
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            v-copy="'@ftp#' + ftpId + record.path"
          >
            复制地址
          </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="removeFile(record)"
          >
            <template slot="title">确认是否删除{{ record.fileName }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </a-table>

    <a-modal
      title="新增"
      v-model="uploadShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="上传文件:" required>
          <a-upload-dragger
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            :remove="handleRemove"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="uploadShow = false">取 消</a-button>
          <a-button type="primary" :loading="loading" @click="uploadSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ftpFileListByDir",
  props: ["ftpId", "initialPath"],
  data() {
    return {
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "fileName",
          scopedSlots: { customRender: "fileName" },
        },
        {
          title: "文件大小",
          dataIndex: "readableFileSize",
          width: 120,
        },
        {
          title: "类型",
          dataIndex: "dir",
          scopedSlots: { customRender: "dir" },
          width: 80,
        },
        {
          title: "最后更新时间",
          dataIndex: "lastModifyAt",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 160,
        },
      ],
      tableDataSource: [],
      allTableDataSource: [],
      path: "/",
      fileName: "",
      fileList: [],
      uploadShow: false,
      loading: false,
    };
  },
  computed: {
    pathArr() {
      let arr = this.path.split("/").filter((item) => {
        return item !== "";
      });
      arr.unshift("root");
      return arr;
    },
  },
  mounted() {
    this.path = this.initialPath;
    setTimeout(() => {
      this.query();
    }, 200);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.queryFileFilter();
    },
    queryFileFilter() {
      this.tableDataSource = this.allTableDataSource.filter((item) => {
        return item.fileName.indexOf(this.fileName) !== -1;
      });
    },
    skipDirectory(item) {
      if (item == "root") {
        this.path = "/";
      } else {
        this.path = this.path.split(item + "/")[0] + item + "/";
      }
      this.fileName = "";
      this.query();
    },
    upload() {
      this.fileList.splice(0);
      this.uploadShow = true;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    uploadSubmit() {
      if (this.fileList.length == 0) {
        this.$message.success({
          type: "info",
          message: "请先上传文件！",
        });
        return;
      }
      this.loading = true;
      let data = {
        file: this.fileList[0],
        ftpId: this.ftpId,
        path:
          this.path +
          (this.path == this.initialPath || this.path == "/" ? "" : "/"),
      };

      this.$http
        .files("/ftp/checkFileExist", data)
        .then((res) => {
          if (res.result == 200) {
            if (res.data) {
              data.isExist = true;
              this.$confirm({
                title: "文件名重复，是否覆盖原文件",
                content: (h) => <div style="color:red;">确认覆盖吗</div>,
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.checkFileExistAfter(data);
                },
                onCancel: () => {
                  this.loading = false;
                  this.uploadShow = false;
                },
              });
            } else {
              data.isExist = false;
              this.checkFileExistAfter(data);
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkFileExistAfter(data) {
      this.syncLoading = this.$syncLoading({
        message: `文件上传中...`,
        progressShow: true,
        percent: 0,
      });
      this.$http
        .files("/ftp/uploadFtp", data, (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.syncLoading.update({
            percent,
          });
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            this.loading = false;
            this.uploadShow = false;
            this.$message.success("上传成功");
            this.query();
          }
        })
        .catch(() => {
          this.syncLoading.close();
          this.loading = false;
        });
    },
    query() {
      this.loadingTable = true;
      let data = {
        ftpId: this.ftpId,
        path: this.path,
      };
      this.$http.get("/ftp/fileListByDir", data).then((res) => {
        if (res.result === 200) {
          this.tableDataSource = res.data;
          this.tableDataSource.forEach((item) => {
            item.path =
              this.path +
              (this.path == this.initialPath || this.path == "/" ? "" : "/") +
              item.fileName;
          });
          this.allTableDataSource = this.$common.deepClone(
            this.tableDataSource
          );
          this.loadingTable = false;
          this.queryFileFilter();
        }
      });
    },
    clickName(record) {
      if (record.dir) {
        this.path = record.path;
        this.fileName = "";
        this.query();
      }
    },
    download(record) {
      location.href =
        (process.env.VUE_APP_CURRENTMODE == "dev"
          ? process.env.VUE_APP_BASE_API
          : location.origin + "/api") +
        `/ftp/downloadFile?ftpId=${this.ftpId}&fullPath=${record.path}`;
    },
    removeFile(record) {
      let data = {
        ftpId: this.ftpId,
        fullPath: record.path,
      };

      this.$http.json_post("/ftp/removeFile", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss">
.ftpFileListByDir {
  .ant-table-header {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
